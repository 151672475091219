import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "./SignupView.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Dialog, Typography } from "@mui/material";
import RoundedButton from "../../../components/button/RoundedButton";
import TitleTypography from "../../../components/typography/TitleTypography";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { getFormattedDate } from "../../../utils/HelperFunctions";

function SignupView(props: any) {
  const profileSelectedTitle =
    props.profileSelectedWhileSignup && props.profileSelectedWhileSignup.title
      ? props.profileSelectedWhileSignup.title
      : "";
  const profileSelectedImage =
    props.profileSelectedWhileSignup && props.profileSelectedWhileSignup.img
      ? props.profileSelectedWhileSignup.img
      : "";
  const profileSelectedType =
    props.profileSelectedWhileSignup &&
    props.profileSelectedWhileSignup.userType
      ? props.profileSelectedWhileSignup.userType
      : "";

  // switch(profileSelectedType){
  //   case "NORMALUSER": return ()
  // }

  const dateOfBirthSelected =
    props.userDetails && props.userDetails.dateOfBirth
      ? props.userDetails.dateOfBirth
      : "";
  console.log("User details data", props.userDetails);
  return (
    <Box className="createNewAccount">
      <Typography className="mainTitle">Create a New Account</Typography>
      <Typography>Spiritual Experience with Temple Dekho</Typography>

      <Box className="signupDetails">
        <Typography className="signUpText">Register</Typography>
        <Typography style={{ marginTop: "1em" }}>
          Profile : {profileSelectedTitle}
        </Typography>
        <img src={profileSelectedImage} style={{ marginTop: "1em" }}></img>

        <Box className="textArea">
          <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            label="First Name*"
            variant="standard"
            error={
              !props.userDetails.firstName && props.isErrorInForm ? true : false
            }
            onChange={(e: any) =>
              props.setUserDetails({
                ...props.userDetails,
                firstName: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            label="Last Name*"
            error={
              !props.userDetails.lastName && props.isErrorInForm ? true : false
            }
            variant="standard"
            onChange={(e: any) =>
              props.setUserDetails({
                ...props.userDetails,
                lastName: e.target.value,
              })
            }
          />

          <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            label="Phone Number*"
            variant="standard"
            error={
              !props.userDetails.phoneNumber && props.isErrorInForm
                ? true
                : false
            }
            defaultValue={props.userDetails.phoneNumber}
          />

          <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            error={
              !props.userDetails.email && props.isErrorInForm ? true : false
            }
            label="Email*"
            variant="standard"
            onChange={(e: any) =>
              props.setUserDetails({
                ...props.userDetails,
                email: e.target.value,
              })
            }
          />

          <Box
            onClick={() => props.setDatePickerOpen(true)}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            <TextField
              label="Birth Day*"
              className="dateField"
              id="input-with-sx"
              error={!dateOfBirthSelected && props.isErrorInForm ? true : false}
              value={dateOfBirthSelected}
              variant="standard"
            />
            <CalendarMonthIcon
              sx={{ color: "action.active", mr: 1, my: 0.5 }}
            />
          </Box>
          {/* <TextField
            style={{ width: "100%" }}
            id="standard-basic"
            label="Birth Day"
            variant="standard"
            onChange={(e: any) =>
              props.setUserDetails({
                ...props.userDetails,
                dateOfBirth: e.target.value,
              })
            }
          /> */}
        </Box>

        <Box className="genderSelect">
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender *
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={props.userDetails?.gender}
              onChange={(e: any) => {
                console.log("gender selected", e.target.value);
                props.setUserDetails({
                  ...props.userDetails,
                  gender: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel value="Male" control={<Radio />} label="Male" />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
        </Box>

        {profileSelectedType === "PANDIT" ? (
          <Box>
            <Button variant="contained" component="label">
              Document front
              <input type="file" hidden />
            </Button>
            <Button
              style={{ marginLeft: "25px" }}
              variant="contained"
              component="label"
            >
              Document back
              <input type="file" hidden />
            </Button>
          </Box>
        ) : null}

        <Box className="updateBtn">
          <Button
            className="btn-primary btnRound confirmBtn"
            variant="contained"
            onClick={() => props.submitSignUp()}
          >
            Continue
          </Button>
        </Box>
        <Box className="loginText">
          <Typography>
            Joined us before?{" "}
            <a onClick={() => props.cancelAndGoToLogin()}>Login</a>
          </Typography>
        </Box>
      </Box>

      <Dialog
        className="dateTimingSelection"
        onClose={() => props.setDatePickerOpen(false)}
        open={props.datePickerOpen}
      >
        <Box className="dateTimePopup">
          <TitleTypography title="Select Birth Date">
            Select Birth Date
          </TitleTypography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              // value={dayjs(dateOfBirthSelected)}
              disableFuture={true}
              onChange={(e: any) => {
                const dateData = getFormattedDate(e.toString());
                props.setUserDetails({
                  ...props.userDetails,
                  dateOfBirth: dateData,
                });
              }}
            />
          </LocalizationProvider>
          <Box className="btnContinue">
            <RoundedButton
              title="Continue"
              onClick={() => {
                props.setDatePickerOpen(false);
              }}
            ></RoundedButton>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        className="ErrorformDialog"
        onClose={() => {
          props.setErrorDialog(false);
        }}
        open={props.errorDialog}
      >
        <Box className="errorForm">
          {/* <TitleTypography title="Status" /> */}
          <Typography>Please enter all necessary fields to register.</Typography>
          <Box className="btnContinue">
            <Button
              variant="outlined"
              title="Ok"
              onClick={() => {
                props.setErrorDialog(false);
              }}
            >OK</Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
}

export default SignupView;

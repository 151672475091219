import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ParentView from '../../Views/PrarentView/ParentView';
import PujaStepsView from '../../Views/PujaSteps/PujaStepsView';



const PujaStepsScreen: React.FC = () => {
    useEffect(() => {

    }, []);

    return (
        <ParentView>
            <PujaStepsView />
        </ParentView>
    );
};

export default PujaStepsScreen;

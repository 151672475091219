import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, Typography, TextField, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

interface UpdatePaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  orderId: string;
}

const UpdatePaymentModal: React.FC<UpdatePaymentModalProps> = ({ isOpen, onClose, orderId }) => {

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="change-status-title">
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', boxShadow: "24px", padding: 16, width: 500 }}>
        <Typography id="change-status-title" variant="h6" gutterBottom>
          Update Payment
          <Button onClick={onClose} style={{ position: 'absolute', top: 0, right: 0 }}>
            <CloseIcon />
          </Button>
        </Typography>
        <Divider sx={{ opacity: 0.6, paddingBottom: "5px" }} />
        <div>
          Content
        </div>
      </div>
    </Modal>
  );
  
};

export default UpdatePaymentModal;

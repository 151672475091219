import CarouselComponent from "../../../components/carousel/Carousel";
import googleicon from "../../../assets/google.png";
import facebookicon from "../../../assets/facebook.png";
import otpImg from "../../../assets/otpImg.png";

import { auth } from "../../../configs/firebaseConfig";
import { useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import Dialog from "@mui/material/Dialog";
import "./LoginView.scss";
import { Box, Grid, Typography } from "@mui/material";
import RoundedButton from "../../../components/button/RoundedButton";
import TitleTypograpgy from "../../../components/typography/TitleTypography";

function LoginView(props: any) {
  return (
    <Box className="loginViewArea">
      <Box className="container">
        <Grid container spacing={4} className="loginBlock">
          <Grid xs={12} sm={12} md={6} lg={6} className="loginLeft">
            <Box className="leftCarousel">
              <CarouselComponent />
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={6} className="loginRight">
            <form className="loginForm" onSubmit={(e) => props.sendOtp(e)}>
              <Box className="rightLoginDetails">
                <Typography className="title">Login to Your Account</Typography>
                {/* <SubtitleTypograpgy title="Spiritual Experience with Temple Dekho" fontSize="25px" /> */}
                <Typography className="subTitle">
                  Spiritual Experience with TempleDekho
                </Typography>

                <Box className="mobileNoInput">
                  <Box className="phInput-group">
                    <span className="input-group-text" id="basic-addon1">
                      +91
                    </span>
                    <input
                      placeholder="Mobile Number"
                      onChange={(e) => props.setPhone(e.target.value)}
                    ></input>
                  </Box>
                  <RoundedButton
                    type="submit"
                    onClick={(e: any) => {
                      props.sendOtp(e);
                      // loginClick();
                    }}
                    title="Request OTP"
                    // padding="15px 30px"
                  ></RoundedButton>
                </Box>
                {props.preOtpErrorMsg &&props.preOtpErrorMsg.length>0? (
                  <Box>{props.preOtpErrorMsg}</Box>
                ) : null}

                <Box id="recaptcha-container"></Box>
                <br></br>
                {/* <button type="button" className="btn btn-light otherLoginBtn">
                <p>
                  <img src={googleicon} alt="" />
                  Continue with google
                </p>
              </button>

              <button type="button" className="btn btn-light otherLoginBtn">
                <p>
                  <img src={facebookicon} alt="" />
                  Continue with Facebook
                </p>
              </button> */}
                <Box className="signUpBottom">
                  <TitleTypograpgy
                    title="Enter your mobile number to login or signup"
                    fontSize="16px"
                  />
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
        <Dialog
          onClose={() => props.setIsOtpScreen(false)}
          open={props.isOtpScreen}
          sx={{
            '& .MuiPaper-root': {
              margin:"15px"
              // Add any other custom styles here
            },
          }}
        >
          <Box className="otpScreen">
            <form onSubmit={(e) => props.verifyOtpSubmit(e)}>
              <Box className="otpHeadingText">
                <Typography className="veryfyNo">Verify your number</Typography>
                <Typography>
                  Code is sent to xxxxxx
                  {props.phone.substring(6, props.phone.length)}
                </Typography>
                <Box className="otpImgBlock">
                  <img src={otpImg}></img>
                </Box>
              </Box>

              <Box className="otpFields">
                <MuiOtpInput
                  autoFocus={true}
                  length={6}
                  value={props.otp}
                  onChange={props.setOtp}
                />
              </Box>

              <Box id="recaptcha-container"></Box>
              {props.enableResendOtp ? (
                <Typography className="resendOtps">
                  <a
                    className="resendotp"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => props.resendOtp(e)}
                  >
                    Resend OTP
                  </a>
                </Typography>
              ) : (
                <Typography></Typography>
              )}

              <Box className="otpConfirm">
                <RoundedButton
                  type="submit"
                  className="btn btn-primary btnRound"
                  title="Confirm"
                  onClick={(e: any) => props.verifyOtpSubmit(e)}
                ></RoundedButton>
              </Box>
            </form>
            {props.loginErrorMessage.length > 0 ? (
              <Typography style={{ marginTop: "1em" }}>
                {props.loginErrorMessage}
              </Typography>
            ) : null}
          </Box>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LoginView;

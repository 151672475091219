import PromotionalPujaView from "../../Views/Puja/PromotionalPujaView";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, Navigate,useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { getTempleDetailsById } from "../../store/actions/templeAction";
import { setSelectedPuja,setSelectedTempleDetailsForPuja } from "../../store/slices/pujaSlice";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import { getPromotionalPujaListById } from "../../store/actions/pujaAction";
function PromotioanlPujaScreen() {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const {promotionalList,slectedPromotionalPujaOrganisations} = useSelector((state: any) => state.puja);
  const promotionalPujaId = location.pathname.split("/")[2];

  useEffect(()=>{
    dispatch(getPromotionalPujaListById(promotionalPujaId))
  },[])

  const selectedPromotionalPujaDetailsFiltered = promotionalList&&promotionalList.length>0?promotionalList.filter(function(item:any){return item.id===promotionalPujaId}):[]
  var promotionalPujaDetails = null
  if(selectedPromotionalPujaDetailsFiltered&&selectedPromotionalPujaDetailsFiltered.length>0){
    promotionalPujaDetails = selectedPromotionalPujaDetailsFiltered[0]
  }

  const onClickParticipate = (templeId:any,pujaId:any)=>{
    navigate(`/temples/${templeId}/pujaInfo/${pujaId}`)
  }
  return (
    <ParentView>
        <PromotionalPujaView promotionalPujaDetails={promotionalPujaDetails} slectedPromotionalPujaOrganisations={slectedPromotionalPujaOrganisations} onClickParticipate={onClickParticipate}/>
    </ParentView>
  );
}

export default PromotioanlPujaScreen;

import MainCategoriesCard from "../../components/card/MainCategories/MainCategoriesCard";
import UpcomingPujasCard from "../../components/card/UpcomingPujas/UpcomingPujasCard";
import TempleCard from "../../components/card/Temple/TempleCard";
import PostCard from "../../components/card/Post/PostCard";
import FeaturesDisplayCard from "../../components/card/FeaturedCategories/FeaturesDisplayCard";
import DailyDetailsCard from "../../components/card/FeaturedCategories/DailyDetailsCard";
import BlessingsCard from "../../components/card/Blessings/BlessingsCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import PostBox from "../../components/post/PostBox";
import ViewCommentsForPost from "../../components/post/ViewCommentsForPost";
import "./HomeView.scss";
import { getOneImageForTemple } from "../../utils/HelperFunctions";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import TopPostBox from "../../components/post/TopPostBox";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Badge } from "@mui/base/Badge";
import BlessYSiso from "../../assets/blessysiso.png";

import KnowRashi from "../../assets/knowrashi.png";
import IndependenceOffer from "../../assets/independence-offer.png";

import {
  UPCOMING_PUJA_CAROUSAL_RESPONSIVE,
  POPULAR_TEMPLE_CAUROSAL_RESPONSIVE,
  FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE,
  CAROUSEL_REMOVE_ARRAOW_ON_DEVICE,
} from "../../utils/Constants";
import PujaCategoryList from "../../components/PujaCategoryList/PujaCategoryList";

function HomeView(props: any) {
  const navigate = useNavigate();
  const [postPopupView, setPostPopupView] = useState(false);

  const [showCommentForPost, setShowCommentForPost] = useState({
    shouldOpened: false,
    postId: "",
  });
  // console.log("Texts", props.TDTexts);

  // console.log("Aggregated address", props.userAddressData);
  const leftAddressheader =
    props.userAddressData && props.userAddressData.city
      ? props.userAddressData.city
      : null;
  const leftAddressData =
    props.userAddressData && props.userAddressData.formatted_address
      ? props.userAddressData.formatted_address
      : null;
  return (
    <Box className="pageContainer homePage">
      <Box className="homeBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
              {leftAddressData ? (
                <LeftAddressCard
                  header={leftAddressheader}
                  address={leftAddressData}
                  footerButton="Edit or add an address"
                  onClick={() => navigate("/profile/address")}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
              <Box className="homeBodyContent">
                <Box className="mainCategories">
                  <Grid container spacing={2}>
                    {props.mainCategories.map((item: any, id: any) => {
                      return (
                        <Grid item xs={6} md={6} lg={6} xl={6}>
                          <MainCategoriesCard
                            cardTitle={item.cardTitle}
                            image={item.img}
                            navigatePath={item.navigatePath}
                            navigate={props.navigate}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                <Box
                  className="knowYourrashi"
                  onClick={() => navigate("/horoscope")}
                >
                  <Link to="./">
                    <img src={KnowRashi} />
                  </Link>
                </Box>
                {/* <Box className="independenceOffer">
                  <Box className="titleBlock">
                    <h1 className="mainTitle">Offer Zone</h1>
                  </Box>
                  <Box className="offerImg" onClick={() => navigate("/pujas")}>
                    <Link to="./">
                      <img src={IndependenceOffer} />
                    </Link>
                  </Box>
                </Box> */}
                {props.promotionalList && props.promotionalList.length > 0 ? (
                  <Box className="upPujaCard">
                    <TitleBlockCard title={props.TDTexts.UPCOMINGPUJAS} />

                    <Carousel
                      responsive={UPCOMING_PUJA_CAROUSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      {props.promotionalList.map((item: any, id: any) => {
                        const PromotionId = item?.id;

                        return (
                          <Box
                            onClick={() =>
                              navigate(`/promotionalPuja/${PromotionId}`)
                            }
                          >
                            <UpcomingPujasCard image={item.dashBoardBg?.uri} />
                          </Box>
                        );
                      })}
                    </Carousel>
                  </Box>
                ) : null}
                {props.featuresToDispaly && (
                  <Box className="featureDetailArea">
                    <TitleBlockCard
                      title={props.TDTexts.SPIRITUALINFORMATION}
                    />
                    <Carousel
                      responsive={FEATURE_TO_DISPLAY_CAUROSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      <DailyDetailsCard
                        panchangData={props.panchangData}
                        onClick={() => navigate("/panchang")}
                      />
                      {props.featuresToDispaly.map((item: any, id: any) => {
                        return (
                          <FeaturesDisplayCard
                            name={item.name}
                            image={item.img}
                            onClick={() =>
                              navigate("/specialTemples" + item.url)
                            }
                          />
                        );
                      })}
                    </Carousel>
                  </Box>
                )}

                <Box className="featureDetailArea">
                  <TitleBlockCard title="Explore Puja Categories" />
                  <Box className="tdCatglistblock">
                    {/* <List className="tdCatgList">
                      {props.categoryList && props.categoryList.map((item: any, id: any) => {
                        return (
                          <ListItem onClick={() => props.selectCategory(item.categoryId)}>
                            <ListItemAvatar className="tdAvatarItem">
                              <Avatar className="catgAvatar">
                                <img src={item.url}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText className="catgName">{item.name}</ListItemText>
                          </ListItem>
                        )
                      })}
                    </List> */}
                    <PujaCategoryList />
                  </Box>
                </Box>
                {props.popularTemples &&
                props.popularTemples &&
                props.popularTemples.length > 0 ? (
                  <Box className="popularTemple">
                    <TitleBlockCard title={props.TDTexts.MOSTPOPULARTEMPLES} />
                    <Carousel
                      responsive={POPULAR_TEMPLE_CAUROSAL_RESPONSIVE}
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      keyBoardControl={true}
                      removeArrowOnDeviceType={CAROUSEL_REMOVE_ARRAOW_ON_DEVICE}
                    >
                      {props.popularTemples &&
                        props.popularTemples.map((item: any, id: any) => {
                          const { personalInfo } = item;
                          let templeImage =
                            personalInfo &&
                            personalInfo.media &&
                            personalInfo.media.pictures &&
                            personalInfo.media.pictures.length > 0
                              ? personalInfo.media.pictures[0].pictureUrl
                              : // ? getOneImageForTemple(
                                //     personalInfo.media.pictures
                                //   )
                                "";
                          return (
                            <TempleCard
                              image={templeImage}
                              templeName={personalInfo.name}
                              templeDescription={personalInfo.descriptions}
                              templeId={item.id}
                              navigate={props.navigate}
                            />
                          );
                        })}
                    </Carousel>
                  </Box>
                ) : null}

                {/* <Box className="blessings">
                  <TitleBlockCard title="Blessings" />
                  <BlessingsCard />
                </Box> */}

                <Box className="recentPost">
                  {/* <TitleBlockCard title="Recent Posts" /> */}
                  <Box className="recentPostCard">
                    <Grid container spacing={2}>
                      {props.posts.map((item: any, id: any) => {
                        return (
                          <Grid item xs={12} md={12} lg={12} xl={12} key={id}>
                            <PostCard
                              postProfileImage={item.postDetails.userImageUrl}
                              postUserName={item.postDetails.userName}
                              postTime={"3 Jan"}
                              postDescription={item.postDetails.postDescription}
                              // postImage={item.postMedia[0].postMediaFileLink}
                              postData={item}
                              setShowCommentForPost={setShowCommentForPost}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                  <Dialog
                    className="startPostInput"
                    onClose={() =>
                      setShowCommentForPost({ shouldOpened: false, postId: "" })
                    }
                    open={showCommentForPost.shouldOpened}
                  >
                    <ViewCommentsForPost postId={showCommentForPost.postId} />
                  </Dialog>
                  {/* {showCommentForPost.shouldOpened?<ViewCommentsForPost />:null} */}
                </Box>
                <Box className="blessYSiso">
                  <Box className="blessIsoImg">
                    <img src={BlessYSiso} />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default HomeView;

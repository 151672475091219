// components/MobileAppPrompt.tsx
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box, Grid } from '@mui/material';
import playstore from "../../assets/play-store.png";
import appstore from "../../assets/app-store.png";
import "./MobileAppPrompt.scss";


interface MobileAppPromptProps {
  open: boolean;
  onClose: () => void;
  onDownload: (platform: string) => void;
}

const MobileAppPrompt: React.FC<MobileAppPromptProps> = ({ open, onClose, onDownload }) => {
  return (
    <Dialog open={open} onClose={onClose} className="appPromotionPopup">
      <DialogTitle className="appPromotionTitle">
        Download Our Mobile App
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ backgroundColor: "#c4c4c4", height: 2 }} />
      <DialogContent className="appPromotionContent">
        <Grid container direction="row" spacing={2}>
          <Grid item sm={12} xs={12} md={6} xl={6}>
            <Button type="button">
              <img
                src={playstore}
                alt="Play Store"
                onClick={() => {
                  onDownload('android');
                }}
              />
            </Button>
          </Grid>
          <Grid item sm={12} xs={12} md={6} xl={6}>
            <Button
              type="button"
              onClick={() => {
                onDownload('ios');
              }}
            >
              <img src={appstore} alt="App Store" />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={onClose}>Cancel</Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default MobileAppPrompt;

import Carousel from "react-multi-carousel";
import redlike from "../../../assets/redlike.png";
import share from "../../../assets/share.png";
import verifytick from "../../../assets/verify-tick.png";
import { TEMPLE_CARD_CAUROSAL_RESPONSIVE } from "../../../utils/Constants"
import { Box, Typography, Button, Stack, Badge, } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link } from "react-router-dom";
import "../../../Views/Temple/ShaktipethaView.scss";
import ReadMoreReadLess from "../../ReadMoreReadLess/ReadMoreReadLess";

export default function ShaktipeethaDetailsCard(props: any) {

  return (
    <Box
      key={props.id}
      className="shaktipethaTemple">
      <Box className="shaktipethaTop">
        {/* <Box className="shaktipethaProfile">
          <img src={props.templeImage} alt="" />
        </Box> */}
        <Box className="shaktipethaTitle">
          <Typography>
            {props.templeName}{" "}
            {/* {props.verificationStatus === "VERRIFIED" ? (
              <img src={verifytick} alt="" />
            ) : null} */}
            {/* <img src={verifytick} alt="" /> */}
          </Typography>
        </Box>
      </Box>
      <Box className="shaktipethaImage">
        <Carousel
          responsive={TEMPLE_CARD_CAUROSAL_RESPONSIVE}
          swipeable={true}
          draggable={true}
          showDots={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {props.templePictures.map(
            (item2: any, id: any) => {
              return (
                <img key={id} src={item2.pictureUrl}></img>
              )

            }
          )}
        </Carousel>
      </Box>
      {props.onboarded?<Badge className="onboardedBadge">Onboarded</Badge>:null}
      {/* <Badge className="onboardedBadge">Onboarded</Badge> */}
      <Box className="shaktipethaDes">
        <Box className="shaktiDesTop">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>Overview</Typography>
            {props.onboarded?<Button onClick={()=>props.onClickNavigateToTemple(props.templeId)}className="visitBtn" variant="contained">Visit</Button>:null}
            
          </Stack>
        </Box>
        <Box className="shortText">
          {/* <Typography>{props.descriptions}</Typography> */}
          <ReadMoreReadLess text={props.descriptions} maxLength={100}></ReadMoreReadLess>
        </Box>
      </Box>

      {/* <Box className="ganpatiBottom">
          <Box className="ratings">
            <i className="bi bi-star-fill"></i>
            <Typography>
              <span>{props.googleData?.rating}</span>{props.googleData?.user_ratings_total}+ ratings
            </Typography>
          </Box>
          <Box className="likeShare">
            <Link to="#">
              <img src={redlike} alt="" />
            </Link>
            <Link to="#">
              <img src={share} alt="" />
            </Link>
          </Box>
        </Box> */}
    </Box>
  )
}
/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserService, TempleService, PanchangService,CommonService } from "td_server";
import {
    setLoading,
    setSelectedTempleDetailsForPuja,
    setPromotionalList,
    setSelectedPromotionalPujaOrganisations,
    setPujaCategoryList,
    setPujaMergedSubCategoryList
  } from "./../slices/pujaSlice";
export const getTempleDetailsById =
  (templeId: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getOrganisationDetailsById(templeId);
      // console.log("response = ", response);
      dispatch(setSelectedTempleDetailsForPuja(response));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getPromotionalPujaList = () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaDashBoardListActive();
      // console.log("response = ", response);
      dispatch(setPromotionalList(response?.promotionalList));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getPromotionalPujaListById = (id:any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await TempleService.getPromotionalPujaListById(id);
      // console.log("response promotional by Id = ", response);
      dispatch(setSelectedPromotionalPujaOrganisations(response?.organisation));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const getTemplePujaCategoryList = () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getTemplePujaCategoryList();
      // console.log("response Category res = ", response);
      dispatch(setPujaCategoryList(response?.categoryList));
      dispatch(setPujaMergedSubCategoryList(response?.mergedSubCategory));

      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('getPanchangData  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };


import "react-multi-carousel/lib/styles.css";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import { useSelector } from "react-redux";

function ParentView(props: any) {


  const { userInfo } = useSelector((state: any) => state.user);
  // console.log("Texts", props);

  return (
    <>
      {props.children}
      <div className="bottomFooter">
        {/* {userInfo?.id ? <Footer /> : null} */}
        <Footer />
      </div>
    </>
  );
}

export default ParentView;

import * as React from "react";
import { Typography } from "@mui/material";

export default function SubtitleTypographyPrimary(props: any) {
  const typoGraphyDefaultCss = {
    color: "#d56939",
    fontSize: "16px",
    fontWeight: "600",
  };
  return (
    <Typography
      style={{
        fontSize: props.fontSize || typoGraphyDefaultCss.fontSize,
        color: props.color || typoGraphyDefaultCss.color,
        fontWeight: props.color || typoGraphyDefaultCss.fontWeight,
      }}
    >
      {props.title}
    </Typography>
  );
}

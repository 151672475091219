import { useContext, useEffect, useState } from "react";
import LandingView from "../../Views/Home/HomeView";
import {
  mainCategories,
  upcomingPujas,
  popularTemples,
  posts,
  featuresToDispaly,
} from "../../utils/Constants";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { indigo } from "@mui/material/colors";
import { TextContext } from "../../text/TextProvider";
import ParentView from "../../Views/PrarentView/ParentView";
import { getPanchangData } from "../../store/actions/templeAction";
import moment from "moment";
import { generatePanchangPayload } from "../../utils/HelperFunctions";
import {CommonService} from "td_server";
function HomeScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { constantTexts }: any = useContext(TextContext);
  const [postsList, setPostsList] = useState([]);
  const [mostPopularTempleListView, setMostPopularListOfTemple] = useState([]);
  const [userAddressInfoAggregated, setUserAddressInfoAggregated]: any =
    useState(null);
  const { cartInfo } = useSelector((state: any) => state.cart);
  const { userInfo, currentAddress } = useSelector((state: any) => state.user);
  const { allTempleList, panchangData, verifiedTempleList } = useSelector(
    (state: any) => state.temple
  );
  const { promotionalList,pujaCategoryList } = useSelector((state: any) => state.puja);
  const personalInfo = userInfo?.personalInfo;
  const userPP = personalInfo?.profilePictureUrl || "";
  const userAddressList = personalInfo?.address || [];
  // console.log("Address", userAddressList);
  useEffect(() => {
    adjustPopularTemples();
  }, [verifiedTempleList]);

  function adjustPopularTemples() {
    if (verifiedTempleList && verifiedTempleList?.length > 0) {
      // Sort the data in descending order based on rating
      let sortedData = verifiedTempleList
        ?.slice()
        .sort((a: any, b: any) => b.googleData?.rating - a.googleData?.rating);
      // Filter data with rating greater than or equal to 4
      let filteredData = sortedData.filter(
        (item: any) => item.googleData?.rating >= 4
      );
      if (filteredData && filteredData.length > 0) {
        if (filteredData.length > 5) {
          setMostPopularListOfTemple(filteredData.slice(0, 5));
        } else {
          setMostPopularListOfTemple(filteredData);
        }
      } else {
        if (filteredData.length > 5) {
          setMostPopularListOfTemple(sortedData.slice(0, 5));
        } else {
          setMostPopularListOfTemple(sortedData);
        }
      }
    }
  }

  // console.log("Most poloular temples---", mostPopularTempleListView);
  const generateUserAddressData = () => {
    if (currentAddress) {
      return currentAddress.address;
    } else if (userAddressList && userAddressList.length > 0) {
      return userAddressList[0];
    } else {
      return null;
    }
  };

  useEffect(() => {
    const aggregatedAdresss = generateUserAddressData();
    setUserAddressInfoAggregated(aggregatedAdresss);
  }, [currentAddress, userInfo]);

  useEffect(() => {
    const todayDate = moment()
      .utcOffset("+05:30")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    // console.log("Today date",todayDate)
    const panchangData = generatePanchangPayload(currentAddress, todayDate);
    // console.log("Pancahng payload formed",panchangData)
    dispatch(getPanchangData(panchangData));
  }, [currentAddress]);

  const selectCategory =(catId:any)=>{
    navigate('/search/category/'+catId);
  }


  // console.log("user address aggregated", userAddressInfoAggregated);
  return (
    <ParentView>
      <LandingView
        userPP={userPP}
        cartDetails={cartInfo}
        mainCategories={mainCategories}
        upcomingPujas={upcomingPujas}
        popularTemples={mostPopularTempleListView}
        posts={postsList}
        featuresToDispaly={featuresToDispaly}
        navigate={navigate}
        userAddressData={userAddressInfoAggregated}
        TDTexts={constantTexts}
        panchangData={panchangData}
        promotionalList={promotionalList}
        categoryList={pujaCategoryList}
        selectCategory={selectCategory}
      />
    </ParentView>
  );
}

export default HomeScreen;

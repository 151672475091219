import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  ButtonGroup,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InfoIcon from "@mui/icons-material/Info";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CircularProgress from '@mui/material/CircularProgress';
import "./styles.css";
import {
  getDateFormatedOrderTime,
  getFormattedDate,
  splitCamelCase,
  capitalizeWords,
  convertToNormalString,
} from "../../../utils/HelperFunctions";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import AnalyticFilter from "./AnalyticFilter";
import FilterDropdown from "./FilterDropdown";
import AnalyticsModel from "./AnalyticsModel";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfinitScroll from "react-infinite-scroll-component";
const AnalyticsView = (props: any) => {
  //const [currentFilter, setCurrentFilter] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [analyticData, setAnalyticData] = useState(true);
  const [resetDropDownFilter, setResetDropDownFilter] = useState(false);
  const handleClose = () => {
    setOpen(false);
    //props.filterByStatus("viewed")
  };

  // ----- For Three Tab -----
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "3") return;
    //setValue(newValue);
    const status = newValue == "1" ? "new" : "viewed";
    props.filterByStatus(status);
  };
  //For Three Tab End

  useEffect(() => {
    setValue(props.currentFilterStatus);
  }, [props.currentFilterStatus]);

  const handleFilter = (filterType: string) => {
    props.setCurrentFilter(filterType);
    // setResetDropDownFilter(false)
    // setTimeout(() => {
    //   setResetDropDownFilter(true)
    // }, 100);

    // You can perform any other actions based on the selected filter
  };

  const openAnalyticsModal = (data: any) => {
    
    setAnalyticData(data);
    setOpen(true);
  };

  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Box className="row">
          <Box className="col-md-3 col-sm-12 col-xs-12">
            {/* <LeftAddressCard
            header="Current Address"
            address="198/3, 3 Cross 80 Feet Road, Next.."
            footerButton="Edit or add an address"
          /> */}
          </Box>
          <Box className="col-md-6 col-sm-12 col-xs-12">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom={2}
            >
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <FilterDropdown
                    analyticslist={props.analyticsKeys}
                    setKeyFilter={props.setKeyFilter}
                    reset={resetDropDownFilter}
                  ></FilterDropdown>
                </Grid>
                <Grid xs={6} sx={{ paddingTop: "16px", textAlign: "end" }}>
                  <AnalyticFilter
                    activeFilter={props.activeFilter}
                    handleFilter={handleFilter}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mb: "20px", height: "75px" }}
              >
                <Grid item xs={12}>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <TabList centered onChange={handleChange}>
                            <Tab label="New" value="1" />
                            <Tab label="Viewed" value="2" />
                            {/* <Tab label="Download Analytics" onClick={e=>e.stopPropagation()} /> */}
                          </TabList>
                          <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<DownloadForOfflineIcon />}
                            disabled={
                              typeof props.analyticslist === "undefined" ||
                              props.analyticslist?.length === 0
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              props.downloadAnalytics();
                            }}
                          >
                            Download Analytics
                          </Button>
                        </Stack>
                      </Box>
                      <TabPanel value="1"></TabPanel>
                      <TabPanel value="2"></TabPanel>
                      {/* <TabPanel value="3"></TabPanel> */}
                    </TabContext>
                  </Box>
                  {/* <ButtonGroup variant="contained" aria-label="Basic button group">
                    <Button className='btngroupanalytics'>New</Button>
                    <Button className='btngroupanalytics'>Viewed</Button>
                    <Button className='btngroupanalytics'>All Users</Button>
                  </ButtonGroup> */}
                </Grid>
              </Grid>
            </Box>
            <Box className="templeCenterBlock">
              {(typeof props.analyticslist === "undefined" ||
                props.analyticslist?.length === 0) &&
                props.loading === false && (
                  <Card sx={{ marginBottom: "15px" }}>
                    <CardContent>
                      <Typography component="div">
                        No user analytics data found
                      </Typography>
                    </CardContent>
                  </Card>
                )}


              { props.loading === true && (
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {" "}
                  <CircularProgress />
                
                </Stack>
              )}

              {/* <InfinitScroll 
              dataLength={100}
              next={()=>console.log("Hello next api")}
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
              inverse={true} //
              hasMore={true}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
              > */}
              {props?.analyticslist &&
                props?.analyticslist.length > 0 &&
                props?.analyticslist.map((item: any, index: number) => {
                  const firstChar = item?.eventData?.userName?.charAt(0);
                  if (!(item && item.eventData)) {
                    return;
                  }
                  return (
                    <Card
                      sx={{ marginBottom: "15px" }}
                      key={`analytics${index}`}
                    >
                      <CardHeader
                        avatar={
                          <Avatar
                            sx={{
                              bgcolor:
                                "#" +
                                ((Math.random() * 0xffffff) << 0)
                                  .toString(16)
                                  .padStart(6, "0"),
                            }}
                            aria-label="recipe"
                          >
                            {firstChar}
                          </Avatar>
                        }
                        action={
                          <IconButton
                            aria-label="settings"
                            onClick={(e) => {
                              e.preventDefault();
                              openAnalyticsModal(item);
                            }}
                          >
                            {/* <MoreVertIcon /> */}
                            <InfoIcon></InfoIcon>
                          </IconButton>
                        }
                        title={capitalizeWords(item.key)}
                        subheader={getDateFormatedOrderTime(item.dateUTC)}
                        sx={{ backgroundColor: "rgb(213, 105, 57, 0.1)" }}
                      />
                      <CardContent>
                        <Grid item xs={12} key={index} sx={{ marginTop: 1 }}>
                          <Stack direction="row" spacing={1}>
                            <Typography
                              component="h6"
                              sx={{ fontWeight: "bold" }}
                            >
                              {"Visits Count"} :
                            </Typography>
                            <Typography component="h6">
                              {`${item?.count + 1} times`}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid container>
                          {item?.eventData &&
                            Object.entries(item?.eventData).map(
                              (data: any, index) => {
                                const containsObject = data.some(
                                  (item: any) => typeof item === "object"
                                );

                                if (containsObject) {
                                  return null;
                                }

                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    key={index}
                                    sx={{ marginTop: 1 }}
                                  >
                                    <Stack direction="row" spacing={1}>
                                      <Typography
                                        component="h6"
                                        sx={{ fontWeight: "bold" }}
                                      >
                                        {splitCamelCase(data[0])} :
                                      </Typography>
                                      <Typography component="h6">
                                        {data[1]}
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                        <Grid container>
                          {item?.status && (
                            <>
                              <Grid item xs={12} key={index}>
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  sx={{ marginTop: 1 }}
                                >
                                  <Typography
                                    component="h6"
                                    sx={{ fontWeight: "bold" }}
                                  >
                                    {splitCamelCase("status")} :
                                  </Typography>
                                  <Typography component="h6">
                                    {convertToNormalString(
                                      Object.keys(
                                        item?.status[item?.status?.length - 1]
                                          ?.event
                                      )[0]
                                    )}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </CardContent>

                      <CardActions
                        disableSpacing
                        sx={{
                          justifyContent: "space-between",
                          padding: "16px",
                        }}
                      >
                        {/* <Button
                          size="small"
                          variant="outlined"
                          className="userAnalyticBtn"
                          sx={{ mr: "8px" }}
                        >
                          <TaskAltRoundedIcon />
                          Mark As Read
                        </Button> */}
                        {/* <Button
                          size="small"
                          variant="outlined"
                          className="userAnalyticBtn"
                          onClick={(e) => {
                            e.preventDefault();
                            openAnalyticsModal(item);
                          }}
                        >
                          View Details
                        </Button> */}
                      </CardActions>
                    </Card>
                  );
                })}
              {/* </InfinitScroll> */}
            </Box>
            {props?.analyticslist && props?.analyticslist.length > 0 && (<Button onClick={() => props.fetchNextData()}>Load more</Button>)}
            
          </Box>
          <Box className="col-md-3 col-sm-12 col-xs-12">
            {/* <RightBlockCard /> */}
          </Box>
          <AnalyticsModel
            isOpen={open}
            onClose={handleClose}
            data={analyticData}
            dateRange={props.dateRange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AnalyticsView;

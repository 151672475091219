import VirtualPoojaBookingView from "../../Views/Puja/VirtualPoojaBookingView";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, Navigate,useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { getTempleDetailsById } from "../../store/actions/templeAction";
import { setSelectedPuja,setSelectedTempleDetailsForPuja } from "../../store/slices/pujaSlice";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";

function VirtualPujaBookingScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [askUserToModifyCartPopUp,setAskUserToModifyCartPopUp] = useState(false)
  const [selectedPujaDetails,setSelectedPujaDetails] = useState(null)
  const {selectedTempleDetails} = useSelector(
    (state: any) => state.temple
  );
  const templeIdComing = location.pathname.split("/")[2];

  console.log("temple path", templeIdComing);

  const onClickAddPuja = (pujaDetails: any) => {
    console.log("Add puja details",pujaDetails);
    dispatch(setSelectedTempleDetailsForPuja(selectedTempleDetails))
    dispatch(setSelectedPuja(pujaDetails))
    // navigate("/checkout/puja-details");
    navigate(`/temples/${templeIdComing}/pujaInfo/${pujaDetails.pujaId}`)
  };

  const userConfirmUpdatePujaDetails = ()=>{
    // console.log("selected puja is ", selectedPujaDetails);
    // dispatch(setPujaDetails(selectedPujaDetails));
    // navigate("/checkout/puja-details");
  }



  const templePersonalInfo = selectedTempleDetails&&selectedTempleDetails.personalInfo?selectedTempleDetails.personalInfo:{}
  console.log("Temple personal info",templePersonalInfo)
  const templeMediaList = templePersonalInfo&&templePersonalInfo.media&&templePersonalInfo.media.pictures&&templePersonalInfo.media.pictures.length?templePersonalInfo.media.pictures:[]
  const templeProfilePic = () => {
    let imageArrayForTemple = templeMediaList
    let templeProfilePic = getOneImageForTemple(imageArrayForTemple)
    return templeProfilePic;
  };

  useEffect(() => {
    console.log("in fetch temle")
    dispatch(getTempleDetailsById(templeIdComing))
  }, []);
  return (
    <ParentView>
        <VirtualPoojaBookingView
          templeDetails={selectedTempleDetails}
          templePersonalInfo={templePersonalInfo}
          templeMediaList={templeMediaList}
          onClickAddPuja={onClickAddPuja}
          templeProfilePic={templeProfilePic}
          setAskUserToModifyCartPopUp={setAskUserToModifyCartPopUp}
          askUserToModifyCartPopUp={askUserToModifyCartPopUp}
          userConfirmUpdatePujaDetails={userConfirmUpdatePujaDetails}
        />
      
    </ParentView>
  );
}

export default VirtualPujaBookingScreen;

import LoginView from "../../Views/Auth/Login/LoginView";
import { useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../utils/Constants";
import { useEffect, useState } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../configs/firebaseConfig";
import {
  UserService,
  TempleService,
  PanchangService,
  PostService,
  AuthService,
} from "td_server";
import {
  loginWithPhoneNumber,
  resendOtpAction,
  verifyOtp,
} from "../../store/actions/authAction";
import { error } from "console";
import ParentView from "../../Views/PrarentView/ParentView";
import LoadInitialData from "../../handlers/LoadInitialData";

function LoginScreen() {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const [phone, setPhone]: any = useState("");
  const [otp, setOtp]: any = useState("");

  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [preOtpErrorMsg,setPreOtpErrorMsg] = useState("");
  const [enableResendOtp, setEnableResendOtp] = useState(false);
  const [downTimerCount, setDownTimerCount] = useState(30);
  const otpConfirmation = useSelector((state: any) => state.auth.confirmation);

  const OTP_RESEN_DDURATION = 30000;
  var interValsetFunId: any = null;
  useEffect(() => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: async (response: any) => {},
      }
    );
  }, [auth]);

  useEffect(() => {
    if (otpConfirmation) {
      setIsOtpScreen(true);
    }
  }, [otpConfirmation]);

  const sendOtp = async (e: any) => {
    e.preventDefault();
    setPreOtpErrorMsg("")
    console.log("Location", window.location.origin);
    dispatch(loginWithPhoneNumber(phone)).then((data:any)=>{
      console.log("Send OTP")
    })
    .catch((err:any)=>{
      setPreOtpErrorMsg(err.errorMessage);
      console.log("ERROR",err)
    })
    countTimeForNextOtp();
    // addSetInterVal()
    // setCountDown()
  };

  const resendOtp = async (e: any) => {
    console.log("In resend otgp");
    try {
      dispatch(resendOtpAction(otpConfirmation));
    } catch (exception: any) {}
  };

  const verifyOtpSubmit = async (e: any) => {
    e.preventDefault();
    dispatch(verifyOtp(otp, otpConfirmation, phone))
      .then((e: any) => {
        console.log("User verified succesfsfully", e);
        if(e.newUser){
          setIsOtpScreen(false)
          navigate('/choose-profile')

        }
        else{
          let navUri: any = localStorage.getItem("redirectUri");
          console.log("nva uri", navUri);
          setIsOtpScreen(false)
          LoadInitialData(dispatch);
          if (navUri && navUri.length) {
            localStorage.removeItem("redirectUri");
            navigate(navUri);

          } else {
            navigate("/");
          }

        }

      })
      .catch((error: any) => {
        console.error("Error:", error);
        if (error === "InvalidOtp") {
          setLoginErrorMessage("Invalid OTP entered please re enter OTP");
        }
      });
  };

  const countTimeForNextOtp = () => {
    setTimeout(() => {
      setEnableResendOtp(true);
    }, OTP_RESEN_DDURATION);
  };

  // const addSetInterVal = ()=>{
  //   interValsetFunId = setInterval(() => {
  //     console.log("down timer")
  //     if (!enableResendOtp) {
  //       console.log("Resend otp in",downTimerCount - 1)
  //       setDownTimerCount(downTimerCount - 1);
  //     }
  //     else{
  //       clearInterval(interValsetFunId);
  //     }
  //   }, 1000);
  // }

  // console.log("Down counter ",downTimerCount)

  function onClickSignUp() {
    navigate("/signup");
  }

  return (
    <div>
      <ParentView>
        <LoginView
          onClickSignUp={onClickSignUp}
          isOtpScreen={isOtpScreen}
          setIsOtpScreen={setIsOtpScreen}
          sendOtp={sendOtp}
          verifyOtpSubmit={verifyOtpSubmit}
          setOtp={setOtp}
          setPhone={setPhone}
          phone={phone}
          otp={otp}
          resendOtp={resendOtp}
          otpConfirmation={otpConfirmation}
          loginErrorMessage={loginErrorMessage}
          enableResendOtp={enableResendOtp}
          downTimerCount={downTimerCount}
          preOtpErrorMsg={preOtpErrorMsg}
        />
      </ParentView>
    </div>
  );
}

export default LoginScreen;

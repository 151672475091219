import {createSlice} from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    isLoading: false,
    error: null,
    cartInfo: [],
    selectedAddressInCart:null,
    pujaPriceInfo:null,
    verifyCouponPriceInfo:null,
    isCouponValid:false,
    isCouponApplied:false,

  },
  reducers: {
    setCartItems: (state, action) => {
      state.cartInfo = action.payload;
    },
    setCart: (state, action) => {
      state.cartInfo = action.payload;
    },
    setSelectedAddressInCart: (state, action) => {
      state.selectedAddressInCart = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPujaPriceInfo: (state, action) => {
      state.pujaPriceInfo = action.payload;
    },
    setVerifyCouponPriceInfo: (state, action) => {
      state.verifyCouponPriceInfo = action.payload;
    },
    setIsCouponValid: (state, action) => {
      state.isCouponValid = action.payload;
    },
    setIsCouponApplied: (state, action) => {
      state.isCouponApplied = action.payload;
    },
  },
});

export const {setCartItems, setLoading, setError, setCart,setSelectedAddressInCart,setPujaPriceInfo,setVerifyCouponPriceInfo,setIsCouponValid,setIsCouponApplied} = cartSlice.actions;

export default cartSlice.reducer;

import TextField from "@mui/material/TextField";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useNavigate } from "react-router-dom";
import "./PujaDetailsView.scss";
import AddEditMembers from "../../../../components/pujaform/AddEditMembers";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Typography, Button } from "@mui/material";
import pujaIcon from "../../../../assets/puja-name-icon.png";
import templeIcon from "../../../../assets/temple-icon.png";

import {
  uid,
  getDateToday,
  getFormattedDate,
  getDateFormatedForBookingDate,
  getDateFormatedForPujaDetails,
} from "../../../../utils/HelperFunctions";
import dayjs from "dayjs";
import TitleTypography from "../../../../components/typography/TitleTypography";
import RoundedButton from "../../../../components/button/RoundedButton";
import { useEffect, useRef } from "react";

function PujaDetailsView(props: any) {



  const containerRef = useRef<HTMLDivElement | null>(null);
  const targetDivRef = useRef<HTMLDivElement | null>(null);

  const scrollToEdit= () => {
    if (targetDivRef.current) {
      targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToSave= () => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const templeName =
    props.templeDetails &&
      props.templeDetails.personalInfo &&
      props.templeDetails.personalInfo.name
      ? props.templeDetails.personalInfo.name
      : "";

  // console.log(
  //   "Puja Date and time",
  //   getFormattedDate(props.pujaDateAndTime.pujaDate)
  // );

  

  useEffect(() => {
   if(props.memberDetails.length > 0){
    scrollToEdit()
   }
  }, [props.memberDetails])

const handleAddOrEditMemberSubmit = (event:any)=>{
  props.addOrEditMemberSubmit(event)
  scrollToEdit()
  
}

  

  const singleCartData = props.cartInfo && props.cartInfo.length > 0 ? props.cartInfo[0] : null
  const maxMemberAllowed = Number(singleCartData?.pujaPackage?.maxMemberAllowed || singleCartData?.pujaDetails?.maxMemberAllowed)
  return (
    <Box className="addPujaDetails">
      {!Number.isNaN(maxMemberAllowed) && <Box className="reminderText">
        <p>
          <button type="button">
            <InfoOutlinedIcon />
          </button>{" "}
          You can add maximum {maxMemberAllowed} members for this puja
        </p>
      </Box>}
      <div ref={containerRef}></div>

      {props.isAddEditMemberScreen ? (
        <AddEditMembers
         
          setIsAddEditMemberScreen={props.setIsAddEditMemberScreen}
          addOrEditMemberSubmit={
           
            handleAddOrEditMemberSubmit
          }
          memberDetailsGettingAddedOrEdited={
            props.memberDetailsGettingAddedOrEdited
          }
          setMemberDetailsGettingAddedOrEdited={
            props.setMemberDetailsGettingAddedOrEdited
            
          }
          pujaDetails={singleCartData?.pujaDetails}
          templeDetails={singleCartData?.templeDetails}
          pujaBookingDate={getDateFormatedForPujaDetails(
            singleCartData?.pujaDateTime?.pujaDate
          )}
          whatsAppNumber={props.whatsAppNumber}
          setWhatsAppNumber={props.setWhatsAppNumber}
          noOfMembers={singleCartData?.members?.length | 0 }
          maxMemberAllowed={maxMemberAllowed}
        />
      ) : null}

      <Box className="memberDetails">
        <Box className="topTitle">
          <Typography>Puja Details</Typography>
        </Box>
        <Box className="pujaInfo">
          <Typography><img src={pujaIcon} alt="Puja Icon" /> {singleCartData?.pujaDetails?.pujaName}</Typography>
          <Typography><img src={templeIcon} className="templeIcon" alt="Temple Icon" /> {singleCartData?.templeDetails?.personalInfo?.name}</Typography>
        </Box>
        <Box
          className="dateAndTime"
        // onClick={() => props.setIsDateTimePicker(true)}
        >
          <Box className="pujaDate">
            <Typography className="sectionTitle">Puja Date</Typography>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                className="dateField"
                id="input-with-sx"
                value={getDateFormatedForPujaDetails(
                  singleCartData?.pujaDateTime?.pujaDate
                )}
                variant="standard"
              />
              <CalendarMonthIcon
                sx={{ color: "action.active", mr: 1, my: 0.5 }}
              />
            </Box>
          </Box>
        </Box>

        <Box className="topTitle">
          <Typography>Member Details</Typography>
          <button type="button">
            <KeyboardArrowDownRoundedIcon />
          </button>
        </Box>
        {/* <p>Add or Edit Members</p> */}
       
        {props.memberDetails.map((item: any, id: any) => {
          return (
            <Box className="memberName">
              <Typography>{item.fullName}</Typography>
              <Box className="editDelete">
                <button
                  type="button"
                  onClick={() => {
                    props.setIsAddEditMemberScreen(true);
                    props.setMemberDetailsGettingAddedOrEdited(item);
                    props.setEditFlag(true);
                    scrollToSave();
                  }}
                >
                  <ModeEditOutlineRoundedIcon />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    props.deleteMemberFromPujaList(item.id);
                  }}
                >
                  <DeleteRoundedIcon />
                </button>
              </Box>
            </Box>
          );
        })}

        {

          (maxMemberAllowed - props.memberDetails.length !== 0) && (
            <Box className="addMemberBtn">
              <Button
                type="button"
                onClick={() => {
                  props.setIsAddEditMemberScreen(true);
                  props.setMemberDetailsGettingAddedOrEdited({ id: uid() });
                  props.setEditFlag(false);
                  scrollToSave();
                }}
              >
                +Add member
              </Button>
              <p>
                {maxMemberAllowed - props.memberDetails.length} member remaining to add (optional)
              </p>
            </Box>
          )
        }

      </Box>

      <Box className="gotoCartBtn">
        <Button
          onClick={() => props.proceedToCart()}

        >
          GO TO CART
        </Button>
      </Box>

      {/* <Dialog className="dateTimingSelection"
        onClose={() => props.setIsDateTimePicker(false)}
        open={props.isDateTimePicker}
      >
        <Box className="dateTimePopup">

          <TitleTypography title="Select Date & Time">Select Date & Time</TitleTypography>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={dayjs(props.pujaDateAndTime.pujaDate)}
              disabled
              onChange={(e: any) => {
                console.log("clicked date", e.toString());
                props.setPujaDateAndTime({
                  ...props.pujaDateAndTime,
                  pujaDate:  e.toString(),
                });
              }}
            />
          </LocalizationProvider>




          <Box className="btnContinue">
            <RoundedButton title="Continue" onClick={() => props.setIsDateTimePicker(false)}></RoundedButton>
          </Box>

        </Box>
      </Dialog> */}
    </Box>
  );
}

export default PujaDetailsView;

import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import AddIcon from '@mui/icons-material/Add';
import EastIcon from "@mui/icons-material/East";
import "./ChooseAddress.scss";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Button } from "react-bootstrap";
function ChooseAddress(props: any) {
  const navigate = useNavigate();
  const addressList =
    props.userPersonalInfo && props.userPersonalInfo.address
      ? props.userPersonalInfo.address
      : [];

  console.log("address list", addressList);
  return (
    <>
      <Box>
        {addressList.map((item: any, id: any) => {
          return (
            <Box
              className="addressArea"
              onClick={() => {
                props.changeDeliverytAddress(item);
                props.setChooseAddressBox(false);
              }}
            >
              <Typography className="title">{item.savedAs || item.name}</Typography>
              <Box className="addressDetail">
                <Box className="addressLeft">
                  <Box className="addressIcon">
                    <PersonPinCircleOutlinedIcon />
                  </Box>
                  <Box className="addressText">
                    <Box className="addressText">
                      <Typography>{item.formatted_address}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="addressRight">
                  <Button type="button">
                    <EastIcon />
                  </Button>
                </Box>
              </Box>
            </Box>
          );
        })}
        <Box
          className="addressArea"
          onClick={()=>navigate("/profile/address")}
        >
          {/* <h6 className="title">Add an address</h6> */}
          <Box className="addressDetail">
            <Box className="addressLeft">
              <Box className="addressIcon">
                <AddIcon />
              </Box>
              <Box className="addressText">
                <Box className="addressText">
                  <Typography style={{fontWeight:"bold"}}>Add new address</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="addressRight">
              <Button type="button">
                {/* <EastIcon /> */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ChooseAddress;

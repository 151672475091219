import { useEffect, useState } from "react";
import TempleView from "../../Views/Temple/TempleView";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
function TempleScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const {cartInfo} = useSelector((state: any) => state.cart);
  const {allTempleList} = useSelector((state: any) => state.temple);
  const [templeList,setTempleList] :any= useState([])
  const [listToshow, setListToShow]: any = useState([]);

  useEffect(()=>{
    if(allTempleList&&allTempleList.length){
      makeLisIndexForSearch(allTempleList)
    }

  },[allTempleList])

  const makeLisIndexForSearch = (allTempleList:any) => {
    let finalList: any = [];
    for (let i = 0; i < allTempleList.length; i++) {
      let searchString = "";

        const templeName = allTempleList[i]?.personalInfo.name;
        const templeAddress =
        allTempleList[i]?.personalInfo?.address?.formatted_address;
        searchString = templeName + templeAddress;
        finalList = [
          ...finalList,
          { ...allTempleList[i], searchString: searchString },
        ];
      } 
    
    setTempleList(finalList);
    setListToShow(finalList);

  };

  const searchTempleForUser = (searchInput: any) => {
    const userInputLC = searchInput.toLowerCase();

    let visibleList: any = [];
    for (let i = 0; i < templeList.length; i++) {
      const searchStrinLC =
      templeList[i] && templeList[i].searchString
          ? templeList[i].searchString.toLowerCase()
          : "";
      if (searchStrinLC.includes(userInputLC)) {
        visibleList = [...visibleList, templeList[i]];
      }
    }
    setListToShow(visibleList);
  };

  const selectTemple = (templeDetails:any)=>{
    console.log("selected temple is ",templeDetails)
    navigate(templeDetails.id)
  }
    return (
      <ParentView>
        <TempleView templeList={listToshow} searchTempleForUser={searchTempleForUser} selectTemple={selectTemple} cartDetails={cartInfo}/>
      </ParentView>
    );
  }
  
  export default TempleScreen;
  
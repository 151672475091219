import React from "react";
import { useSelector } from "react-redux";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./PujaCategoryList.scss";
import { setMessageToReactNative } from "../../utils/WebNativeFunctions";


const PujaCategoryList: React.FC<any> = ({ isNativeView = false }) => {
  const navigate = useNavigate();
  const { pujaCategoryList } = useSelector((state: any) => state.puja);
  const navigatTo = (catId: string,index:number) => {
    if (isNativeView) {
      const data = {value:catId,isCategory:true,position:index}
      setMessageToReactNative(JSON.stringify(data));
    } else {
      navigate("/search/category/" + catId);
    }
  };
  return (
    <List className="tdCatgList">
      {pujaCategoryList &&
        pujaCategoryList.map((item: any,index:number) => (
          <ListItem
            key={item.categoryId}
            onClick={() => navigatTo(item.categoryId,index)}
          >
            <ListItemAvatar className="tdAvatarItem">
              <Avatar className="catgAvatar">
                <img  src={item.url} alt={item.name} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText className="catgName">
              {item.name}</ListItemText>
          </ListItem>
        ))}
    </List>
  );
};

export default PujaCategoryList;

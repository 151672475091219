import {createSlice} from '@reduxjs/toolkit';

const couponCodeSlice = createSlice({
  name: 'couponcode',
  initialState: {
    isLoading: false,
    couponcodeList:[
      
    ],
   
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setCouponCodeList: (state, action) => {
      state.couponcodeList = action.payload;
    },
    
  },
});

export const { setLoading,setCouponCodeList } = couponCodeSlice.actions;
export default couponCodeSlice.reducer;

import kaliPuja from "../../assets/Kali.jpg";
import kaliPujaimg from "../../assets/kali-puja.jpg";
import ganpati from "../../assets/ganpati-temple.jpg";
import subramanyatemple from "../../assets/Subramanya-temple.jpg";
import durgaPujaimg from "../../assets/durga-puja.jpg";
import durgaPujaprofile from "../../assets/durgapuja-profile.jpg";
import redlike from "../../assets/redlike.png";
import share from "../../assets/share.png";
import postprofile from "../../assets/temple-post-profile.jpg";
import media from "../../assets/media.png";
import date from "../../assets/date.png";
import writepost from "../../assets/writepost.png";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import "./PujaView.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Grid, Typography ,TextField, InputAdornment} from "@mui/material";
import Link from '@mui/material/Link';
import PujaDetailsCard from "../../components/card/Puja/PujaDetailsCard";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import { getOneImageForTemple } from "../../utils/HelperFunctions";
import Search from "@mui/icons-material/Search";
function PujaView(props: any) {
  const navigate = useNavigate();

  return (
    <Box className="pageContainer templePage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={8}>
          <TextField className="searchView"
              onChange={(e: any) => props.searchPujaForUser(e.target.value)}
              id="outlined-basic"
              // label="Search for temples and pujas"
              variant="outlined"
              placeholder="Search for pujas."
              sx={{
                "& fieldset": { border: 'none' },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search sx={{ color: '#000'}}/>
                  </InputAdornment>
                ),
              }}
            />
            <Box className="templeCenterBlock pujaBlock">
              {props.allPujaList &&
                props.allPujaList.map((item: any, id: any) => {
                  let templeProfilePic = getOneImageForTemple(item.personalInfo?.media?.pictures)
                  return (
                    <PujaListCard
                    pujaData={item}
                    pujaName={item.pujaDetails.pujaName}
                    templeProfilePic={item.pujaDetails.pujaBg?.uri || templeProfilePic}
                    description={item.pujaDetails.description}
                    benefits={item.pujaDetails.benefits}
                    acctualPrice={item.pujaDetails.acctualPrice}
                    onClickAddPuja={props.onClickAddPuja}
                    temple={item.personalInfo}
                    devoteeCount={item.pujaDetails?.maxMemberAllowed}
                    benefitList = {item?.pujaDetails?.benefitList}
                    isPujaDisable={item?.pujaDetails?.isPujaDisable}
                    />
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={2} xl={2} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PujaView;

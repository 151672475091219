
import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import errorIcon from "../../assets/error.png";

const ErrorView: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const handleClick = (e: any) => {
    e.preventDefault();
    if (navigator.onLine) {
      navigate('/');
    }
  }

  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: '0 24px',
      }}
    >
      <Box mb={3}>
        {/* <AdbTwoToneIcon style={{ fontSize: 80, color: '#f50057' }} /> */}
        <img style={{ width: "100px" }} src={errorIcon} alt="" />
      </Box>
      <Typography variant="h4" sx={{ fontWeight: 500 }} gutterBottom>
        Error
      </Typography>
      <Typography variant="body1" gutterBottom>
        {props?.message}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{ marginTop: "20px", boxShadow: "none", padding: "8px 25px" }}
      >
        Retry
      </Button>
    </Container>
  );
};



export default ErrorView;

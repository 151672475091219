import PersonPinCircleOutlinedIcon from "@mui/icons-material/PersonPinCircleOutlined";
import EastIcon from "@mui/icons-material/East";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import razorpay from "../../../../assets/badge-light-razor.png";
import Dialog from "@mui/material/Dialog";
import ChooseAddress from "../../../../components/chooseaddress/ChooseAddress";
import "./CartView.scss";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import TitleTypography from "../../../../components/typography/TitleTypography";
import SubtitleTypography from "../../../../components/typography/SubtitleTypography";
import razorPayIcon from "../../../../assets/razorpay_images.png";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";

function CartView(props: any) {
  const priceData = props.priceBreakup;
  const userDetails = props.userDetails;
  // console.log("price in view", priceData);

  
  return (
    <Box className="cartDetails">
      {userDetails ? (
        <Box
          className="addressArea"
          onClick={() => {
            if (props.selectedAddressDetails) {
              props.setChooseAddressBox(true);
            } else {
              props.navigateToAddressScreen();
            }
          }}
        >
          <Typography className="titleHead">
            {props.selectedAddressDetails
              ? props.selectedAddressDetails.savedAs ||
                props.selectedAddressDetails.name
              : "Address"}
          </Typography>
          <Box className="addressDetail">
            <Box className="addressLeft">
              <Box className="addressIcon">
                <PersonPinCircleOutlinedIcon />
              </Box>

              {props.selectedAddressDetails ? (
                <Box className="addressText">
                  <TitleTypography
                    title={props.selectedAddressDetails.savedAs}
                  ></TitleTypography>
                  <SubtitleTypography
                    title={props.selectedAddressDetails.formatted_address}
                  ></SubtitleTypography>
                </Box>
              ) : (
                <Box className="addressText">
                  <Box className="addressText">
                    <Typography>Please add your address..</Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className="addressRight">
              <button type="button">
                <EastIcon />
              </button>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className="logoutCartArea">
          <Box className="logoutCartAreaLeft">
            <Typography>
              You are not logged in. Please Login or register to place order.
            </Typography>
          </Box>
          <Box className="logoutCartAreaRight">
            <Button type="button" onClick={() => props.navigateToLoginScreen()}>
              login or signup
            </Button>
          </Box>
        </Box>
      )}

      <Box className="pujaAddedArea">
        <TitleTypography title="Puja Details"></TitleTypography>
        <Box className="pujaAddedDetails">
          <Box className="pujaAddedLeft">
            <TitleTypography
              title={`Puja at ${props?.templeDetails?.personalInfo?.name}`}
            ></TitleTypography>
            <TitleTypography
              title={props?.pujaDetails?.pujaName}
            ></TitleTypography>
            <SubtitleTypography
              title={`₹ ${priceData&&priceData.discount?props.priceInfoWithoutCoupon?.finalPrice:priceData.finalPrice}/-`}
              isLoading={priceData.finalPrice?false:true}
            ></SubtitleTypography>
          </Box>
          <Box className="pujaAddedRight">
            <SubtitleTypography
              title={`x ${props.memberDetails.length} Members`}
            ></SubtitleTypography>
            <button
              type="button"
              onClick={() => {
                props.editPujaOptions();
              }}
            >
              <ModeEditOutlineRoundedIcon />
            </button>
            <button type="button" onClick={() => props.clearCart()}>
              <DeleteRoundedIcon />
            </button>
          </Box>
        </Box>
        <Box className="pujaAddedMember">
          <TitleTypography title="Participating Member(s)"></TitleTypography>
          <Box className="memberName">
            {props.memberDetails.map((item: any, id: any) => {
              return <p>{item.fullName}</p>;
            })}
          </Box>
        </Box>
      </Box>

      <Box className="billDetails">
        <Box className="topTitle">
          <TitleTypography title="Bill Details"></TitleTypography>
        </Box>
        <Box className="billTopPart">
          <SubtitleTypography title="Puja Value">Puja Value</SubtitleTypography>
          <SubtitleTypography
            title={`₹ ${priceData&&priceData.discount?props.priceInfoWithoutCoupon?.finalPrice:priceData.finalPrice}/-`}
            isLoading={priceData.finalPrice?false:true}
          ></SubtitleTypography>
        </Box>

        {priceData.discount ? (
          <Box className="discountPart">
            <SubtitleTypography title="Discount">
              {/* <button type="button">
                <InfoOutlinedIcon />
              </button> */}
            </SubtitleTypography>
            <SubtitleTypography
              title={`₹ ${priceData.discount}/-`}
              isLoading={!priceData || priceData.discount === undefined}
            ></SubtitleTypography>
          </Box>
        ) : null}

        {/* <Box className="billMiddlePart">
          <Box className="thirdPart">
            <SubtitleTypography title="GST 18%">
              <button type="button">
                <InfoOutlinedIcon />
              </button>
            </SubtitleTypography>
            <SubtitleTypography
              title={`₹ ${priceData.gst}/-`}
              isLoading={!priceData || priceData.gst === undefined}
            ></SubtitleTypography>
          </Box>
        </Box> */}
        <Box className="billBottomPart">
          <SubtitleTypography title="Total Amount"></SubtitleTypography>
          <SubtitleTypography
            title={`₹ ${priceData.finalPrice}/-`}
            isLoading={!priceData || priceData.finalPrice === undefined}
          ></SubtitleTypography>
        </Box>
      </Box>

      {userDetails ? (
        <Box className="offerZone">
          <TitleTypography className="title">Offers Zone</TitleTypography>
          {props.couponApplyResponse.isApplied ? (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="couponAppliedText"
            >
              <Typography>Coupon applied = {props.couponCodeData}</Typography>
              <Button>
                <CloseIcon
                  onClick={() => {
                    props.setCouponApplyResponse({
                      ...props.couponApplyResponse,
                      isApplied: false,
                      isValid: false,
                    });

                    props.setPriceQuoteForPuja()
                  }}
                />
              </Button>
            </Stack>
          ) : (
            <Box
              className="offerZoneDetails"
              onClick={() => props.setCouponPopUp(true)}
            >
              <Box className="offerZoneLeft">
                <Typography>
                  <PercentRoundedIcon /> Apply Coupon
                </Typography>
              </Box>
              <Box className="offerZoneRight">
                <button type="button">
                  <EastIcon />
                </button>
              </Box>
            </Box>
          )}
        </Box>
      ) : null}

      {userDetails ? (
        <Box className="userDetails">
          <Box className="topTitle">
            <TitleTypography title="User Details"></TitleTypography>
            <button type="button">
              <KeyboardArrowDownRoundedIcon />
            </button>
          </Box>
          <Box className="mainDetailsArea">
            <Typography>
              {props.userPersonalInfo.firstName}{" "}
              {props.userPersonalInfo.lastName}
            </Typography>
            <Typography>
              {props?.userDetails?.phoneNumber?.substring(0, 7)}XXXX
              {props?.userDetails?.phoneNumber?.substring(
                props?.userDetails?.phoneNumber?.length - 2,
                props?.userDetails?.phoneNumber?.length
              )}
            </Typography>
            {/* hide the critical informtaion later */}
            <Typography>{props.userPersonalInfo.email}</Typography>
            <Box className="input-group checkBoxArea">
              <Box className="input-group-text checkBoxItem">
                <input className="form-check-input mt-0" type="checkbox" />
                <label className="onlyDesktop">
                  I want to receive transaction and puja updates on WhatsApp and
                  email.
                </label>
                <label className="onlyMobile">
                  I want to receive transaction
                  <br />
                  and puja updates on WhatsApp
                  <br />
                  and email.
                </label>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : null}

      <Box className="cancelPolicy">
        <TitleTypography className="title">Cancellation Policy</TitleTypography>
        <Box className="cancelPolicyDetails">
          <Typography>
            Orders can be cancelled 24 hours prior starting the puja, after that
            no refund for cancellations will be made.
          </Typography>
        </Box>
      </Box>

      {userDetails ? (
        <Box className="placeOrderArea">
          <Box className="placeOrderLeft">
            <img src={razorPayIcon} alt="" />
            <Box className="payUsingText">
              <Typography>
                Pay using{" "}
                {/* <button type="button">
                <ArrowDropUpIcon />
              </button> */}
              </Typography>
              <Typography className="upi">Razorpay</Typography>
            </Box>
          </Box>
          <Box className="placeOrderRight">
            <button type="button" onClick={(e: any) => props.placeOrder(e)}>
              <Typography>₹ {priceData.finalPrice} | Place Order</Typography>
            </button>
          </Box>
        </Box>
      ) : null}

      <Dialog
        className="startPostInput"
        onClose={() => props.setChooseAddressBox(false)}
        open={props.chooseAddressBox}
      >
        <ChooseAddress
          userPersonalInfo={props.userPersonalInfo}
          changeDeliverytAddress={props.changeDeliverytAddress}
          setChooseAddressBox={props.setChooseAddressBox}
        />
      </Dialog>

      {/* <Dialog
        className="Orderresult"
        onClose={() => props.setOrderCreatedScreen(false)}
        open={props.orderCreatedScreen}
      >
        <Box style={{ width: "20em", height: "15em",padding:"1em" }}>
          <Typography style={{marginTop:"1em"}}>
          Your order Created Successfully
          </Typography>
          <Typography style={{fontWeight: "bold"}}>
          Order Id : {props.orderResultDetails}
          </Typography>
         <Button onClick={()=>{dispatch(setCartItems([]));}}>Ok</Button>
        </Box>
      </Dialog> */}

      <Dialog
        className="warnMsg"
        onClose={() =>
          props.setErrorPopUp({ isError: false, errorMessages: [] })
        }
        open={props.errorPopup.isError}
      >
        <Box className="warnCard">
          <Typography>
            <ErrorOutlineRoundedIcon />
            Please take action on below items before submiting
          </Typography>
          {props.errorPopup.errorMessages.map((item: any, id: any) => {
            if (item.errType === "addressNotGiven") {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{ margin: "20px 0 10px" }}
                >
                  <Typography className="warnItem">{item.message}</Typography>
                  <Button onClick={() => props.navigateToAddressScreen()}>
                    Add Address +
                  </Button>
                </Stack>
              );
            } else if (item.errType === "memberNotSelected") {
              return (
                <Stack
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{ margin: "20px 0 10px" }}
                >
                  <Typography className="warnItem">{item.message}</Typography>
                  <Button onClick={() => props.navigateToAddMembersScreen()}>
                    Add Members +
                  </Button>
                </Stack>
              );
            } else {
              return null;
            }
            // return <Box><Typography className="warnItem">
            //   {item.message}</Typography>

            //   <Button></Button>
            //   </Box>;
          })}
          <Button
            sx={{ marginTop: "30px" }}
            onClick={() =>
              props.setErrorPopUp({ isError: false, errorMessages: [] })
            }
          >
            Ok
          </Button>
        </Box>
      </Dialog>

      <Dialog
        className="applyCouponPopup"
        onClose={() => props.setCouponPopUp(false)}
        open={props.couponPopup}
      >
        <Box className="couponModal">
          <Typography className="couponModalTitle">Apply Coupon</Typography>

          <TextField
            label="Coupon Code"
            name="couponCode"
            value={props.couponCodeData}
            fullWidth
            required
            onChange={(e: any) => props.setCouponCodeData(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      props.checkValidityOfcoupon();
                    }}
                  >
                    Check
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          {/* <input>Enter coupon</input> */}

          {props?.couponApplyResponse?.isValid && (
            <Typography className="validCoupon">Coupon Is valid</Typography>
          )}
          {props.couponApplyResponse.isError ? (
            <Typography className="invalidCoupon">
              Coupon Is not valid
            </Typography>
          ) : null}
          {props.couponApplyResponse.isValid ? (
            <Button
              className="applyCouponBtn"
              variant="contained"
              onClick={() => {
                props.setCouponApplyResponse({
                  ...props.couponApplyResponse,
                  isApplied: true,
                });
                props.setCouponPopUp(false);
              }}
            >
              Apply Coupon
            </Button>
          ) : null}
          {/* <Box className="existingCouponArea">
          <Typography></Typography>
        </Box> */}
        </Box>
      </Dialog>

      {/* <Dialog
        className="errorMsg"
        onClose={() => props.setBookingFailedPopUp(false)}
        open={props.bookingFailedPopup}
      >
        <Typography>Sorry for inconvenience. Error happened while booking the Puja. Please try again after sometime !</Typography>
        <Button onClick={() => props.setBookingFailedPopUp(false)}>Ok</Button>
      </Dialog> */}
    </Box>
  );
}

export default CartView;

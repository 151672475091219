import * as React from "react";
import { Typography } from "@mui/material";

export default function ParagraphTypography(props: any) {

  const typoGraphyDefaultCss = {
    color: "#181818",
    fontSize: "14px",
    fontWeight: "400",
  };

  return (
    <Typography style={{
      fontSize: props.fontSize || typoGraphyDefaultCss.fontSize,
      color: props.color || typoGraphyDefaultCss.color,
      fontWeight: props.fontWeight || typoGraphyDefaultCss.fontWeight,
    }}>
        {props.title}
    </Typography>
  );
}

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ParentView from '../../Views/PrarentView/ParentView';
import ContactUsView from '../../Views/ContactUs/ContactUsView';

const ContactUsScreen: React.FC = () => {
  

    return (
        <ParentView>
            <ContactUsView />
        </ParentView>
    );
};

export default ContactUsScreen;

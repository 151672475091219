import { useEffect, useState } from "react";
import ChadhavaView from "../../Views/Chadhava/ChadhavaView";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ParentView from "../../Views/PrarentView/ParentView";
function ChadhavaScreen() {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const {cartInfo} = useSelector((state: any) => state.cart);
  const {allTempleList} = useSelector((state: any) => state.temple);
  const [templeList,setTempleList] = useState([])


  const selectChadhavaTemple = (templeDetails:any)=>{
    console.log("selected temple is ",templeDetails)
    navigate("/chadhavaDetails/fgg55")
  }
    return (
      <ParentView>
        <ChadhavaView templeList={allTempleList} selectTemple={selectChadhavaTemple} cartDetails={cartInfo}/>
      </ParentView>
    );
  }
  
  export default ChadhavaScreen;
  
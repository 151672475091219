import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

const ReadMoreReadLess = ({ text, maxLength = 100 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMoreLess = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded) {
      return text;
    }
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  return (
    <Box>
      <Typography className="textInfo">{renderText()}</Typography>
      {text.length > maxLength && (
        <Box sx={{ display: "flex", alignItems: "center", paddingBottom:"15px" }}>
          <Button className="showMoreLessBtn" onClick={toggleReadMoreLess}>
            {isExpanded ? 'Show less' : 'Show more'}
          </Button>
          <Button className="moreLessIconBtn" onClick={toggleReadMoreLess}>
            {isExpanded ? <RemoveCircleRoundedIcon /> : <AddCircleRoundedIcon />}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ReadMoreReadLess;
import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Stack } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import pujaStepsBanner from "../../assets/Puja-steps-img/puja-steps-banner.png";
import badgeIcon from "../../assets/Puja-steps-img/badge-icon.png";
import startUpIndia from "../../assets/Puja-steps-img/startup-india-logo.png";
import isoLogo from "../../assets/Puja-steps-img/iso-logo.png";
import socLogo from "../../assets/Puja-steps-img/soc-2-type-2-logo.png";
import pujaStepsImg1 from "../../assets/Puja-steps-img/steps-img1.png";
import pujaStepsImg2 from "../../assets/Puja-steps-img/steps-img2.png";
import pujaStepsImg3 from "../../assets/Puja-steps-img/steps-img3.png";
import pujaStepsImg4 from "../../assets/Puja-steps-img/steps-img4.png";
import pujaStepsImg5 from "../../assets/Puja-steps-img/steps-img5.png";
import pujaStepsImg6 from "../../assets/Puja-steps-img/steps-img6.png";
import './PujaStepsView.scss';

const steps = [
    'Puja/Chadhava Selection',
    'Add Devotee Details',
    'Review Details & Checkout'
];

const PujaStepsView = () => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box className="pageContainer pujaSteps">
            <Box className="templeBlock">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdLeftBlock"></Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="pujaStepsCenterBlock">
                            <Box className="pujaStepsBanner">
                                <img src={pujaStepsBanner} alt="" />
                            </Box>
                            <Box className="pujaStepsBody">
                                <Typography className="mainHeading">Temple Dekho</Typography>
                                <Typography>Temple Dekho leads in virtual puja services, making rituals reachable beyond geographical barriers.</Typography>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                    className="templeDekhoInfo"
                                >
                                    <img src={badgeIcon} alt="" />
                                    <Typography>Temple Dekho is India’s leading most reliable, and secure digital platform for booking online puja service.</Typography>
                                </Stack>
                                <Typography className="securityInfo">Temple Dekho stands out among other apps with its distinction of being <span>SOC 2 type 2 compliant</span> and <span>ISO 27001 certified</span>, ensuring the highest standards of security and compliance.</Typography>
                                <Box className="certificateImg">
                                    <Typography>Recognised and Certified by</Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                        className="imgList"
                                    >
                                        <img className="startUpIndiaLogo" src={startUpIndia} alt="" />
                                        <img src={isoLogo} alt="" />
                                        <img src={socLogo} alt="" />
                                    </Stack>
                                </Box>
                            </Box>

                            <Box className="whatWeOffer">
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img src={pujaStepsImg1} alt="" />
                                    <Typography>Seamless Online Puja Booking and Chadhava Offerings through <span>Temple Dekho</span> app.</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography>Skilled pandits will conduct the Pujas and offer Chadhava with your <span>Name & Gotram</span>.</Typography>
                                    <img src={pujaStepsImg2} alt="" />
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img className="pujaStepsImg3" src={pujaStepsImg3} alt="" />
                                    <Typography>Receive notifications directly to your <span>WhatsApp number</span>.</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography>Receive prasad at <span>your doorstep directly</span> from temples.</Typography>
                                    <img className="pujaStepsImg4" src={pujaStepsImg4} alt="" />
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <img className="pujaStepsImg5" src={pujaStepsImg5} alt="" />
                                    <Typography><span>Panchang calendar</span>. You can access panchang for various Tithis.</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                    className="offerList"
                                >
                                    <Typography><span>Live darshan</span> of various temples of India.</Typography>
                                    <img className="pujaStepsImg6" src={pujaStepsImg6} alt="" />
                                </Stack>
                            </Box>

                            <Box className="awesomeBtnArea">
                                <Typography>This is how <span>Temple Dekho</span> operates.</Typography>
                                <Button variant="contained">This is Awesome</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdRightBlock"></Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PujaStepsView;

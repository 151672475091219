import profileimg from "../../assets/profiletopimg.jpg";
import feedback from "../../assets/feedback.png";
import privacypolicy from "../../assets/privacy-policy.png";
import settings from "../../assets/settings.png";
import logout from "../../assets/logout.png";
import Drawer from "@mui/material/Drawer";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import RoomIcon from "@mui/icons-material/Room";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import PolicyIcon from "@mui/icons-material/Policy";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GradingIcon from "@mui/icons-material/Grading";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import InfoIcon from '@mui/icons-material/Info';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import "./RightDrawer.scss";
// import { useSelector } from "react-redux";
import AvatarGenerator from "../profilePic/GenerateAvatar";
import { useEffect, useState } from "react";
import { logoutAction } from "../../store/actions/authAction";
import { APPROLES } from "../../utils/Constants";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import GavelIcon from '@mui/icons-material/Gavel';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { emptyCartAction } from "../../store/actions/cartAction";
function RightDrawer(props: any) {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  var { userInfo } = useSelector((state: any) => state.user);
  const personalInfo = userInfo?.personalInfo;

  const ppUrl = personalInfo?.profilePictureUrl || "";
  const userFName = personalInfo?.firstName || "";
  const userLName = personalInfo?.lastName || "";
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.userType === APPROLES.SUPERADMIN.roleId ||
        userInfo.userType === APPROLES.ADMIN.roleId)
    ) {
      setIsSuperAdmin(true);
    }
  }, [userInfo]);

  const navigateToPage = (navId: string) => {
    console.log("is mobile", isMobile);
    props.setOpenRightSidePanel(false);
    navigate(navId);
  };
  return (
    <Drawer
      anchor={"right"}
      open={props.openRightSidePanel}
      onClose={() => props.setOpenRightSidePanel(false)}
    >
      <div className="rightPannelArea">
        {userInfo ? (
          <div
            className="pannelTop"
            onClick={() => {
              if (isMobile) {
                navigateToPage("/profile/");
              } else {
                navigateToPage("/profile/personalInfo");
              }
            }}
          >
            <div className="leftProfileInfo">
              {ppUrl ? (
                <img src={ppUrl} alt="" />
              ) : (
                <AvatarGenerator firstName={userFName} lastName={userLName} />
              )}
              <h6>
                {userFName} {userLName} <p>View and edit profile</p>{" "}
              </h6>
            </div>
            <div className="rightArrow">
              <button>
                <ChevronRightRoundedIcon />
              </button>
            </div>
          </div>
        ) : (
          <div
            className="pannelTop"
            onClick={() => {
              if (isMobile) {
                navigateToPage("/login");
              } else {
                navigateToPage("/login");
              }
            }}
          >
            <div className="leftProfileInfo">
              <i className="bi bi-person-circle"></i>

              <h6>Login or signup</h6>
            </div>
            <div className="rightArrow">
              <button>
                <ChevronRightRoundedIcon />
              </button>
            </div>
          </div>
        )}

        <div className="bottomMenuList1">
          <ul>
            {/* <li>
              <a href="#">
                <CategoryRoundedIcon />
                Categories
              </a>
            </li> */}
            {userInfo ? (
              <li onClick={() => navigateToPage("/myOrders")}>
                <a>
                  <DashboardCustomizeRoundedIcon />
                  My Orders
                </a>
              </li>
            ) : null}
            {userInfo ? (
              <li onClick={() => navigateToPage("/profile/address")}>
                <a>
                  <RoomIcon />
                  Address
                </a>
              </li>
            ) : null}

            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin");
                }}
              >
                <a href="javascript:void(0)">
                  <AdminPanelSettingsIcon />
                  Admin Panel
                </a>
              </li>
            )}

            {/* {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/useranalytics");
                }}
              >
                <a href="javascript:void(0)">
                  <AnalyticsIcon />
                  User Analytics
                </a>
              </li>
            )}
            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/sendpush");
                }}
              >
                <a href="javascript:void(0)">
                  <NotificationAddIcon />
                  Send Push Notification
                </a>
              </li>
            )}
            {isSuperAdmin && (
              <li
                onClick={() => {
                  navigateToPage("/admin/couponcode");
                }}
              >
                <a href="javascript:void(0)">
                  <ConfirmationNumberIcon />
                  Manage Coupon Code
                </a>
              </li>
            )} */}
          </ul>
        </div>
        <div className="bottomMenuList2">
          <ul>

            {/* <li>
              <a href="/about">
                <InfoIcon />
                About Us
              </a>
            </li>
            <li>
              <a href="/contactus">
                <ContactPhoneIcon />
                Contact Us
              </a>
            </li>
           
            <li>
              <a href="/refundscancellations">
                <PaymentsIcon />
                Refunds and Cancellations
              </a>
            </li>

            <li>
              <a href="/communityguidelines">
                <LocalLibraryIcon />
                Content & Community Guidlines
              </a>
            </li>

            <li>
              <a href="/privacypolicy">
                <PolicyIcon />
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="/termsandconditions">
                <GavelIcon />
                Terms and Conditions
              </a>
            </li> */}

            {/* <li>
              <a href="#">
                <SettingsIcon />
                Settings
              </a>
            </li> */}
          </ul>
        </div>

        {userInfo ? (
          <div
            className="logOutBtn"
            onClick={() => {
              dispatch(emptyCartAction());
              dispatch(logoutAction());
              navigate("/");
              props.setOpenRightSidePanel(false);
            }}
          >
            <ul>
              <li>
                <a>
                  <LogoutIcon />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
}

export default RightDrawer;

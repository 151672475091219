export const OrderAdminColumnData = [
  {
    Header: "Temple Name",
    accessor: "templeName",
  },
  {
    Header: "Order Id",
    accessor: "orderId",
  },
  {
    Header: "Order By",
    accessor: "orderUserName",
  },
  {
    Header: "Customer Phone",
    accessor: "phoneNumber",
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
  },
  {
    Header: "Puja Date",
    accessor: "pujaDate",
  },

  {
    Header: "Puja Name",
    accessor: "pujaName",
  },

  {
    Header: "Booking Date",
    accessor: "orderBookingDate",
  },
  {
    Header: "AWB Number",
    accessor: "awbNumber",
  },
  {
    Header: "Change Status",
    accessor: "changeStatus",
  },
  {
    Header: "View All Details",
    accessor: "viewAllDetails",
  },
];

export const SettelementColumnData = [
  {
    Header: "Temple Name",
    accessor: "templeName",
  },
  {
    Header: "Order Id",
    accessor: "orderId",
  },
  {
    Header: "Payment Status",
    accessor: "paymentStatus",
  },
  {
    Header: "Puja Date",
    accessor: "pujaDate",
  },

  {
    Header: "Payment Date",
    accessor: "paymentDate",
  },
  {
    Header: "Puja Value",
    accessor: "totalAmount",
  },
  {
    Header: "Temple Amount",
    accessor: "totalAmountForTemple",
  },
  {
    Header: "TDS",
    accessor: "tdsDetuction",
  },
  {
    Header: "Pay to Partner",
    accessor: "amountToPay",
  },
  {
    Header: "Puja Name",
    accessor: "pujaName",
  },
  {
    Header: "Order Status",
    accessor: "orderStatus",
  },

  {
    Header: "Transaction Number",
    accessor: "transactionNumber",
  },
  {
    Header: "Order By",
    accessor: "orderUserName",
  },
  {
    Header: "Customer Phone",
    accessor: "phoneNumber",
  },
  {
    Header: "Booking Date",
    accessor: "orderBookingDate",
  },
  {
    Header: "AWB Number",
    accessor: "awbNumber",
  },
  {
    Header: "Update Payment",
    accessor: "changeStatus",
  },
  {
    Header: "View All Details",
    accessor: "viewAllDetails",
  },
];

import {
  setAuthUser,
  setLoading,
  setConfirmation,
} from "./../slices/authSlice";
import { setUser } from "../slices/userSlice";
// import auth from '@react-native-firebase/auth';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { AuthService } from "td_server";
import { auth } from "../../configs/firebaseConfig";
import { setGlobalLoader } from "./loaderAction";
import { APPROLES } from "../../utils/Constants";
import { env } from "../../env";
import {
  sendOTPToUser,
  resSendOTPToUser,
  verifyOTPbyUser,
} from "../../webapi/AuthApi";
import { getPromotionalPujaList } from "./pujaAction";
import { getAllTempleList } from "./templeAction";
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import RazorpayCheckout from 'react-native-razorpay';
// import {getToken} from './../../utils/PushNotification';

export const loginWithPhoneNumber =
  (phoneNumber: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      console.log("Phone num", phoneNumber);
      // const otpResponse: any = await sendOTPToUser(`${phoneNumber}`, "91");
      const otpResponse = await AuthService.sendOtpLessOtp(
        `${phoneNumber}`,
        "91"
      );
      const confirmation = otpResponse;
      console.log("confirmation = ", confirmation);

      if (confirmation.orderId && confirmation.orderId.length > 0) {
        dispatch(setAuthUser(phoneNumber));
        dispatch(setConfirmation(confirmation));
      } else {
        dispatch(setLoading(false));
        return Promise.reject(confirmation);
      }

      dispatch(setLoading(false));
      return Promise.resolve("");
    } catch (error: any) {
      console.log("LOGIN ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const resendOtpAction =
  (_confirmation: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      let authUser = getState().auth.user;
      const resendResult: any = await AuthService.reSendOtpLessOtp(
        _confirmation.orderId
      );
      const confirmation = resendResult;
      console.log("confirmation = ", confirmation);
      // dispatch(setAuthUser(authUser));
      if (confirmation.orderId && confirmation.orderId.length > 0) {
        dispatch(setConfirmation(confirmation));
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }

      dispatch(setLoading(false));
      return Promise.resolve(true);
    } catch (error: any) {
      console.log("resend otp ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  };

export const resendOtpActionRegister =
  (phoneNumber: any, _confirmation: any) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      let authUser = getState().auth.user;

      const confirmation: any = await AuthService.reSendOtpLessOtp(
        _confirmation.orderId
      );
      // dispatch(setAuthUser(authUser));
      //console.log('confirmation = ', confirmation);
      if (confirmation.orderId && confirmation.orderId.length > 0) {
        dispatch(setConfirmation(confirmation));
      } else {
        dispatch(setLoading(false));
        return Promise.reject("");
      }
      dispatch(setLoading(false));
      return Promise.resolve(true);
    } catch (error: any) {
      //console.log('resend otp ERROR : ', error);
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  };

export const checkUserDetails = (phoneNumber: any) => async (dispatch: any) => {
  try {
    dispatch(setGlobalLoader(true));
    const checkUser = await AuthService.handleUserLogin(phoneNumber);
    let userDetails = null;
    if (checkUser?.newUser) {
      dispatch(setLoading(false));
      userDetails = {
        newUser: checkUser.newUser,
        phoneNumber: checkUser.phoneNumber.replace(/^\+91/, ""),
      };
    } else {
      userDetails = checkUser.user;
    }
    localStorage.setItem("userInfo", JSON.stringify(userDetails));
    dispatch(setUser(userDetails));
    dispatch(setLoading(false));
    dispatch(setGlobalLoader(false));
    return Promise.resolve("");
  } catch (error: any) {
    //console.log('otp error = ', error);
    dispatch(setLoading(false));
    return Promise.reject("InvalidOtp");
  }
};
export const verifyOtp =
  (otp: any, _confirmation: any, phoneNumber: any) =>
  async (dispatch: any, getState: any) => {
    console.log("Verify otp called",otp,_confirmation)
    try {
      dispatch(setLoading(true));
      let response:any = {};
      //Logic for hardcoded OTP for test ENV
      if(otp==="121212"&&env==="dev"){
        response.isOTPVerified = true
      }
      else{
        const responseVerifyOtp: any = await AuthService.verifyOtpLessOtp(
          phoneNumber,
          _confirmation.orderId,
          otp,
          "91"
        );
        response = responseVerifyOtp
        console.log("Verif yresponse",responseVerifyOtp)
      }

      
      dispatch(setLoading(false));


      var userDetails = null;
      if (response.isOTPVerified) {
        dispatch(setConfirmation(response));
        const checkUser = await AuthService.handleUserLogin(
          `+91${phoneNumber}`
        );
        //Added for new user test
        console.log("Verifiy user Data-->",checkUser)
        // checkUser.newUser = true;
        // checkUser.phoneNumber = phoneNumber
        if (checkUser?.newUser) {
          console.log("Inside new user flow")
          dispatch(setLoading(false));
          let newUserDetails = {
            newUser: checkUser.newUser,
            phoneNumber: checkUser.phoneNumber.replace(/^\+91/, ""),
          };
          userDetails = newUserDetails
          dispatch(setUser(userDetails));
        }
        else{
          userDetails = checkUser.user;
          dispatch(setUser(userDetails));
          // return Promise.resolve(userDetails);
        }
        await localStorage.setItem("userInfo", JSON.stringify(userDetails));
        // dispatch(setUser(userDetails));
        dispatch(setLoading(false));
        dispatch(setGlobalLoader(false));
        return Promise.resolve(userDetails);
      } else {
        dispatch(setLoading(false));
        return Promise.reject("InvalidOtp");
      }
    } catch (error: any) {
      //console.log('otp error = ', error);
      dispatch(setLoading(false));
      return Promise.reject("InvalidOtp");
    }
  };

// export const setUserInfoAction = (user: any) => async (dispatch: any) => {
//   try {
//     const checkUser = await AuthService.handleUserLogin(user?.phoneNumber);
//     //console.log('checkUser', checkUser);

//     let userDetails = checkUser.user;
//     const token = await getToken();
//     if (token) {
//       userDetails = {
//         ...userDetails,
//         pushToken: token,
//       };
//       try {
//         await AuthService.insertNotificationToken(userDetails.id, token);
//       } catch (e) {
//         console.log('Error storing push token');
//       }
//     }

//     await AsyncStorage.setItem('userInfo', JSON.stringify(userDetails));

//     dispatch(setUser(userDetails));
//   } catch (e) {}
// };

// export const addRegisterDataAction =
//   (formData: any, isPhoneVerified: boolean) => async (dispatch: any) => {
//     try {
//       dispatch(setLoading(true));
//       const phoneNumber = `+91${formData.phoneNumber}`;
//       console.log('isPhoneVerified = ', isPhoneVerified);
//       if (!isPhoneVerified) {
//         try {
//           const check = await AuthService.checkIsExistRegisterData(phoneNumber);
//           if (check) {
//             dispatch(setLoading(false));
//             return Promise.reject('PhoneExists');
//           }
//         } catch (e: any) {
//           console.log('REGISTER ERROR 2: ', e);
//           dispatch(setLoading(false));
//           return Promise.reject('');
//         }
//         const confirmation: any = await auth().signInWithPhoneNumber(
//           phoneNumber,
//         );
//         //console.log('confirmationReg = ', confirmation);
//         dispatch(setAuthUser(phoneNumber));
//         dispatch(setConfirmation(confirmation));
//       }
//       dispatch(setLoading(false));
//       return Promise.resolve('');
//     } catch (e: any) {
//       console.log('REGISTER ERROR 1: ', e.message);
//       dispatch(setLoading(false));
//       return Promise.reject(e);
//     }
//   };

// export const verifyRegisterOtp =
//   (otp: any, confirmation: any) => async (dispatch: any) => {
//     try {
//       dispatch(setLoading(true));
//       //console.log('OTP RESPONSE = hhhhh');
//       const response = await confirmation.confirm(otp);
//       //console.log('OTP RESPONSE = ', response);
//       dispatch(setConfirmation(response));
//       dispatch(setLoading(false));
//       return Promise.resolve('');
//     } catch (e) {
//       dispatch(setLoading(false));
//       return Promise.reject('');
//     }
//   };

export const submitRegistrationInfoAction =
  (formData: any, verifyFormData: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const contactData = {
        name: `${formData.firstName} ${formData.lastName}`,
        email: "avinash@omkarainfotech.com",
        contact: formData.phoneNumber,
        type: formData.userType,
        reference_id: "NA",
        notes: {
          random_key_1: formData.userType,
          random_key_2: formData.userType,
        },
      };
      // const paymentProfile = await CommonService.createRazorpayContact(
      //   contactData,
      // );

      const data = {
        ...formData,
        email: formData.email.toLowerCase().trim(),
        phoneNumber: `+91${formData.phoneNumber}`,
        ...verifyFormData,
        paymentProfile: {},
      };
      //console.log('REGISTER DATA = ', data);
      const user = await AuthService.handleUserRegistration(data);
      //TODO
      dispatch(setUser(user));
      localStorage.setItem("userInfo", JSON.stringify(user));
      dispatch(setLoading(false));
      return Promise.resolve(user);
    } catch (e: any) {
      console.log("REGISTRATION SUBMIT ERROR = ", JSON.stringify(e));
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const logoutAction = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    try {
      signOut(auth)
        .then((signoutData: any) => {
          console.log("signout successful");
          dispatch(setUser(null));
        })
        .catch((error) => {
          console.log("signout error");
        });
    } catch (err) {
      console.log("Error while logging out user", err);
    }

    localStorage.removeItem("userInfo");
    //clear cart as well || new user may login
    localStorage.removeItem("cartInfo");
    dispatch(setConfirmation(null));
    // dispatch({ type: "RESET_STORE" });
    dispatch(setLoading(false));
    return Promise.resolve("");
  } catch (e) {
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

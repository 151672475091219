import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import RoundedButton from "../button/RoundedButton";
import { Box, Typography } from "@mui/material";
export default function VirtualPujaBookDialog(props: any) {
  return (
    <Dialog
      className="startPostInput"
      onClose={() => props.setAskUserToModifyCartPopUp(false)}
      open={props.askUserToModifyCartPopUp}
    >
      <br></br>
      <Typography style={{ marginLeft: "50px", marginRight: "50px" }}>
        You have one puja added in cart do you want to replace it with new puja?
      </Typography>
      <br></br>
      <br></br>
      <Box style={{ display: "flex", marginLeft: "50px" }}>
        <RoundedButton
          onClick={() => props.userConfirmUpdatePujaDetails()}
          title="Yes"
        />

        <RoundedButton
          onClick={() => props.setAskUserToModifyCartPopUp(false)}
          title="No"
          backgroundColor="red"
        />
      </Box>

      <br></br>
      <br></br>
    </Dialog>
  );
}

import like from "../../../assets/like.png";
import message from "../../../assets/message.png";
import share from "../../../assets/share.png";
import redlike from "../../../assets/redlike.png";
import verifytick from "../../../assets/verify-tick.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  UserService,
  TempleService,
  PanchangService,
  PostService,
} from "td_server";
export default function ViewCommentsForPost(props: any) {
  const userDetails = useSelector((state: any) => state.AuthSlice.userDetails);
  const [commentsList, setCommentsList] = useState([]);
  useEffect(() => {
    PostService.getCommentsForAPost(props.postId)
      .then((response) => {
        console.log("comments for post", response);
        setCommentsList(response);
      })
      .catch((error: any) => {
        console.log("error fetching commants", error);
      });
  }, []);
  return (
    <div
      className="startPostBox"
      style={{ height: "20em", width: "20em", backgroundColor: "white" }}
    >
      Comments view for posts...
      {commentsList.map((item: any, id: any) => {
        return (
          <div key={id} style={{ display: "flex", margin: "15px" }}>
            <img
              src={item.userImageUrl}
              style={{ width: "35px", height: "35px" }}
            ></img>
            <div style={{ width: "100%", height: "35px", marginLeft: "5px" }}>
              <p>
                {item.userName}
                <p>{item.comment}</p>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

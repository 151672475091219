import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Store } from "../utils/Constants";
import { APPROLES } from "../utils/Constants";
import AdminLayoutScreen from "../Screen/Admin/AdminLayoutScreen";
function AdminHandlerRoutes() {
  var { userInfo } = useSelector((state: any) => state.user);
  var userInfoFromExtractLocal = localStorage.getItem("userInfo") || "{}";
  var userInfoLocalJsonParsed = JSON.parse(userInfoFromExtractLocal);
  // if (userInfo?.userType!==APPROLES.SUPERADMIN.roleId) {
  if (
    userInfoLocalJsonParsed &&
    userInfoLocalJsonParsed?.userType !== APPROLES.SUPERADMIN.roleId
  ) {
    return <Navigate to="/" />;
  } else {
    // return <Outlet />;
    return <AdminLayoutScreen />
  }
}

export default AdminHandlerRoutes;

import { Box } from "@mui/material";

function ResetPasswordView() {
    return (
      <Box>
          Reset password
      </Box>
    );
  }
  
  export default ResetPasswordView;
  
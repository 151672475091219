import {combineReducers} from '@reduxjs/toolkit';
import testSlice from './slices/testSlice';
import authSlice from './slices/authSlice';
import userSlice from './slices/userSlice';
import templeSlice from './slices/templeSlice';
import cartSlice from './slices/cartSlice';
import orderSlice from './slices/orderSlice';
import loaderSlice from './slices/loaderSlice';
import pujaSlice from './slices/pujaSlice';
import analyticsSlice from './slices/analyticsSlice';
import couponCodeSlice from './slices/couponCodeSlice';
import HoroscopeSlice from "./slices/HoroscopeSlice";

const appReducer = combineReducers({
  test: testSlice,
  auth: authSlice,
  user: userSlice,
  temple: templeSlice,
  cart: cartSlice,
  order: orderSlice,
  loader: loaderSlice,
  puja:pujaSlice,
  analytics:analyticsSlice,
  couponcode:couponCodeSlice,
  horoscope:HoroscopeSlice
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'RESET_STORE') {
    state = undefined; // Reset the entire state to its initial state.
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;

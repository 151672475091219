import Carousel from "react-multi-carousel";
import redlike from "../../../assets/redlike.png";
import share from "../../../assets/share.png";
import verifytick from "../../../assets/verify-tick.png";
import { TEMPLE_CARD_CAUROSAL_RESPONSIVE } from "../../../utils/Constants";
import { Box, Typography } from "@mui/material";
import templeLogo from "../../../assets/temple-logo-icon.png";
import { Link } from "react-router-dom";

import "./TempleDetailsCard.scss";

export default function TempleDetailsCard(props: any) {
  return (
    <Box
      key={props.id}
      className="templeCardBox">
      <Box className="templeTop">
        <Box className="templeProfile">
          <img src={props.templeImage} alt="" />
        </Box>
        <Box className="templeTitle">
          <Typography>
            {props.templeName}{" "}
            {props.verificationStatus === "VERRIFIED" ? (
              <img src={verifytick} alt="" />
            ) : null}
            {/* <img src={verifytick} alt="" /> */}
          </Typography>
          <Box className="ratings">
            <i className="bi bi-star-fill"></i>
            <Typography>
              <span>{props.googleData?.rating}</span> <span className="ratingCount">({props.googleData?.user_ratings_total}+ ratings)</span>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box className="templeImage">
        <Carousel
          responsive={TEMPLE_CARD_CAUROSAL_RESPONSIVE}
          swipeable={true}
          draggable={true}
          showDots={true}
          keyBoardControl={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {props.templePictures.map(
            (item2: any, id: any) => {
              return (
                <img onClick={() => props.selectTemple(props.templeData)} key={id} src={item2.pictureUrl}></img>
              )

            }
          )}
        </Carousel>
      </Box>
      <Box className="templeDescription" onClick={() => props.selectTemple(props.templeData)}>
        <Typography>{props.descriptions}</Typography>
      </Box>

      <Box className="imgLogo">
        <img src={templeLogo} alt="" />
      </Box>
    </Box>
  )
}
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
function AddEditMembers(props: any) {

  const [open, setOpen] = useState(false);
  const [errorPh, setErrorPh] = useState('');
  const [errorName, setErrorName] = useState('');

  const validatePhoneNumber = (value:string) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };




  const saveDetails = () => {

    if (validatePhoneNumber(props.whatsAppNumber)) {
      setErrorPh('');
    } else {
      setErrorPh('Invalid phone number. Please enter a 10-digit number.');
      return ;
    }

   
    if(props.noOfMembers === 0 &&  typeof props.memberDetailsGettingAddedOrEdited?.fullName === 'undefined' ){
      setErrorName("Please eneter full name")
      return ;
    }else{
      setErrorName('')
    }
    
   console.log(`rops.memberDetailsGettingAddedOrEdited?.fullName`,typeof props.memberDetailsGettingAddedOrEdited?.fullName)

    if(typeof props.memberDetailsGettingAddedOrEdited?.fullName !== 'undefined'){
      setOpen(true);
    }

   
    props.addOrEditMemberSubmit()
  }

  const handleClose = (event:any, reason:any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  return (
    <div className="inputTextFieldArea">
      <p className="title">Add Members</p>
      <div className="pujaName">
        <p className="sectionTitle">Puja Name</p>
        <TextField
          className="pujaNameTextField"
          id="standard-basic"
          variant="standard"
          value={props.pujaDetails.pujaName}
          disabled={true}
        />
      </div>
      <div className="pujaName">
        <p className="sectionTitle">Temple Name</p>
        <TextField
          className="pujaNameTextField"
          id="standard-basic"
          variant="standard"
          value={props.templeDetails?.personalInfo?.name}
          disabled={true}
        />
      </div>
      <div className="pujaName">
        <p className="sectionTitle">Puja Booking Date*</p>
        <TextField
          className="pujaNameTextField"
          id="standard-basic"
          variant="standard"
          value={props.pujaBookingDate}
          disabled={true}
        />
      </div>
      <div className="pujaName">
        <p className="sectionTitleBlack">Provide Your WhatsApp Number *</p>
        <TextField
          className="pujaNameTextField"
          id="standard-basic"
          variant="standard"
          placeholder="Enter WhatsApp Number"
          value={props.whatsAppNumber}
          error={!!errorPh}
          helperText={errorPh}
          onChange={(e: any) => props.setWhatsAppNumber(e.target.value)}

        />
      </div>
      <br></br>
      <div className="fullName">
        <p className="sectionTitleBlack">Full Name{ props?.noOfMembers === 0 ? "*":""  }</p>
        <TextField
          className="fullNameInput"
          id="standard-basic"
          variant="standard"
          placeholder="Enter Full Name"
          error={!!errorName}
          helperText={errorName}
          value={props.memberDetailsGettingAddedOrEdited.fullName ? props.memberDetailsGettingAddedOrEdited.fullName : ""}
          onChange={(e: any) => props.setMemberDetailsGettingAddedOrEdited({ ...props.memberDetailsGettingAddedOrEdited, fullName: e.target.value })}
        />
      </div>
      {/* <div className="gender">
      <p className="sectionTitle">Gender *</p>
      <FormControl variant="standard" style={{ width: "50%" }}>
        <InputLabel id="demo-simple-select-standard-label">
      {props.memberDetailsGettingAddedOrEdited.gender}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select"
          placeholder="Gender"
          onChange={(e:any)=>props.setMemberDetailsGettingAddedOrEdited({...props.memberDetailsGettingAddedOrEdited,gender:e.target.value})}
        >
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
          <MenuItem value="Others">Others</MenuItem>
        </Select>
      </FormControl>
    </div> */}

      <div className="gotram">
        <p className="sectionTitleBlack">Gotram (Optional)</p>
        <TextField
          className="gotramInput"
          id="standard-basic"
          placeholder="Enter Gotram"
          variant="standard"
          value={props.memberDetailsGettingAddedOrEdited.gotram ? props.memberDetailsGettingAddedOrEdited.gotram : ""}
          onChange={(e: any) => props.setMemberDetailsGettingAddedOrEdited({ ...props.memberDetailsGettingAddedOrEdited, gotram: e.target.value })}
        />
      </div>
      {/* <div className="birthDetails">
      <p className="sectionTitle">Birth Details</p>
      <div>
        <p className="dob">Date of Birth (DD/MM/YYYY)*</p>
        <TextField
          className="birthInput"
          id="standard-basic"
          placeholder="Enter here"
          variant="standard"
          value={props.memberDetailsGettingAddedOrEdited.dateOfBirth}
          onChange={(e:any)=>props.setMemberDetailsGettingAddedOrEdited({...props.memberDetailsGettingAddedOrEdited,dateOfBirth:e.target.value})}
        />
      </div>
    </div>

    <div className="tob">
      <p className="sectionTitle">Time of Birth (HH:MM) (Optional)</p>
      <TextField
        className="tobInput"
        id="standard-basic"
        placeholder="Enter here"
        variant="standard"
      />
    </div>

    <div className="birthLocation">
      <p className="sectionTitle">Birth Location (Optional)</p>
      <TextField
        className="birthLocationInput"
        id="standard-basic"
        placeholder="Enter here"
        variant="standard"
      />
    </div>

    <div className="rashi">
      <p className="sectionTitle">Rashi / Rash Name *</p>
      <TextField
        className="rashiInput"
        id="standard-basic"
        placeholder="Enter here"
        variant="standard"
        value={props.memberDetailsGettingAddedOrEdited.rashi}
        onChange={(e:any)=>props.setMemberDetailsGettingAddedOrEdited({...props.memberDetailsGettingAddedOrEdited,rashi:e.target.value})}
      />
    </div>

    <div className="ad">
      <p className="sectionTitle">Additional Details (Optional)</p>
      <TextField
        className="adInput"
        id="standard-basic"
        placeholder="Enter here"
        variant="standard"
      />
    </div> */}
      <div className="ad">
        <p className="sectionTitleBlack">Manokamna/ Wish (Optional)</p>
        <TextField
          className="adInput"
          id="standard-basic"
          placeholder="Enter Manokamna"
          variant="standard"
          value={props.memberDetailsGettingAddedOrEdited.additionalDetails ? props.memberDetailsGettingAddedOrEdited.additionalDetails : ""}
          onChange={(e: any) => props.setMemberDetailsGettingAddedOrEdited({ ...props.memberDetailsGettingAddedOrEdited, additionalDetails: e.target.value })}

        />
      </div>
      <div className="saveBtn">
        <Button className="addMember" type="button" onClick={() => props.setIsAddEditMemberScreen(false)}>
          Cancel
        </Button>
        <Button className="save" type="button" onClick={() => saveDetails()}>
          Save
        </Button>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert  icon={<CheckIcon fontSize="inherit" />} severity="success" sx={{ width: '100%' }}>
          {/* Form submitted successfully! */ props.maxMemberAllowed > 1 ? "one user added successfully and another user" :   "the user added successfully" }
        </Alert>
      </Snackbar>
      
    </div>
  );
}

export default AddEditMembers;

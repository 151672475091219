import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HoroscopeManageView from "../../Views/Admin/Horoscope/HoroscopeManageView";
import ParentView from "../../Views/PrarentView/ParentView";
import { getHoroscopeByDate,updateHoroScopeAction } from "../../store/actions/HoroScopeAction";
import { setHoroscope } from "../../store/slices/HoroscopeSlice";


function HoroscopeManageScreen() {

  const dispatch: any = useDispatch();
  const [horoscopeData,SetHoroscopeData] =  useState([]);
  const { isLoading, horoscope, error } = useSelector(
    (state: any) => state.horoscope
  );

  const handleDateChange = (date: string) => {

    dispatch(getHoroscopeByDate(date))
    SetHoroscopeData([])

  }

  useEffect(() => { 
       SetHoroscopeData(horoscope)
  }, [horoscope])

  const handleSubmit = (data:any)=>{
    try 
    {
      console.log("Update insert data",data)
      dispatch(updateHoroScopeAction(data))
    } catch (error) {
      console.error(error)
    }
    
  }
  return (
    <HoroscopeManageView horoscopeData={horoscopeData} onDateChange={handleDateChange} handleSubmit={handleSubmit} />
  );
}

export default HoroscopeManageScreen;

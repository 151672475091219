import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { Box, Button, Grid, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import "./AdminLayoutView.scss";

const AdminLayoutView = () => {
  const navigate = useNavigate();
  return (
    <Box className="adminSideNavView" sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={2} md={2} lg={2} xl={2} className="pagehdLeftBlock">
          <List
            className="sideNavList"
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader className="listHeaderTitle" component="div" id="nested-list-subheader">
            //     Nav lists
            //   </ListSubheader>
            // }
          >
            <ListItemButton
              className="listItemBtn"
              onClick={() => {
                navigate("/admin");
              }}
            >
              <ListItemIcon className="listIconSec">
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Admin Panel" />
            </ListItemButton>
            <ListItemButton
              className="listItemBtn"
              onClick={() => {
                navigate("/admin/useranalytics");
              }}
            >
              <ListItemIcon className="listIconSec">
                <AnalyticsIcon />
              </ListItemIcon>
              <ListItemText primary="User Analytics" />
            </ListItemButton>
            <ListItemButton
              className="listItemBtn"
              onClick={() => {
                navigate("/admin/sendpush");
              }}
            >
              <ListItemIcon className="listIconSec">
                <NotificationAddIcon />
              </ListItemIcon>
              <ListItemText primary="Send Push Notification" />
            </ListItemButton>
            <ListItemButton
              className="listItemBtn"
              onClick={() => {
                navigate("/admin/couponcode");
              }}
            >
              <ListItemIcon className="listIconSec">
                <ConfirmationNumberIcon />
              </ListItemIcon>
              <ListItemText primary="Manage Coupon code" />
            </ListItemButton>
            <ListItemButton
              className="listItemBtn"
              onClick={() => {
                navigate("/admin/horoscopes");
              }}
            >
              <ListItemIcon className="listIconSec">
                <Diversity2Icon />
              </ListItemIcon>
              <ListItemText primary="Manage Horoscopes" />
            </ListItemButton>

          </List>
        </Grid>

        <Grid item xs={10} md={10} lg={10} xl={10} className="pagehdLeftBlock">
          <Outlet />
        </Grid>
      </Grid>


      {/* <Grid container spacing={1}>
        <Grid item xs={2} md={2} lg={1} xl={1} className="pagehdLeftBlock">
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          Nav lists
          <Button
            onClick={() => {
              navigate("/admin");
            }}
          >
            Admin panel
          </Button>
          <Button
            onClick={() => {
              navigate("/admin/useranalytics");
            }}
          >
            User Analytics
          </Button>
          <Button
            onClick={() => {
              navigate("/admin/sendpush");
            }}
          >
            Send Push
          </Button>
          <Button
            onClick={() => {
              navigate("/admin/couponcode");
            }}
          >
            Manage Coupon code
          </Button>
          <Button
            onClick={() => {
              navigate("/admin/horoscopes");
            }}
          >
            Manage Horoscopes
          </Button>
        </Grid>
        <Grid item xs={10} md={10} lg={11} xl={11} className="pagehdLeftBlock">
          <Outlet />
        </Grid>
      </Grid> */}
    </Box>
  );
};

export default AdminLayoutView;

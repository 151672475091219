// HomePage.tsx
import React, { useState } from 'react';
import { Button } from '@mui/material';
import ShareLinkDialog from '../dialog/ShareLinkDialog';
import ReplyIcon from '@mui/icons-material/Reply';
interface ShareLinkProps {
    urlToShare: string;
}

const ShareLink: React.FC<ShareLinkProps> = ({ urlToShare }) => {
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const handleOpenShareDialog = () => {
        setShareDialogOpen(true);
    };

    const handleCloseShareDialog = () => {
        setShareDialogOpen(false);
    };

    return (
        <div>
            <ReplyIcon onClick={handleOpenShareDialog} />
            <ShareLinkDialog open={shareDialogOpen} onClose={handleCloseShareDialog} url={urlToShare} />
        </div>
    );
};

export default ShareLink;

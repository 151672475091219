import { createSlice } from "@reduxjs/toolkit";
import { promotionalPujaLoading } from "../../utils/Constants";

const templeSlice = createSlice({
  name: "puja",
  initialState: {
    isLoading: false,
    error: null,
    selectedTempleDetailsForPuja: null,
    selectedPuja: null,
    pujaMembers: [],
    selectedPujaDateAndTime: {},
    promotionalList: promotionalPujaLoading,
    slectedPromotionalPujaOrganisations: [],
    pujaCategoryList:[],
    pujaMergedSubCategoryList:[]
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSelectedTempleDetailsForPuja: (state, action) => {
      state.selectedTempleDetailsForPuja = action.payload;
    },
    setSelectedPuja: (state, action) => {
      state.selectedPuja = action.payload;
    },
    setPujaMembers: (state, action) => {
      state.pujaMembers = action.payload;
    },
    setSelectedPujaDateAndTime: (state, action) => {
      state.selectedPujaDateAndTime = action.payload;
    },
    setPromotionalList: (state, action) => {
      state.promotionalList = action.payload;
    },
    setSelectedPromotionalPujaOrganisations: (state, action) => {
      state.slectedPromotionalPujaOrganisations = action.payload;
    },
    setPujaCategoryList: (state, action) => {
      state.pujaCategoryList = action.payload;
    },
    setPujaMergedSubCategoryList: (state, action) => {
      state.pujaMergedSubCategoryList = action.payload;
    },
  },
});

export const {
  setLoading,
  setError,
  setSelectedTempleDetailsForPuja,
  setSelectedPuja,
  setPujaMembers,
  setSelectedPujaDateAndTime,
  setPromotionalList,
  setSelectedPromotionalPujaOrganisations,
  setPujaCategoryList,
  setPujaMergedSubCategoryList
} = templeSlice.actions;

export default templeSlice.reducer;

import { Outlet, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import "./CheckoutView.scss";
import { Box, Grid } from "@mui/material";
import CartNoItem from "../../components/card/Cart/NoItemInCart";

function CheckoutView(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => {
    // console.log("path", location.pathname);
    return location.pathname === path ? "active" : "inactive";
  };
  // console.log("Location---checkout view", location.pathname);
  return (
    <Box
      className="pageContainer PujaDetailsView"
      style={{ minHeight: "50em" }}
    >
      <Box className="PujaDetailsViewBlock">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="d-none d-md-block"
          ></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="addPujaTab">
              <Box className="tabList">
                <Box
                  className="pujaDetailsTab"
                  onClick={() => navigate("puja-details")}
                >
                  <Button
                    type="button"
                    className={`pujaDetailsText ${isActive(
                      "/checkout/puja-details"
                    )}`}
                  >
                    Puja Details
                  </Button>
                </Box>
                <Box className="cartTab" onClick={() => navigate("cart")}>
                  <Button
                    type="button"
                    className={`cartText  ${isActive("/checkout/cart")}`}
                  >
                    Cart
                  </Button>
                </Box>
              </Box>

              <Box className="tabsContents">
                <Outlet />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            className="d-none d-md-block"
          ></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default CheckoutView;

import React from 'react';

import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import "./RefundsCancellationsView.scss";


const RefundsCancellationsView = () => {
    return (
        <Box className="pageContainer refundCancellation">
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        Refunds <span > And Cancellations</span>
                    </Typography>
                </div>
                <div className="refundCancellationInfo mb-40">
                    <Box mb={5}>
                       
                        <Typography paragraph>
                            Payment for services rendered by Temple Dekho must be made in full at the time of booking.
                        </Typography>
                        <Typography paragraph>
                            We accept payments via the following methods:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="1. Gpay UPI" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2. PhonePe UPI" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3. Debit Card" />
                            </ListItem>
                        </List>
                        <Typography paragraph>
                            Our team is available to assist with any payment-related inquiries you may have.
                        </Typography>
                    </Box>

                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                            Cancellation
                        </Typography>
                        <Typography paragraph>
                            You may request to cancel your order up to 48 hours before the scheduled date and time of the event. To cancel, please call us at +91 90733 00055. Please note that no cancellations can be processed within 48 hours of the event.
                        </Typography>
                    </Box>

                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                            Refunds
                        </Typography>
                        <Typography paragraph>
                            For orders cancelled up to 48 hours before the scheduled date and time of the event, refunds will be processed to the original source of payment within 7-10 business days.
                        </Typography>
                    </Box>

                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                            Non-Refundable Instances
                        </Typography>
                        <Typography paragraph>
                            No refund shall be processed in the following instances:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemText primary="1. The order has reached the processing stage." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2. The Platform experiences a technical delay or glitch in processing the request." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3. Incorrect information provided by you. You are responsible for verifying the accuracy of any information submitted. Requests to rectify incorrect information must be made within two hours of the puja booking by calling us at +91 90733 00055." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="4. We cannot guarantee that the desired blessings or outcomes will be fulfilled through our pujas or services. Results may vary, and we do not take responsibility for ensuring specific outcomes." />
                            </ListItem>
                        </List>
                    </Box>

                    <Box mb={5}>
                        <Typography variant="h5" gutterBottom className='fw-bold'>
                            Processing Refunds
                        </Typography>
                        <Typography paragraph>
                            Refunds, if applicable, will be processed after deducting transaction charges levied by the bank, payment gateway, or any other charges incurred by the Platform during processing or delivering the service.
                        </Typography>
                    </Box>
                </div>
            </Container>
        </Box>
    );
};

export default RefundsCancellationsView;

import postprofile from "../../assets/temple-post-profile.jpg";
import media from "../../assets/media.png";
import date from "../../assets/date.png";
import writepost from "../../assets/writepost.png";
import "./Donation.scss";
import { Box, Grid, Link, Typography } from "@mui/material";



function DonationView() {
  return (
    <Box className="pageContainer donationPage">
      <Box className="donationBlock">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <Box className="donationLeftBlock">
              <Typography>Left Content</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className="centerBlockTop">
              <Box className="topField">
                <Box className="profileImg">
                  <Link href="#"><img src={postprofile} alt="" /></Link>
                </Box>
                <Box className="inputBox">
                  <input className="form-control startPostInput" type="search" placeholder="Start a post" aria-label="Search" />
                </Box>
              </Box>
              <Box className="bottomField">
                <Box className="mediaButton">
                  <Link href="#"><img src={media} alt="" />Media</Link>
                </Box>
                <Box className="dateButton">
                  <Link href="#"><img src={date} alt="" />Date</Link>
                </Box>
                <Box className="writePostButton">
                  <Link href="#"><img src={writepost} alt="" />Write Post</Link>
                </Box>
              </Box>
            </Box>
            <Box className="donationCenterBlock">
              <Typography>Center Content</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Box className="donationRightBlock">
              <Typography>Right Content</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default DonationView;
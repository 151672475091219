import kaliPuja from "../../assets/Kali.jpg";
import kaliPujaimg from "../../assets/kali-puja.jpg";
import subramanyatemple from "../../assets/Subramanya-temple.jpg";
import durgaPujaimg from "../../assets/durga-puja.jpg";
import pujaMainImg from "../../assets/puja-img.png";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import pricetagIcon from "../../assets/price-tag.png";
import welbeingImg from "../../assets/welbeing.png";
import prosperityImg from "../../assets/prosperity.png";
import protectionImg from "../../assets/protection.png";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";

import "./PujaView.scss";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import Link from "@mui/material/Link";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import Avatar from "@mui/material/Avatar";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";

import pujaImg from "../../assets/puja-img.png";
import promoPujaBanner from "../../assets/promo-puja-banner.png";
import vertorArtLeft from "../../assets/vector-art-left.png";
import vertorArtRight from "../../assets/vector-art-right.png";
import PujaListCard from "../../components/card/Puja/PujaListCard";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import { getOneImageForTemple } from "../../utils/HelperFunctions";

function PromotionalPujaView(props: any) {
  const [urlToShare,setUrlToShare]  = useState<string>('')
  useEffect(() => {
  
    const hostname = window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'https://'+ window.location.hostname;
    setUrlToShare(`${hostname}/pagelink?visheshpuja=${props?.promotionalPujaDetails?.id}`)
 
  }, [props.promotionalPujaDetails]);

  return (
    <Box className="pageContainer promotionalPujaPage">
      <Box className="templeBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="promoPujaCenterBlock">
              <Box>
                <img
                  className="promoPujaBanner"
                  src={props.promotionalPujaDetails?.pujaListBg?.uri}
                  alt={"Image"}
                />
              </Box>
              <Box className="shareIcon">
                <ShareLink urlToShare={urlToShare}></ShareLink>
              </Box>

              {props.slectedPromotionalPujaOrganisations &&
                props.slectedPromotionalPujaOrganisations.length > 0
                ? props.slectedPromotionalPujaOrganisations.map(
                  (item: any, id: any) => {
                    return (
                      <Box>
                        <Box className="pujaBookingTitle">
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box>
                              <img src={vertorArtLeft} alt="" />
                            </Box>
                            <Typography>
                              {item?.personalInfo?.name}
                            </Typography>
                            <Box>
                              <img src={vertorArtRight} alt="" />
                            </Box>
                          </Stack>
                        </Box>

                        <Box className="templeCenterBlock">
                          {item.templePuja && item.templePuja.length > 0
                            ? item.templePuja.map(
                              (itemPuja: any, idPuja: any) => {
                                let templeProfilePic = getOneImageForTemple(item.personalInfo?.media?.pictures)
                                return (
                                  <PujaListCard
                                    pujaData={itemPuja}
                                    pujaName={itemPuja?.pujaName}
                                    templeProfilePic={itemPuja.pujaBg?.uri || templeProfilePic}
                                    description={itemPuja.description}
                                    benefits={itemPuja.benefits}
                                    acctualPrice={itemPuja.acctualPrice}
                                    onClickAddPuja={() => {
                                      props.onClickParticipate(
                                        item.id,
                                        itemPuja.pujaId
                                      );
                                    }}
                                    temple={item.personalInfo}
                                    devoteeCount={itemPuja.maxMemberAllowed}
                                    benefitList={itemPuja?.benefitList}
                                    isPujaDisable={itemPuja?.isPujaDisable}
                                  />
                                );
                              }
                            )
                            : null}
                        </Box>
                      </Box>
                    );
                  }
                )
                : null}
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PromotionalPujaView;

import PujaSubCategoriesView from "../../Views/Puja/PujaSubcategoriesView";
import { useDispatch, useSelector } from "react-redux";

import { Outlet, useNavigate, Navigate,useLocation } from "react-router-dom";

import { useEffect, useState } from "react";

import { getOneImageForTemple } from "../../utils/HelperFunctions";
import { getTempleDetailsById } from "../../store/actions/templeAction";
import { setSelectedPuja,setSelectedTempleDetailsForPuja } from "../../store/slices/pujaSlice";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import { getPromotionalPujaListById } from "../../store/actions/pujaAction";
function PujaSubCategoriesScreen() {
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [filteredPujaList,setFilterEdPujaList]:any = useState([])
  const [subCategoryDetails,setSubCategoryDetails]:any = useState([])
  const {allPujaList} = useSelector((state: any) => state.temple);
  const {pujaMergedSubCategoryList} = useSelector((state: any) => state.puja);
  const subcategoryPujaId = location.pathname.split("/")[2];
  console.log("Sub cat id",subcategoryPujaId)
  useEffect(()=>{
    // dispatch(getPromotionalPujaListById(promotionalPujaId))
    if(allPujaList&&allPujaList.length>0){
        console.log("All pujas",allPujaList)
        getPujaForSubCategory(allPujaList,subcategoryPujaId)
    }

    if(pujaMergedSubCategoryList&&pujaMergedSubCategoryList.length>0){
      let resFilter = pujaMergedSubCategoryList.filter((item:any)=>item.subCategoryId === subcategoryPujaId)
      if(resFilter&&resFilter.length>0){
        setSubCategoryDetails(resFilter[0])
      }
    }

  },[allPujaList,pujaMergedSubCategoryList])

  function getPujaForSubCategory(allPujaList:any,subcategoryIncoming:any){
    let finalData :any= []
    for(let i=0;i<allPujaList.length;i++){
        let pujaDetails = allPujaList[i].pujaDetails
        if(pujaDetails&&pujaDetails.pujaSubCategory){
            let subCategoryForPuja = pujaDetails.pujaSubCategory
            let filterRes = subCategoryForPuja.filter((item:any)=>item.subCategoryId === subcategoryIncoming)
            if(filterRes.length>0){
                finalData = [...finalData,allPujaList[i]]
            }
        }
    }

    setFilterEdPujaList(finalData)
  }

  const onClickParticipate = (pujaDetails:any)=>{
    console.log("Click pujaDetails",pujaDetails)
    let pujaId = pujaDetails.pujaDetails.pujaId
    let templeId = pujaDetails.id
    navigate(`/temples/${templeId}/pujaInfo/${pujaId}`)
  }

  console.log("Filtered List",filteredPujaList)
  console.log("Sub category detaisl",subCategoryDetails)
  return (
    <ParentView>
        <PujaSubCategoriesView  allPujaList={filteredPujaList} subCategoryDetails={subCategoryDetails} onClickAddPuja={onClickParticipate}/>
    </ParentView>
  );
}

export default PujaSubCategoriesScreen;

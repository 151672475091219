import React, { useEffect, useState } from "react";
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Box,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import dayjs from "dayjs";
import { getDateNowInUtc, getFormattedDate } from "../../../utils/HelperFunctions";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


import moment from "moment";
import MultipleSelectAutocomplete from "../../../components/AutoComplete/MultipleSelectAutocomplete";

type CouponFormProps = {
  onSubmit: (data: any) => Promise<void>;
  couponcode: any;
  handleBack: () => void;
  mode: string;
  allTempleList: any[]
  handleTempleSelect: ([]: any) => any
  handlePujaSelect: ([]: any) => any
  allPujaList: any[];
  allPackagesList: any[];
  handlePackageSelect: ([]: any) => any
  seletedTempleOptions: any[];
  seletedPujaOptions: any[];
  seletedPackageOptions: any[];

};

const CouponCodeForm: React.FC<CouponFormProps> = ({ onSubmit,seletedTempleOptions, seletedPujaOptions, seletedPackageOptions, couponcode, handleBack, mode, allTempleList, handleTempleSelect, allPujaList, handlePujaSelect, allPackagesList, handlePackageSelect }) => {
  const initialData = {
    couponCode: "",
    couponType: "",
    pujaType: "",
    description: "",
    isInterCondition: false,
    numberOfUse: 0,
    percentDiscount: 0,
    isDiscountInPercent: false,
    isNotPreviousOrderValidation: false,
    afterNumberOfUse: 0,
    afterPercentDiscount: 0,
    afterIsDiscountInPercent: false,
    validThrough: dayjs(new Date().toString()),
  }
  const [formValues, setFormValues]: any = useState(initialData);
  const [isEditMode, setIsEditMode] = useState(true);

  console.log('allTempleList', allTempleList)
  console.log("allPujaList", allPujaList)

  useEffect(() => {
    if (mode === 'edit') {
      // setIsEditMode(false)
      setFormValues({
        couponCode: couponcode?.couponCode,
        couponType: couponcode?.couponType ? couponcode?.couponType : '',
        pujaType: couponcode?.pujaType,
        description: couponcode?.description,
        isInterCondition: couponcode?.intermediateCondition?.isInterCondition,
        numberOfUse: couponcode?.intermediateCondition?.before?.numberOfUse,
        percentDiscount:
          couponcode?.intermediateCondition?.before?.percentDiscount,
        isDiscountInPercent:
          couponcode?.intermediateCondition?.before?.isDiscountInPercent,
        isNotPreviousOrderValidation: couponcode?.isNotPreviousOrderValidation,
        afterNumberOfUse: couponcode?.intermediateCondition?.after?.numberOfUse,
        afterPercentDiscount:
          couponcode?.intermediateCondition?.after?.percentDiscount,
        afterIsDiscountInPercent:
          couponcode?.intermediateCondition?.after?.isDiscountInPercent,
        validThrough: dayjs(new Date(couponcode?.validTill).toString()),
      });
    }



  }, [couponcode]);



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    // if (["numberOfUse","percentDiscount","afterNumberOfUse","afterPercentDiscount"].includes(name) && Number(value) < 0) {
    //   return;
    // }

    const tempValue: any = { [name]: type === "checkbox" ? checked : value }
    if (name === "couponCode" && formValues.couponCode !== 'ALL') {
      tempValue["pujaType"] = value;
    }
    setFormValues({ ...formValues, ...tempValue });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {

    const tempValue: any = { [e.target.name]: e.target.value }
    if (e.target.value === 'ALL') {
      tempValue["pujaType"] = e.target.value;
    }
    else if (e.target.value === 'PUJA') {
      tempValue["pujaType"] = formValues.couponCode
    }
    else {
      tempValue["pujaType"] = ""
    }
    setFormValues({ ...formValues, ...tempValue });



  };



  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const fdata: any = {
      pujaType: formValues.pujaType,
      description: formValues.description,
      validTill: moment(dayjs(formValues.validThrough).toString()).format(
        "YYYY-MM-DDTHH:mm:ss[Z]"
      ),
      restrictionNumberOfUse: Number(formValues?.numberOfUse ? formValues?.numberOfUse : 0) + Number(formValues.afterNumberOfUse ? formValues.afterNumberOfUse : 0),
      isActive: true,
      numberOfUse: couponcode && couponcode.id && couponcode.numberOfUse ? couponcode.numberOfUse : 0,
      couponCode: formValues.couponCode,
      couponType: formValues.couponType,
      isNotPreviousOrderValidation: formValues.isNotPreviousOrderValidation,
      intermediateCondition: {
        isInterCondition: formValues.isInterCondition,
        before: {
          numberOfUse: formValues.numberOfUse,
          percentDiscount: formValues.percentDiscount,
          isDiscountInPercent: formValues.isDiscountInPercent,
        },
        after: {
          percentDiscount: formValues.afterPercentDiscount,
          numberOfUse: formValues.afterNumberOfUse,
          isDiscountInPercent: formValues.afterIsDiscountInPercent,
        },
      },

    };

    if (couponcode?.id) {
      fdata["id"] = couponcode.id;
    }
    fdata["couponLog"] = couponcode?.couponLog ? couponcode?.couponLog : [];
    event.preventDefault();
    onSubmit(fdata);
    setIsEditMode(false);
  };

  const handleEdit = () => {
    setIsEditMode(!isEditMode);
  };

  const handleDateChange = (date: Date | any) => {
    setFormValues({
      ...formValues,
      validThrough: date,
    });
  };

  const handleGenerateCode = () => {
    const generatedCode = Math.random().toString(36).substring(7).toUpperCase();
    setFormValues({
      ...formValues,
      couponCode: generatedCode,
    });
  };



  return (
    <Box>

      <Box className="couponHeader">
        <Typography variant="h4">
          <IconButton onClick={handleBack} color="primary">
            <KeyboardBackspaceIcon />
          </IconButton>
          Coupon Code
        </Typography>

        {/* <IconButton onClick={handleEdit} color="primary">
          <EditIcon />
        </IconButton> */}
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Coupon Code"
            name="couponCode"
            value={formValues.couponCode}
            onChange={handleChange}
            fullWidth
            required
            disabled={!isEditMode}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleGenerateCode} variant="outlined">
                    Generate Code
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box mb={2}>
          <InputLabel id="demo-simple-select-label">Coupon Type*</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="couponType"
            value={formValues.couponType}
            label="Coupon Type"
            onChange={handleSelectChange}
            fullWidth
            required
            disabled={!isEditMode}
          >
            <MenuItem value="PUJA">PUJA</MenuItem>
            <MenuItem value="CHADHAVA">CHADHAVA</MenuItem>
            <MenuItem value="USER">USER</MenuItem>
            <MenuItem value="ASTRO">ASTRO</MenuItem>
            <MenuItem value="MARKETING">MARKETING</MenuItem>
            <MenuItem value="ALL">ALL</MenuItem>
          </Select>
        </Box>
        <Box mb={2}>
          <TextField
            label="Puja Type"
            name="pujaType"
            value={formValues.pujaType}
            onChange={handleChange}
            fullWidth
            required
            // disabled={true}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Box>

        <Box mb={2}>
          <MultipleSelectAutocomplete options={allTempleList} label="Temple List" placeholder="Choose Temple" onChange={handleTempleSelect} alreadySelectedOptions={seletedTempleOptions}></MultipleSelectAutocomplete>
        </Box>

        {allPujaList && allPujaList.length > 0 && (
          <Box mb={2}>
            <MultipleSelectAutocomplete options={allPujaList} label="Puja List" placeholder="Choose Puja" onChange={handlePujaSelect} alreadySelectedOptions={seletedPujaOptions}></MultipleSelectAutocomplete>
          </Box>
        )}

        {allPackagesList && allPackagesList.length > 0 && (
          <Box mb={2}>
            <MultipleSelectAutocomplete options={allPackagesList} label="All Package List" placeholder="Choose puja package" onChange={handlePackageSelect} alreadySelectedOptions={seletedPackageOptions}></MultipleSelectAutocomplete>
          </Box>
        )}


        <Box mb={2}>
          <TextField
            label="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            disabled={!isEditMode}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isInterCondition"
                checked={formValues.isInterCondition}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            }
            label="Is Inter Condition"
          />
        </Box>

        <Box mb={2}>
          <Typography variant="body1">Before</Typography>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Number of Use"
                name="numberOfUse"
                value={formValues.numberOfUse}
                onChange={handleChange}
                fullWidth
                disabled={!isEditMode}
                type="number"
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Discount"
                name="percentDiscount"
                value={formValues.percentDiscount}
                onChange={handleChange}
                fullWidth
                disabled={!isEditMode}
                type="number"
                InputProps={{
                  inputProps: { min: 0 }
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isDiscountInPercent"
                checked={formValues.isDiscountInPercent}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            }
            label="Is Discount in %"
          />
        </Box>

        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isNotPreviousOrderValidation"
                checked={formValues.isNotPreviousOrderValidation}
                onChange={handleChange}
                disabled={!isEditMode}
              />
            }
            label="Allow Previously Free Puja Booked Users"
          />
        </Box>

        {formValues.isInterCondition && (
          <>
            <Box mb={2}>
              <Typography variant="body1">After</Typography>
            </Box>
            <Box mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Number of Uses"
                    name="afterNumberOfUse"
                    value={formValues.afterNumberOfUse}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={!isEditMode}
                    type="number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Discount"
                    name="afterPercentDiscount"
                    value={formValues.afterPercentDiscount}
                    onChange={handleChange}
                    fullWidth
                    required
                    disabled={!isEditMode}
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="afterIsDiscountInPercent"
                    checked={formValues.afterIsDiscountInPercent}
                    onChange={handleChange}
                    disabled={!isEditMode}
                  />
                }
                label="Is Discount in %"
              />
            </Box>
          </>
        )}

        <Box mb={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Valid Through"
              value={formValues.validThrough}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              disabled={!isEditMode}
              disablePast={true}
              slotProps={{
                textField: {
                  variant: "standard",
                  error: false,
                },
              }}
            />
          </LocalizationProvider>
        </Box>

        {isEditMode && (

          <Grid container spacing={2} sx={{ mt: "20px" }}>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ width: "100%", p: "8px", boxShadow: "none" }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="outlined"
                sx={{ width: "100%", p: "8px", boxShadow: "none" }}
                onClick={handleBack}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </Box>
  );
};

export default CouponCodeForm;

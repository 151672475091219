import { useEffect, useState } from "react";
import TempleView from "../../Views/Temple/TempleView";
import { UserService, TempleService, PanchangService, PostService } from "td_server";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import ParentView from "../../Views/PrarentView/ParentView";
import LiveDarshanVideoView from "../../Views/LiveDarshan/LiveDarshanVideoView";
import { getDarshanList } from "../../store/actions/templeAction";
function LiveDarshanVideoScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const [darshanDataFortemple,setDarshanDataFortemple] = useState(null)
  var { darshanList } = useSelector((state: any) => state.temple);
  useEffect(()=>{
    dispatch(getDarshanList())
  },[])

  useEffect(()=>{
    const darshanIdComing = location.pathname.split("/")[2];
    console.log("Darshna id coming",darshanIdComing);
    const darshanDataForId = darshanList.filter(function(item:any){return darshanIdComing===item.id})
    
    if(darshanDataForId&&darshanDataForId.length>0){
      setDarshanDataFortemple(darshanDataForId[0])
    }
  },[darshanList])


    return (
      <ParentView>
        <LiveDarshanVideoView darshanDataFortemple={darshanDataFortemple}/>
      </ParentView>
    );
  }
  
  export default LiveDarshanVideoScreen;
  
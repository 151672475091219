import { Box, Grid } from "@mui/material";
import LiveDarshan from "../../components/card/LiveDarshan/LiveDarshan";

import "./LiveDarshanView.scss";

function LiveDarshanView(props: any) {
  console.log("Darshan data in darshan view",props.darshanList)
  return (
    <Box className="pageContainer liveDarshanPage">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">

          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
            <Box className="liveDarshanView">
              {props.darshanList.map((item:any,id:any)=>{
                return (
                  <LiveDarshan templeData={item} image={item.media?.url} name={item.templeName} location={item.state} goToTempleDarshan={props.goToTempleDarshan}/>
                )
              })}
              
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">

          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default LiveDarshanView;

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Grid, Typography } from "@mui/material";
import Link from '@mui/material/Link';

export default function PersonalInfoView(props: any) {
  return (
    // <Box className="personalInfo">
    //   <Box className="personalInfoTitle">
    //     <Typography>Personal Information</Typography>
    //     {!props.personalInfoEditMode ? (
    //       <Link onClick={() => props.setPersonalInfoEditMode(true)}>
    //         <i className="bi bi-pencil-fill"></i> Edit Profile
    //       </Link>
    //     ) : (
    //       <Link onClick={() => props.cancelUpdate()}>
    //         Cancel edit
    //       </Link>
    //     )}
    //   </Box>

    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props?.userPersonalInfo?.firstName  ? props?.userPersonalInfo?.firstName : " " }
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           firstName: e.target.value,
    //         });
    //       }
    //     }}
    //     label="First Name"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.userPersonalInfo.lastName}
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           lastName: e.target.value,
    //         });
    //       }
    //     }}
    //     label="Last Name"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.userPersonalInfo.email}
    //     onChange={(e:any) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           email: e.target.value,
    //         });
    //       }
    //     }}
    //     label="Email"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     value={props.phoneNumberNonEditable}
    //     label="Mobile Number"
    //     variant="standard"
    //   />
    //   <br></br>
    //   <TextField className="inputField"
    //     id="standard-basic"
    //     label="Birth Day"
    //     value={props.userPersonalInfo.dateOfBirth}
    //     onChange={(e) => {
    //       if (props.personalInfoEditMode) {
    //         props.setUserPersonalInfo({
    //           ...props.userPersonalInfo,
    //           dateOfBirth: e.target.value,
    //         });
    //       }
    //     }}
    //     variant="standard"
    //   />

    //   <br></br>
    //   <br></br>
    //   {props.personalInfoEditMode ? (
    //     <Box className="updateBtn">
    //       <Button variant="contained" onClick={() => props.submitUpdate()}>Update profile</Button>
    //     </Box>
    //   ) : null}
    // </Box>
    <Box className="personalInfo">
      <Box className="personalInfoTitle">
        <Typography>Personal Information</Typography>
        {!props.personalInfoEditMode ? (
          <Link onClick={() => props.setPersonalInfoEditMode(true)}>
            <i className="bi bi-pencil-fill"></i> Edit Profile
          </Link>
        ) : (
          <Link onClick={() => props.cancelUpdate()}>
            Cancel edit
          </Link>
        )}
      </Box>

      <TextField
        className="inputField"
        id="first-name"
        value={props?.userPersonalInfo?.firstName || ""}
        onChange={(e:any) => {
          if (props.personalInfoEditMode) {
            props.setUserPersonalInfo({
              ...props.userPersonalInfo,
              firstName: e.target.value,
            });
          }
        }}
        label="First Name"
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="last-name"
        value={props.userPersonalInfo.lastName || ""}
        onChange={(e:any) => {
          if (props.personalInfoEditMode) {
            props.setUserPersonalInfo({
              ...props.userPersonalInfo,
              lastName: e.target.value,
            });
          }
        }}
        label="Last Name"
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="email"
        value={props.userPersonalInfo.email || ""}
        onChange={(e:any) => {
          if (props.personalInfoEditMode) {
            props.setUserPersonalInfo({
              ...props.userPersonalInfo,
              email: e.target.value,
            });
          }
        }}
        label="Email"
        variant="standard"
      />
      <br />

      <TextField
        className="inputField"
        id="mobile-number"
        value={props.phoneNumberNonEditable || ""}
        label="Mobile Number"
        variant="standard"
        InputProps={{
          readOnly: true,
        }}
      />
      <br />

      <TextField
        className="inputField"
        id="birth-day"
        label="Birth Day"
        value={props.userPersonalInfo.dateOfBirth || ""}
        onChange={(e:any) => {
          if (props.personalInfoEditMode) {
            props.setUserPersonalInfo({
              ...props.userPersonalInfo,
              dateOfBirth: e.target.value,
            });
          }
        }}
        variant="standard"
      />
      <br />
      <br />

      {props.personalInfoEditMode && (
        <Box className="updateBtn">
          <Button variant="contained" onClick={() => props.submitUpdate()}>
            Update profile
          </Button>
        </Box>
      )}
    </Box>

  );
}

import React, { Component } from 'react';
import { Box, Typography } from "@mui/material";
import { zodiacSign } from "../../utils/Constants";

import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import "./HoroscopeView.scss";

import ariesImg from '../../assets/horoscopes/aries.png';
import taurusImg from '../../assets/horoscopes/taurus.png';
import geminiImg from '../../assets/horoscopes/gemini.png';
import cancerImg from '../../assets/horoscopes/cancer.png';
import leoImg from '../../assets/horoscopes/leo.png';
import virgoImg from '../../assets/horoscopes/virgo.png';
import libraImg from '../../assets/horoscopes/libra.png';
import scorpioImg from '../../assets/horoscopes/scorpio.png';
import sagittariusImg from '../../assets/horoscopes/sagittarius.png';
import capricornImg from '../../assets/horoscopes/capricorn.png';
import aquariusImg from '../../assets/horoscopes/aquarius.png';
import piscesImg from '../../assets/horoscopes/pisces.png';



const HoroscopeCarousel = (props: any) => {

  const images = [
    { src: ariesImg, alt: 'ariesImg' },
    { src: taurusImg, alt: 'taurusImg' },
    { src: geminiImg, alt: 'geminiImg' },
    { src: cancerImg, alt: 'cancerImg' },
    { src: leoImg, alt: 'leoImg' },
    { src: virgoImg, alt: 'virgoImg' },
    { src: libraImg, alt: 'libraImg' },
    { src: scorpioImg, alt: 'scorpioImg' },
    { src: sagittariusImg, alt: 'sagittariusImg' },
    { src: capricornImg, alt: 'capricornImg' },
    { src: aquariusImg, alt: 'aquariusImg' },
    { src: piscesImg, alt: 'piscesImg' }
  ];

  const selectedItem = props.selectedItem;


  return (
    // <Carousel showIndicators={false} 
    //  selectedItem={selectedItem} 
    //  centerMode={true} 
    //  showArrows={false}
    // //  showThumbs={false}
    //  onChange={(e:any)=>props.navigateToSelectedSignIndex(e)}> 
    //   <Box>
    //     <img src={ariesImg} />
    //   </Box>
    //   <Box>
    //     <img src={taurusImg} />
    //   </Box>
    //   <Box>
    //     <img src={geminiImg} />
    //   </Box>
    //   <Box>
    //     <img src={cancerImg} />
    //   </Box>
    //   <Box>
    //     <img src={leoImg} />
    //   </Box>
    //   <Box>
    //     <img src={virgoImg} />
    //   </Box>
    //   <Box>
    //     <img src={libraImg} />
    //   </Box>
    //   <Box>
    //     <img src={scorpioImg} />
    //   </Box>
    //   <Box>
    //     <img src={sagittariusImg} />
    //   </Box>
    //   <Box>
    //     <img src={capricornImg} />
    //   </Box>
    //   <Box>
    //     <img src={aquariusImg} />
    //   </Box>
    //   <Box>
    //     <img src={piscesImg} />
    //   </Box>
    // </Carousel>

    <Carousel
      showIndicators={false}
      className='maincarousel'
      selectedItem={props.selectedItem}
      centerMode={true}
      showArrows={false}
      onChange={(e: any) => props.navigateToSelectedSignIndex(e)}
    >
      {images.map((image, index) => (
        <Box key={index} className={`carousel-item carousel-item-${index + 1}`}>
          <img src={image.src} alt={image.alt} />
        </Box>
      ))}
    </Carousel>
  );
};

export default HoroscopeCarousel;


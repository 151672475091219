import { useEffect, useState } from "react";

import { Container, Typography } from "@mui/material";
import SendPushNotificationView from "../../Views/Admin/Notification/SendPushNotificationView";

import { UserService } from "td_server";

import { env } from "../../env";
import ParentView from "../../Views/PrarentView/ParentView";

function SendPushNotification() {
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [sendNotfnStatus, setSendNotifnStatus] = useState(false);
  const handleSubmit = async (notification: any) => {
    setIsSendingNotification(true);
    let isSendToSpecificNumbers = notification.isSendToSpecificNumbers;
    if (env === "prod" && isSendToSpecificNumbers === false) {
      try {
        console.log("Getting inside porod flow");
        var notificationImage = "";
        if (notification.image) {
          const sourceData = {
            uri: notification.image,
            platform: "web",
          };
          notificationImage = await UserService.uploadImageForPushNotification(
            sourceData
          );
          console.log("Image Url", notificationImage);
        }

        UserService.sendPushNotificationToAllDevice(
          [],
          notification.title,
          notification.message,
          notificationImage,
          { details: JSON.stringify(notification.pushDetails) }
        )
          .then((res) => {
            console.log("Response", res);
            setIsSendingNotification(false);
            setSendNotifnStatus(true);
          })
          .catch((err) => console.log("eerrr", err));
      } catch (error) {
        console.log(error);
        setIsSendingNotification(false);
      }
    } else {
      try {
        console.log(
          "In noraml flow Notification data",
          JSON.stringify(notification.pushDetails)
        );
        let isSendToSpecificNumbers = notification.isSendToSpecificNumbers;
        let pushToUserList = notification.pushToUserList;

        console.log("isSendToSpecificNumbers :  ", isSendToSpecificNumbers);
        console.log("pushToUserList :  ", pushToUserList.length);
        let pushTokens: any = [];
        if (isSendToSpecificNumbers) {
          console.log("pushToUserList specific :  ", pushToUserList.length);
          for (let i = 0; i < pushToUserList.length; i++) {
            if (pushToUserList[i].pushToken) {
              // console.log("Token",pushToUserList[i].pushToken)
              pushTokens = [...pushTokens, pushToUserList[i].pushToken];
            }
          }
        } else {
          //avinash token
          pushTokens.push(
            "fxg1rBpTS5uVomEFw5AKg0:APA91bGc0lwhvGPErfbtSYTRSr0u-BAjhUYmMRMWNHfu8JQWo5GnHIOfFdCLjMmUmoPNdGe4J7PjNpuAUDoaa4fjARs7bmhE0ji32rb5Ncf6O5tr0R7lvBJdUNuUzTp07QIpYfckoUHK"
          );
        }

        console.log("Push token List", pushTokens);
        var notificationImage = "";
        if (notification.image) {
          const sourceData = {
            uri: notification.image,
            platform: "web",
          };
          notificationImage = await UserService.uploadImageForPushNotification(
            sourceData
          );
          console.log("Image Url", notificationImage);
        }
        UserService.sendUserPushNotificaion(
          pushTokens,
          notification.title,
          notification.message,
          notificationImage,
          { details: JSON.stringify(notification.pushDetails) }
        )
          .then((res) => {
            setIsSendingNotification(false);
            setSendNotifnStatus(true);
          })
          .catch((err) => console.log("eerrr", err));
      } catch (error) {
        console.log(error);
        setIsSendingNotification(false);
      }
    }
  };

  return (
    <SendPushNotificationView
      onSubmit={handleSubmit}
      isLoading={isSendingNotification}
      setSendNotifnStatus={setSendNotifnStatus}
      sendNotfnStatus={sendNotfnStatus}
    ></SendPushNotificationView>
  );
}

export default SendPushNotification;

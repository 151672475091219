
import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

const MultipleSelectAutocomplete: React.FC<any> = ({ options, onChange,label,placeholder,alreadySelectedOptions=[] }) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedOptions(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    if(alreadySelectedOptions?.length > 0){
      const items = options?.filter((item: any) => alreadySelectedOptions.includes(item.value));
      setSelectedOptions(items)
    }
    
  }, [])

  return (
    <Autocomplete
      multiple
      options={options}
      getOptionLabel={(option) => option.label}
      value={selectedOptions}
      onChange={handleChange}
      renderTags={(value: any[], getTagProps) =>
        value.map((option: any, index: number) => (
          <Chip
            label={option.label}
            {...getTagProps({ index })}
            key={option.value}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default MultipleSelectAutocomplete;

// import AsyncStorage from '@react-native-async-storage/async-storage';
// import uuid from 'uuid';
import {uid} from "../../utils/HelperFunctions";
import {
  setUser,
  setLoading,
  setAddressList,
  setCurrentAddress,
  setAllOrganisationUser,
  setAllNormalUser
} from './../slices/userSlice';
import {UserService, AuthService} from 'td_server';

// export const getUserLocationAction =
//   (latitude: number, longitude: number) =>
//   async (dispatch: any, getState: any) => {
//     try {
//       //dispatch(setLoading(true));
//       //console.log('longitude = ', longitude, latitude);
//       const response: any = await UserService.getUserAddressOnGeoLocation(
//         latitude,
//         longitude,
//       );
//       if (response?.results) {
//         //console.log('responseresponse= ', response.results);

//         // const address = response?.results[0]?.formatted_address;
//         const userInfo = getState().user.userInfo;
//         //console.log('userInfouserInfo = ', userInfo);
//         let index = 0;
//         const check =
//           userInfo?.personalInfo?.address &&
//           userInfo?.personalInfo?.address.length &&
//           userInfo?.personalInfo?.address.filter((obj: any, i: number) => {
//             if (obj.latitude === latitude && obj.longitude === longitude) {
//               index = i;
//               return true;
//             } else {
//               index = i;
//               return false;
//             }
//           });
//         let addressList: any = [];
//         let addresses = [];
//         //console.log('checkkk = ', userInfo);
//         if (check && check.length) {
//           dispatch(setCurrentAddress(check[0]));
//           addressList.push(check[0]);
//           addressList = [
//             ...addressList,
//             ...(userInfo?.personalInfo?.address
//               ? userInfo.personalInfo.address.splice(index, 1)
//               : null),
//           ];
//         } else {
//           dispatch(
//             setCurrentAddress({
//               ...response?.results[0],
//               savedAs: response?.results[0].address_components[2].long_name,
//             }),
//           );
//           // addressList.push([
//           //   {
//           //     ...response?.results[0],
//           //     savedAs: response?.results[0].address_components[2].long_name,
//           //   },
//           // ]);
//           addressList = [
//             ...addressList,
//             ...(userInfo?.personalInfo?.address
//               ? userInfo.personalInfo.address
//               : null),
//           ];
//         }

//         const newUserInfo = {
//           ...userInfo,
//           personalInfo: {
//             ...userInfo.personalInfo,
//             address: addressList,
//           },
//         };
//         dispatch(setUser(newUserInfo));

//         dispatch(setLoading(false));
//         return Promise.resolve('');
//       } else {
//         dispatch(setLoading(false));
//         return Promise.reject('');
//       }
//     } catch (error: any) {
//       //console.log('USER LOCATION : ', error);
//       dispatch(setLoading(false));
//       return Promise.reject('');
//     }
//   };

export const getUserGPSAddressAction =
  (latitude: number, longitude: number) =>
  async (dispatch: any, getState: any) => {
    try {
      //dispatch(setLoading(true));
      //console.log('longitude = ', longitude, latitude);
      const response: any = await UserService.getUserAddressOnGeoLocation(
        latitude,
        longitude,
      );
      // console.log("Location response",response)
      if (response?.results) {
        let address = response.results[0]
        let area = '';
        let city = '';
        let state = '';
        let zip = '';
        if (address.address_components.length) {
          //console.log('address = ', address);
          address.address_components.forEach((item: any, i: number) => {
            if (i === 2) area = item.long_name;
            if (item?.types?.includes('administrative_area_level_1')) {
              state = item.long_name;
            }
            if (item?.types?.includes('locality')) {
              city = item.long_name;
            }
            if (item?.types?.includes('postal_code')) {
              zip = item.long_name;
            }
          });
        }
        let formatted_address = address?.formatted_address || '';

        let modiifedPayload ={
          coordinates: {
          latitude: latitude,
          longitude:longitude
        },
        address: {
          street: '',
          city: city,
          state: state,
          zip: zip,
          area: area,
          formatted_address: formatted_address,
          isPrimary: false,
          phoneNumber: '',
          latitude: latitude,
          longitude:longitude
        }}
        // console.log("GPS address--->",modiifedPayload)
        dispatch(setCurrentAddress(modiifedPayload))

        dispatch(setLoading(false));
        return Promise.resolve('');
      } else {
        dispatch(setLoading(false));
        return Promise.reject('');
      }
    } catch (error: any) {
      console.log('USER LOCATION : ', error);
      dispatch(setLoading(false));
      return Promise.reject('');
    }
  };

export const updateUserProfileImageAction =
  (id: any, source: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response = await UserService.updateUserProfileImage(id, source);
      console.log("Profile pic change response",response);
      const userInfo = getState().user.userInfo;
      const checkUser = await AuthService.handleUserLogin(
        userInfo?.phoneNumber,
      );

      dispatch(setUser(checkUser.user));
      dispatch(setLoading(false));
      return Promise.resolve('');
    } catch (e) {
      dispatch(setLoading(false));
      console.log("PP chang error",e)
      return Promise.reject(e);
    }
  };

export const updateUserCoverImageAction =
  (id: any, source: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response = await UserService.updateUserCoverImage(id, source);
      const userInfo = getState().user.userInfo;

      const checkUser = await AuthService.handleUserLogin(
        userInfo?.phoneNumber,
      );
      dispatch(setUser(checkUser.user));

      dispatch(setLoading(false));
      return Promise.resolve('');
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };
export const updateProfileAction =
  (formData: any) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      let userInfo = getState().user.userInfo;
      formData.email.toLowerCase().trim();

    //   await UserService.updateUserProfile(
    //     {...formData.personalInfo},
    //     userInfo.id,
    //   );
    await UserService.updateUserProfile(
        {...formData},
        userInfo.id,
      );

      const checkUser = await AuthService.handleUserLogin(
        userInfo?.phoneNumber,
      );
      await localStorage.setItem('userInfo', JSON.stringify(checkUser.user));

      dispatch(setUser(checkUser.user));
      // dispatch(setUser(userInfo));
      dispatch(setLoading(false));
      return Promise.resolve('');
    } catch (e) {
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

export const addAddressAction =
  (formData: any, editFlag: boolean, editId: any, screen: any) =>
  async (dispatch: any, getState: any) => {
    try {
      const userInfo = getState().user.userInfo;
      dispatch(setLoading(true));
      const id = editId ? editId : uid();
    //   formData = {...formData.coordinates, ...formData.address, id};
    formData = {...formData, id};
      await UserService.addOrUpdateUserAddressById(
        formData,
        userInfo.id,
        editFlag,
      );
      // if (editId) {
      //   const editedAddress = userInfo.personalInfo.address.map((a: any) => {
      //     if (a.id === editId) {
      //       return {...a, ...formData};
      //     }
      //     return a;
      //   });
      //   const updateUserInfo = {
      //     ...userInfo,
      //     personalInfo: {
      //       ...userInfo.personalInfo,
      //       address: editedAddress,
      //     },
      //   };
      //   dispatch(setUser(updateUserInfo));
      //   await AsyncStorage.setItem('userInfo', JSON.stringify(updateUserInfo));
      // } else {
      //   const updateUserInfo = {
      //     ...userInfo,
      //     personalInfo: {
      //       ...userInfo.personalInfo,
      //       address: [formData, ...userInfo.personalInfo.address],
      //     },
      //   };
      //   dispatch(setUser(updateUserInfo));
      //   await AsyncStorage.setItem('userInfo', JSON.stringify(updateUserInfo));
      // }
      const checkUser = await AuthService.handleUserLogin(
        userInfo?.phoneNumber,
      );

      dispatch(setUser(checkUser.user));
      await localStorage.setItem('userInfo', JSON.stringify(checkUser.user));
    //   if (screen && screen === 'dashboard') {
    //     dispatch(setCurrentAddress(formData));
    //   }
      dispatch(setLoading(false));
      return Promise.resolve('');
    } catch (e) {
      //console.log('action error = ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

// export const getAddressListAction =
//   () => async (dispatch: any, getState: any) => {
//     try {
//       const userInfo = getState().user.userInfo;
//       dispatch(setLoading(true));
//       const response = await UserService.getAddressListOnId(userInfo.id);
//       dispatch(setAddressList({...userInfo, response}));
//       dispatch(setLoading(false));
//       return Promise.resolve('');
//     } catch (e) {
//       //console.log('action error = ', e);
//       dispatch(setLoading(false));
//       return Promise.reject(e);
//     }
//   };

export const deleteUserAddressAction =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      const userInfo = getState().user.userInfo;
      dispatch(setLoading(true));
      await UserService.deleteAddressById(userInfo.id, id);
      const editedAddress = userInfo.personalInfo.address.map(
        (a: any) => a.id === id,
      );

      const checkUser = await AuthService.handleUserLogin(
        userInfo?.phoneNumber,
      );

      dispatch(setUser(checkUser.user));
      await localStorage.setItem('userInfo', JSON.stringify(checkUser.user));
      dispatch(setLoading(false));
      return Promise.resolve('');
    } catch (e) {
      //console.log('action error = ', e);
      dispatch(setLoading(false));
      return Promise.reject(e);
    }
  };

// export const addCardDetailsByUserId =
//   (formData: any, type: string) => async (dispatch: any, getState: any) => {
//     try {
//       const userInfo = getState().user.userInfo;
//       //console.log('formData= ', formData);
//       dispatch(setLoading(true));
//       const id = uuid.v4();
//       const newData = {...formData, id};
//       //console.log('userInfo.id = ', userInfo.id);
//       await UserService.addCardDetailsByUserId(userInfo.id, newData, type);
//       let updateUserInfo;
//       if (type === 'CARD') {
//         updateUserInfo = {
//           ...userInfo,
//           personalInfo: {
//             ...userInfo.personalInfo,
//             payment: {
//               ...(userInfo?.personalInfo?.payment || {}),
//               cards: [
//                 ...(userInfo?.personalInfo?.payment?.cards || []),
//                 newData,
//               ],
//             },
//           },
//         };

//         //console.log('updateUserInfo = ', updateUserInfo);
//       }
//       if (type === 'UPI') {
//         updateUserInfo = {
//           ...userInfo,
//           personalInfo: {
//             ...userInfo.personalInfo,
//             payment: {
//               ...(userInfo?.personalInfo?.payment || {}),
//               upi: [...(userInfo?.personalInfo?.payment?.upi || []), newData],
//             },
//           },
//         };
//       }
//       dispatch(setUser(updateUserInfo));
//       await AsyncStorage.setItem('userInfo', JSON.stringify(updateUserInfo));
//       dispatch(setLoading(false));
//       return Promise.resolve('');
//     } catch (e) {
//       //console.log('action error = ', e);
//       dispatch(setLoading(false));
//       return Promise.reject(e);
//     }
//   };

// export const setCurrentAddressAction =
//   (address: any) => async (dispatch: any) => {
//     dispatch(setCurrentAddress(address));
//   };

// export const getAllOrganisationUser =
//   (locationData: any) => async (dispatch: any, getState: any) => {
//     try {
//       dispatch(setLoading(true));
//       const response: any = await UserService.getAllOrganisationUser();
//       if (response.user) {
//         dispatch(setAllOrganisationUser(response.user));
//       } else {
//         dispatch(setAllOrganisationUser([]));
//       }
//       dispatch(setLoading(false));
//       return Promise.resolve(response);
//     } catch (e) {
//       //console.log('Temple LIST ERROR : ', e);
//       dispatch(setLoading(false));
//       return Promise.reject('');
//     }
//   };


export const getAllUsers = () => async (dispatch: any, getState: any) => {
    
    try {
      dispatch(setLoading(true));
      const response: any = await UserService.getAllUsers();
      if (response.userList) {
        dispatch(setAllNormalUser(response.userList));
      } else {
        dispatch(setAllNormalUser([]));
      }
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log('USER LIST  ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject('');
    }
  };

import Button from "@mui/material/Button";
import verifytick from "../../assets/verify-tick.png";
import share from "../../assets/share.png";
import whitelike from "../../assets/whitelike.png";
// import "./TempleDetailsView.scss";
import GoogleMap from "../../components/map/GoogleMap";
import LeftAddressCard from "../../components/card/LeftBlock/LeftAddressCard";
import RightBlockCard from "../../components/card/RightBlock/RightBlock";
import TempleDetailsBanner from "../../components/banner/TempleDetailsBanner";
import TitleBlockCard from "../../components/card/TitleBlock/TitleBlock";
import { Avatar, Box, Grid, Icon, List, ListItem, ListItemAvatar, ListItemText, Paper } from "@mui/material";
import TextArea from "../../components/textarea/Textarea";
import Typography from "@mui/material/Typography";
import ImageViewer from "../../components/imageviewer/ImageViewer";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChadhavaBanner from '../../assets/chadhava-banner.jpg';
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import './Chadhava.scss';
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import MorPankh from '../../assets/morpankh.png';
import TempleFlag from '../../assets/temple-flag.png';
import { ButtonGroup } from "react-bootstrap";
import RemoveIcon from '@mui/icons-material/Remove';
import { AddIcon } from "@chakra-ui/icons";

function ChadhavaDetailsView(props: any) {
  // const checkDetails = props.templeDetails.

  const templeProfilePic = props.templeProfilePic();
  return (
    <Box className="pageContainer templeDetailsPage">
      <Box className="templeDetailsBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
              {/* <LeftAddressCard
                header="Current Address"
                address="198/3, 3 Cross 80 Feet Road, Next.."
                footerButton="Edit or add an address"
              /> */}
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={6}>
              <Box className="chadhavaContainer">
                <Box className="chadhavaBanner">
                  <img src={ChadhavaBanner} />
                </Box>
                <Box className="pujaDescription">
                  <List>
                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "50px" }}>
                        <Avatar alt="Temple Icon" className="squreShape temple">
                          <img src={templeIcon} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>Baba Khatu Shyam Temple</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar sx={{ minWidth: "50px" }}>
                        <Avatar alt="Location Icon" className="squreShape location">
                          <img src={locationIcon} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>Khatoo, Rajasthan</ListItemText>
                    </ListItem>
                    <ListItem>
                      <ListItemText><span className="dflexBdr"><span className="highlight">232+</span> devotees booked Chadhava from this temple</span></ListItemText>
                    </ListItem>
                  </List>
                </Box>
                <Box className="chadhavaDetailsBlock templeOverview">
                  <SubtitleTypographyPrimary title="Temple Overview" />
                  <ParagraphTypography title="In Hinduism, KhatuShyam is a manifestation of
                    Barbarika, son of Ghatotkacha. The legend begins
                    with the Mahabharata. Barbarika alias Khatu Shyamji
                    or Shyam Baba was a grandson of the brave prince,
                    Bhima, second of the Pandava brothers."/>
                  <ParagraphTypography title="He was the son of Ghatotkacha, sired by Bhima
                    through one of his wives, Jagadamba."/>
                </Box>
                <Box className="chadhavaDetailsBlock aboutTD">
                  <SubtitleTypographyPrimary title='About "Temple Dekho" ' />
                  <ParagraphTypography title="Please watch our feature video to discover the capabilities of the Temple Dekho app." />
                  <Box className="btnBlock"><Button className="btnChadhavaVideo">SEE VIDEO</Button></Box>
                </Box>
                <Box className="chadhavaDetailsBlock chadhavaItems">
                  <SubtitleTypographyPrimary title="Chadhava Items" />
                  <Box className="chadhavaItem">
                    <Box className="chadhavaLeft">
                      <Typography>Mor Pankh <span className="offerItem">Most offered</span></Typography>
                      <ParagraphTypography title="Devotees often offer peacock feathers to deities in temples as a gesture of…" />
                      <TitleTypography title="₹ 19.00" />
                    </Box>
                    <Box className="chadhavaRight">
                      <Box className="itemImg">
                        <img src={MorPankh}></img>
                      </Box>
                      <Box className="btnAdd"><Button className="chadhavaAdd" variant="outlined">ADD</Button></Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="chadhavaDetailsBlock chadhavaItems">
                  <Box className="chadhavaItem">
                    <Box className="chadhavaLeft">
                      <Typography>Temple Flag <span className="offerItem">Most offered</span></Typography>
                      <ParagraphTypography title="Devotees often offer peacock feathers to deities in temples as a gesture of…" />
                      <TitleTypography title="₹ 159.00" />
                    </Box>
                    <Box className="chadhavaRight">
                      <Box className="itemImg">
                        <img src={TempleFlag}></img>
                      </Box>
                      {/* <Box className="btnAdd"><Button className="chadhavaAdd" variant="outlined">ADD</Button></Box> */}
                      <Box className="addItemsBtns">
                        <ButtonGroup className="btnsGroup">
                          <Button variant="contained"><RemoveIcon fontSize="small"/></Button>
                          <Button variant="contained">1</Button>
                          <Button variant="contained"><AddIcon fontSize="small"/></Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="chadhavaDetailsBlock chadhavaItems">
                  <Box className="chadhavaItem">
                    <Box className="chadhavaLeft">
                      <Typography>Mor Pankh <span className="offerItem">Most offered</span></Typography>
                      <ParagraphTypography title="Devotees often offer peacock feathers to deities in temples as a gesture of…" />
                      <TitleTypography title="₹ 19.00" />
                    </Box>
                    <Box className="chadhavaRight">
                      <Box className="itemImg">
                        <img src={MorPankh}></img>
                      </Box>
                      <Box className="btnAdd"><Button className="chadhavaAdd" variant="outlined">ADD</Button></Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ChadhavaDetailsView;

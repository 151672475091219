import React from "react";
import SendPushNotificationForm from "./SendPushNotificationForm";
import { Box, Container, Paper, Dialog,Button } from "@mui/material";
import "./notification.css";
interface NotificationFormProps {
  onSubmit: ({}: any) => void;
  isLoading: any;
  setSendNotifnStatus: any;
  sendNotfnStatus: any;
}

const SendPushNotificationView: React.FC<NotificationFormProps> = ({
  onSubmit,
  isLoading,
  setSendNotifnStatus,
  sendNotfnStatus,
}) => {
  const handleSubmit = (notification: string) => {
    // Handle form submission logic here
    onSubmit(notification);
  };

  return (
    <Container fixed>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh" }}
      >
        <Paper
          elevation={3}
          sx={{ width: "50%", padding: "40px", marginTop: "50px" }}
        >
          <SendPushNotificationForm
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </Paper>
        <Dialog
          className="startPostInput"
          onClose={() => setSendNotifnStatus(false)}
          open={sendNotfnStatus}
        >
          <Box style={{width:"25em",height:"10em"}}>
            <p style={{marginTop:"10%",marginLeft:"10px",fontSize:"23px"}}>
             Notification sent successfully  
            </p>
              <Button style={{marginRight:"auto"}} onClick={()=>setSendNotifnStatus(false)}>Ok</Button>
          </Box>
          
        </Dialog>
      </Box>
    </Container>
  );
};

export default SendPushNotificationView;

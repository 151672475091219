import CheckoutView from "../../Views/Checkout/CheckoutView";
import { Outlet, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
function CheckoutScreen() {
  const navigate = useNavigate();

  const pjuaDetails = useSelector(
    (state: any) => state.temple.selectedPuja
  );
  const { selectedPuja } = useSelector((state: any) => state.temple);
  //Go back to puja list screen
  const { cartInfo } = useSelector(
    (state: any) => state.cart
  );

  const isCartEnabled = cartInfo&&cartInfo.length>0?true:false
  return (
    <ParentView>
      <CheckoutView isCartEnabled={isCartEnabled}/>
    </ParentView>
  );
}

export default CheckoutScreen;

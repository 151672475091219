import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useLoadScript } from '@react-google-maps/api';
import { googleMapKey } from "../../configs/firebaseConfig";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { convertMinutesToOffset } from '../../utils/HelperFunctions';
const libraries: ('places')[] = ['places'];

interface CityOption {
  label: string;
  value: string;
  utc_offset_minutes:string
  lat: string;
  lng: string;
  
}

interface CityAutocompleteProps {
  onChangeHandle: (city: CityOption | null) => void;
}

const CityAutocomplete: React.FC<CityAutocompleteProps> = ({  onChangeHandle }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapKey,
    libraries,
  });

  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<CityOption[]>([]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    const request = {
      input: inputValue,
      types: ['(cities)'],
      // componentRestrictions: { country: 'in' },
    };

    service.getPlacePredictions(request, (predictions:any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setOptions(
          predictions.map((prediction:any) => ({
            label: prediction.description,
            value: prediction.place_id,
          }))
        );
      }
    });
  }, [inputValue]);

  const handlePlaceSelect = (placeId: string) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );
    const request = {
      placeId,
      fields: ["name",'utc_offset_minutes','geometry.location'],
    };

    service.getDetails(request, (place:any, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {

        const lat = place?.geometry?.location?.lat();
        const lng = place?.geometry?.location?.lng();
        const utc_offset_minutes = place?.utc_offset_minutes ? convertMinutesToOffset(place?.utc_offset_minutes) : "0";
        onChangeHandle({ label: place?.name, value: placeId, utc_offset_minutes,lat,lng });
      }
    });
  };


  if (!isLoaded) return <div>Loading...</div>;

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, value) => {
       // onChangeHandle(value);
        if (value) {
          handlePlaceSelect(value.value);
        } else {
          onChangeHandle(null);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label="Enter Your Birth Location" variant="outlined"
         
        />
      )}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <LocationOnIcon style={{ marginRight: 8 }} />
          <Typography variant="body2">{option.label}</Typography>
        </Box>
      )}
    />
  );
};

export default CityAutocomplete;

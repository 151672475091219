import {createSlice} from '@reduxjs/toolkit';

const HoroscopeSlice = createSlice({
  name: 'horoscope',
  initialState: {
    isLoading: false,
    horoscope:[],
    error:"",
    //calcMoonSign:""
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setHoroscope: (state, action) => {
      state.horoscope = action.payload;
    },

    // setCalcMoonSign: (state, action) => {
    //   state.calcMoonSign = action.payload;
    // },
   
   
  },
});

export const {
  setLoading,
  setError,
  setHoroscope,
  //setCalcMoonSign
  
  
} = HoroscopeSlice.actions;

export default HoroscopeSlice.reducer;

import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    isLoading: false,
    error: null,
    confirmation: null,
    registrationDetails: null,
    signUpProfile:null
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setConfirmation: (state, action) => {
      state.confirmation = action.payload;
    },
    setRegistrationDetails: (state, action) => {
      state.registrationDetails = action.payload;
    },
    setSignUpProfile: (state, action) => {
      state.signUpProfile = action.payload
    }
  },
});

export const {
  setAuthUser,
  setLoading,
  setError,
  setConfirmation,
  setRegistrationDetails,
  setSignUpProfile
} = authSlice.actions;

export default authSlice.reducer;

import { createContext, useState } from "react";
import { TextMapping } from "./TextMapping";


type TextContextType ={
    constantTexts:any
    setTextData:any
    textData:any
}
type TextContextProviderProps = {
    children: React.ReactNode
}
export const TextContext  = createContext<TextContextType|null>(null);
export const TextContextProvider = ({ children }:TextContextProviderProps) => {
    const [textData, setTextData]:any = useState("ENGLISH");
    var constantTexts:any = null
    if(textData==="ENGLISH"){
        constantTexts = TextMapping.ENGLISH
    }
    else if(textData==="HINDI"){
      constantTexts = TextMapping.HINDI
    }
    else{
        constantTexts = TextMapping.BENGALI
    }
  return (
    <TextContext.Provider value={{constantTexts,setTextData,textData}}>
      {children}
    </TextContext.Provider>
  );
};

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import "./styles.css";
interface AnalyticFilterProps {
  handleFilter: (filterType: string) => void;
  activeFilter:any ;
}

const AnalyticFilter: React.FC<AnalyticFilterProps> = ({ handleFilter,activeFilter }) => {
    //const [activeFilter, setActiveFilter] = useState<string>(); // State to store the active filter
  
    const handleButtonClick = (filterType: string) => {
     // setActiveFilter(filterType);
      handleFilter(filterType);
    };
  
    return (
        
        <ButtonGroup variant="contained" aria-label="analytic filter buttons" >
        <Button
          className='btngroupanalytics'
          onClick={() => handleButtonClick('7day')}
          color={activeFilter === '7day' ? 'primary' : 'inherit'} // Apply 'primary' color if active, 'inherit' otherwise
          sx={{ padding: "14px", boxShadow: "none" }}
        >
          Last 7 Days
        </Button>
        <Button
          className='btngroupanalytics'
          onClick={() => handleButtonClick('today')}
          color={activeFilter === 'today' ? 'primary' : 'inherit'} // Apply 'primary' color if active, 'inherit' otherwise
          sx={{ padding: "14px", boxShadow: "none" }}
        >
          Today's
        </Button>
        <Button
          className='btngroupanalytics'
          onClick={() => handleButtonClick('30day')}
          color={activeFilter === '30day' ? 'primary' : 'inherit'} // Apply 'primary' color if active, 'inherit' otherwise
          sx={{ padding: "14px", boxShadow: "none" }}
        >
          Last 30 Days
        </Button>
      </ButtonGroup>
     
    );
  };
export default AnalyticFilter
import { useState,useEffect } from "react";
import ParentView from "../../Views/PrarentView/ParentView";
import  ShaktipethaView  from "../../Views/Temple/ShaktipethaView";
import {  TempleService } from "td_server";
import {  useLocation, useNavigate } from "react-router-dom";
import { getShaktipeethAndShivlingAction } from "../../store/actions/templeAction";
import { useDispatch, useSelector } from "react-redux";
function ShaktipethaScreen() {
  const navigate = useNavigate();
  const {shaktipeethList,allTempleList} = useSelector((state: any) => state.temple);
  const [shaktipeethShivlingList,setShaktipeethaShivlingList] :any = useState([])
  const dispatch: any = useDispatch();
  const location = useLocation();
  useEffect(() => {

    
    if(shaktipeethList.length===0){
      dispatch(getShaktipeethAndShivlingAction())
    }

  }, []);

  useEffect(()=>{
    const templeTypePath = location.pathname.split("/specialTemples/")
    const templeType = templeTypePath[1].toUpperCase()
    console.log("Temple type",templeType)
    setUpdatedShaktipeethaList(templeType)
  },[shaktipeethList])

  function setUpdatedShaktipeethaList(templeType:string){
    const allOrganisationList = allTempleList

    let updatedTempleList = []
    for(let i=0;i<shaktipeethList.length;i++){
      let isTempleOnborded = false
      let templeId = null
      if(shaktipeethList[i].personalInfo?.templeType.includes(templeType)){
        const shaktipeethaGplaceId = shaktipeethList[i].googlePlaceId
        const checkIfOnboarded = allOrganisationList.filter(function(item:any){return item.googlePlaceId === shaktipeethaGplaceId})

        console.log("Check if onboarded",checkIfOnboarded)
        if(checkIfOnboarded.length>0){
          isTempleOnborded = true
          templeId = checkIfOnboarded[0].id
        }
        else{
          isTempleOnborded = false
        }

        updatedTempleList.push({...shaktipeethList[i],onboarded:isTempleOnborded,templeId:templeId})
      }
    }
    console.log("Updated temple list",updatedTempleList)
    updatedTempleList = updatedTempleList.sort((a, b) => b.onboarded - a.onboarded);
    setShaktipeethaShivlingList(updatedTempleList)
  }
  const onClickNavigateToTemple = (templeId:string)=>{
    navigate(`/temples/${templeId}`)
  }

    return (
      <ParentView>
      <ShaktipethaView templeList={shaktipeethShivlingList} onClickNavigateToTemple={onClickNavigateToTemple}/>
      </ParentView>
    );
  }
  
  export default ShaktipethaScreen;
  
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonService } from "td_server";
import {
  setAnalyticsList,
  setLoading,
  setPaginatedAnalyticsList,
} from "../slices/analyticsSlice";


export const getLoadingState = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));

    return Promise.resolve("");
  } catch (e) {
    //console.log('Analytics LIST ERROR : ', e);
    dispatch(setLoading(false));
    return Promise.reject("");
  }
};

export const updateAnalyticsStatusAction =
  (formData: any, userId: any,dateRange:any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const status = await CommonService.updateAnalyticsStatusForUser(
        formData,
        userId,
        dateRange
      );
      dispatch(setLoading(false));
      // dispatch(getViewedAnalyticsList());
      return Promise.resolve("");
    } catch (error: any) {
      console.log("updateUserStatus ERROR : ", error);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getNewAnalyticsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getAllAnalytics();
      const fdata = response.analyticsList.filter((d: any) => !d.status);
      dispatch(setAnalyticsList(fdata));
      dispatch(setLoading(false));
      return Promise.resolve(fdata);
    } catch (e) {
      //console.log('Analytics LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

  export const addAnalytics = (data:any) => async (dispatch: any, getState: any) => {
    try {
      const response: any = await CommonService.addAnalytics(data);
      return Promise.resolve(response);
    } catch (e) {
      return Promise.reject("");
    }
  };

export const getNewPaginatedAnalyticsList =
  (lastSnapshot: any, queryLimit: any) =>
  async (dispatch: any, getState: any) => {
    try {
      var currentState = getState();
      var currentAnalyticsData = currentState.analytics.analyticsList;
      // console.log("Last snapshot---?>",lastSnapshot)
      // console.log("Analytics state====",currentAnalyticsData)
      dispatch(setLoading(true));
      const response: any = await CommonService.getAnalyticsPaginated(
        lastSnapshot,
        queryLimit
      );
      // console.log("Response from server = ",response)
      dispatch(setPaginatedAnalyticsList(response));
      var updatedResponse = response.queriedAnalytics;
      const fdata = updatedResponse.filter((d: any) => !d.status);
      var tempData = [...currentAnalyticsData, ...fdata];

      // console.log("Temp data--->",tempData)
      var sortedData = tempData;
      dispatch(setAnalyticsList(sortedData));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      console.log("getAnalyticsPaginated LIST ERROR : ", e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getViewedAnalyticsList =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const response: any = await CommonService.getAllAnalytics();
      const fdata = response.analyticsList.filter(
        (d: any) => d?.status && d?.status?.length > 0
      );
      dispatch(setAnalyticsList(fdata));
      dispatch(setLoading(false));
      return Promise.resolve(fdata);
    } catch (e) {
      //console.log('Analytics LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

export const getViewedPaginatedAnalyticsList =
  (lastSnapshot: any, queryLimit: any) =>
  async (dispatch: any, getState: any) => {
    try {
      var currentState = getState();
      var currentAnalyticsData = currentState.analytics.analyticsList;
      // console.log("Last snapshot---?>",lastSnapshot)
      // console.log("Analytics state====",currentAnalyticsData)
      dispatch(setLoading(true));
      const response: any = await CommonService.getAnalyticsPaginated(
        lastSnapshot,
        queryLimit
      );
      // console.log("Response from server = ",response)
      dispatch(setPaginatedAnalyticsList(response));
      var updatedResponse = response.queriedAnalytics;
      console.log("response", updatedResponse);
      const fdata = updatedResponse.filter(
        (d: any) => d?.status && d?.status?.length > 0
      );
      console.log("F data", fdata);
      var tempData = [...currentAnalyticsData, ...fdata];

      // console.log("Temp data--->",tempData)
      var sortedData = tempData;
      dispatch(setAnalyticsList(sortedData));
      dispatch(setLoading(false));
      return Promise.resolve(response);
    } catch (e) {
      //console.log('Analytics LIST ERROR : ', e);
      dispatch(setLoading(false));
      return Promise.reject("");
    }
  };

import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  List,
  ListItem,
  Divider,
  ButtonGroup,
} from "@mui/material";
import { Link } from 'react-router-dom';
import { zodiacSign } from "../../utils/Constants";
import "./HoroscopeView.scss";
import TitleTypography from "../../components/typography/TitleTypography";
import {
  CalendarMonthOutlined,
  Label,
  LocationCityOutlined,
  MapOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { googleMapKey } from "../../configs/firebaseConfig";
import discoverRashiSign from "../../assets/rashi-discover-sign.png";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CityAutocomplete from "../../components/AutoComplete/CityAutocomplete";
import ariesRashi from "../../assets/horoscopes/aries.png";

import AriesBanner from "../../assets/horoscopes/ariesBanner.png";
import appShareAd from "../../assets/shareapp-img.png";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import { capitalizeWords, formatDate } from "../../utils/HelperFunctions";
import PujaCategoryList from "../../components/PujaCategoryList/PujaCategoryList";

import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HoroscopeCarousel from "./HoroscopeCarousel";
import ShareLink from "../../components/sharelink/sharelink";
import ShareLinkDialog from "../../components/dialog/ShareLinkDialog";


const HoroscopeDetailsView = (props: any) => {
  const horoscopeData = props.horoscopeData;
  const selectedLanguage: any = props.selectedLanguage;
  const selectedSign = props.selectedSign;
  const imageData = props.imageData;
  console.log(props?.selectedItem)
  const nativeViewCheck = props.parentViewCheck

  // For Open Share Modal
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const handleOpenShareDialog = () => {
    setShareDialogOpen(true);
  };

  const handleCloseShareDialog = () => {
    setShareDialogOpen(false);
  };

  return (
    <Box className={`rashiPage ${nativeViewCheck ? 'pageContainer' : 'pageContainerNative'}`}>
      <Box className="rashiBlock">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdLeftBlock"
            ></Grid>
            <Grid item xs={12} md={8} lg={6} xl={6} className="pageCenterBlock">
              <div className="chooseRashi">
                <Box className="rashiMainContent">
                  <Box className="rshiDetails">
                    <Box className="rashiBanner">
                      {/* <img src={imageData?.img} /> */}
                      <HoroscopeCarousel
                        selectedItem={props?.selectedItem}
                        navigateToSelectedSignIndex={
                          props.navigateToSelectedSignIndex
                        }
                      />
                    </Box>
                    <Box className="dayLang">
                      <Box className="daysRashi">
                        <Button
                          className="btnToday"
                          variant={
                            props.isTodaySelected ? "contained" : "outlined"
                          }
                          onClick={() => props.getHoroscopeDataForToDay()}
                        >
                          Today
                        </Button>
                        <Button
                          className="btnToday"
                          variant={
                            props.isTodaySelected ? "outlined" : "contained"
                          }
                          onClick={() => props.getHoroscopeDataForTomorrow()}
                        >
                          Tomorrow
                        </Button>
                      </Box>
                      <Box className="langSelect">
                        <ButtonGroup
                          variant="outlined"
                          aria-label="Basic button group"
                        >
                          <Button
                            variant={
                              props.selectedLanguage === "english"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => props.changeLanguage("english")}
                          >
                            A
                          </Button>
                          <Button
                            variant={
                              props.selectedLanguage === "hindi"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() => props.changeLanguage("hindi")}
                          >
                            अ
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Box>

                    {horoscopeData ? (
                      <Box className="rashiDescription">
                        <Typography variant="h5">
                          {props?.selectedDate
                            ? formatDate(props?.selectedDate)
                            : " "}
                        </Typography>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Daily Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.dailyHoroscope[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )}  Health and Wellness Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.healthAndWellness[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Love and Relationship Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {
                              horoscopeData?.loveAndRelationship[
                              selectedLanguage
                              ]
                            }
                          </Typography>
                        </Box>

                        <Box className="contDetails">
                          <SubtitleTypography
                            title={`${capitalizeWords(
                              selectedSign
                            )} Career and Money Horoscope`}
                          ></SubtitleTypography>
                          <Typography paragraph={true}>
                            {horoscopeData?.careerAndMoney[selectedLanguage]}
                          </Typography>
                        </Box>

                        <Box>
                          <Box className="categoryList">
                            <Box className="fullWidthBorder"></Box>
                            <Typography className="categoryListTitle">
                              Explore Puja Categories
                            </Typography>
                            <Box>
                              <PujaCategoryList
                                isNativeView={!nativeViewCheck}
                              />
                            </Box>
                          </Box>

                          <Box className="shareAppAd">
                            <Box className="fullWidthBorder"></Box>
                            <Box className="shareIcon">
                            
                              <ShareLinkDialog open={shareDialogOpen} onClose={handleCloseShareDialog} url={props?.urlToShare} />
                            </Box>
                              <img src={appShareAd} onClick={e=>setShareDialogOpen(true)} alt="" />
                          </Box>
                          {/* <Box className="shareAppAd">
                            <Box className="fullWidthBorder"></Box>
                            {nativeViewCheck ? (<><Box className="shareIcon">
                            
                              <ShareLinkDialog open={shareDialogOpen} onClose={handleCloseShareDialog} url={props?.urlToShare} />
                            </Box>
                              <img src={appShareAd} onClick={e=>setShareDialogOpen(true)} alt="" />
                              </>
                            ) : (
                              <img src={appShareAd} alt="" style={{ cursor: "pointer" }} onClick={() => props.shareNativeAd()} />

                            )}
                          </Box> */}
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100vh",
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
              lg={3}
              xl={3}
              className="pagehdRightBlock"
            >
              {/* <RightBlockCard /> */}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default HoroscopeDetailsView;

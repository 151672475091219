import pujaMainImg from "../../assets/puja-img.png";
import templeIcon from "../../assets/temple-icon.png";
import locationIcon from "../../assets/loc-icon.png";
import pricetagIcon from "../../assets/price-tag.png";
import viewPujaSteps from "../../assets/viewpujasteps.png";
import viewBless from "../../assets/bless-your-self.png";
import "./PujaInfoView.scss";
import pujaNameIcon from "../../../src/assets/puja-name-icon.png";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Button,
  Dialog,
  Tooltip,
  Snackbar,
  Alert,
  Radio,
  Badge,
} from "@mui/material";
import TitleTypography from "../../components/typography/TitleTypography";
import ParagraphTypography from "../../components/typography/ParagraphTypography";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import SubtitleTypography from "../../components/typography/SubtitleTypography";
import SubtitleTypographyPrimary from "../../components/typography/SubtitleTypographyPrimary";
import RoundedButton from "../../components/button/RoundedButton";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { calculateActualPujaPrice } from "../../utils/HelperFunctions";
import ShareLink from "../../components/sharelink/sharelink";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import packageImg1 from "../../assets/Package-img1.png";
import packageImg2 from "../../assets/Package-img2.png";
import packageImg3 from "../../assets/Package-img3.png";
import packageImg6 from "../../assets/Package-img6.png";
import packageImg8 from "../../assets/Package-img8.png";
import listIcon from "../../assets/list-flower-icon.png";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

import { SyntheticEvent } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { VIRTUAL_PUJA_BOOKINGCAROUSAL_RESPONSIVE } from "../../utils/Constants";
import dayjs from "dayjs";
import PujaPackageCard from "../../components/card/PujaPackage/PujaPackageCard";

function PujaView(props: any) {
  console.log("pujaDetails", props.pujaDetails);
  // console.log("Temple details in puja view selectedTempleDetails", props.selectedTempleDetails);
  const templeName =
    props.selectedTempleDetails &&
    props.selectedTempleDetails.personalInfo &&
    props.selectedTempleDetails.personalInfo.name
      ? props.selectedTempleDetails.personalInfo.name
      : "";
  const personalInfo = props.selectedTempleDetails?.personalInfo;
  const templeAddress =
    (personalInfo?.address?.street ||
      personalInfo?.address?.city ||
      personalInfo?.address?.area) +
    " , " +
    personalInfo?.address?.state;

  const getImageForBenefitsDataByBenefitId = (benefitId: string) => {
    const benefitList = props.benefitsList;
    const benefitFilterData = benefitList.filter(function (item: any) {
      return item.benefitId === benefitId;
    });
    // console.log("New bebefit list", benefitFilterData);
    if (benefitList.length > 0) {
      return benefitFilterData[0];
    } else {
      return null;
    }
  };
  const packageSelected = props.packageSelected;
  const pujaPrice = props?.pujaPriceInfo?.finalPrice;
  // const pujaPrice =
  //   props.packageSelected && props.packageSelected.acctualPrice ? props.packageSelected?.acctualPrice
  //     : props.pujaDetails?.acctualPrice;
  const actualPujaPriceAggregated = props?.pujaPriceInfo?.finalPrice;

  const couponCodeText =
    props.packageSelected?.couponCode || props.pujaDetails?.couponCode || "";

  //pagelink?singlepuja=${pujaDetails.pujaId}&&templeid=${templeDetails?.id}

  const [urlToShare, setUrlToShare] = useState<string>("");
  const templeAndPujaInfoPath = window.location.pathname;
  useEffect(() => {
    const templeIdComing = window.location?.pathname?.split("/")[2];
    const hostname =
      window.location.hostname === "localhost"
        ? "http://localhost:3000"
        : "https://" + window.location.hostname;
    const pujaId = templeAndPujaInfoPath?.split("/")[4];
    setUrlToShare(
      `${hostname}/pagelink?singlepuja=${pujaId}&templeid=${templeIdComing}`
    );

    // }
  }, [props.selectedTempleDetails]);

  const sortPackageData = (multiplePujaDetails: any) => {
    let multiplePuja = [...multiplePujaDetails];
    let data = multiplePuja.sort((a: any, b: any) =>
      Number(a.maxMemberAllowed) > Number(b.maxMemberAllowed) ? 1 : -1
    );

    return data;
  };

  const pujaBgImage =
    props.pujaDetails &&
    props.pujaDetails.pujaBg &&
    props.pujaDetails.pujaBg.uri
      ? props.pujaDetails.pujaBg.uri
      : pujaMainImg;
  let pujaPackages =
    props.pujaDetails && props.pujaDetails.multiplePujaDetails
      ? sortPackageData(props.pujaDetails.multiplePujaDetails)
      : [];

  const [open, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(couponCodeText);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const bookedSingleDatePuja = () => {
    //setLoading(true)

    //props.preprocessingAndProceedToAddmemberScreen()
    if (props.cartInfo && props.cartInfo.length > 0) {
      props.setPopupPujaAlreadyAdded(true);
    } else {
      props.preprocessingAndProceedToAddmemberScreen();
    }
  };
  //Puja Package Radio btn
  const [radioSelectedValue, setRadioSelectedValue] = useState("option2");

  const handleChange = (event: any) => {
    setRadioSelectedValue(event.target.value);
  };

  const getDevoteeImage = (index: any) => {
    switch (index) {
      case "1":
        return packageImg1;
        break;
      case "2":
        return packageImg2;
        break;
      case "4":
        return packageImg3;
        break;
      case "6":
        return packageImg6;
        break;
      case "8":
        return packageImg8;
        break;

      default:
        return packageImg1;
        break;
    }
  };

  const minDate = dayjs().add(2, "day");
  return (
    <Box className="pageContainer pujaPage">
      <Box className="pujaBlock">
        <Grid container spacing={1}>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdLeftBlock">
            {/* <LeftAddressCard
              header={props.TDTexts.CURRENTADDRESS}
              address={
                props.userAddressList && props.userAddressList.length > 0
                  ? props.userAddressList[0].formatted_address
                  : ""
              }
              footerButton="Edit or add an address"
            /> */}
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Box className="pujainfoCenterBlock">
              <Box className="pujaCard">
                {/* <TitleTypography
                  title={`${props.pujaDetails?.pujaName}`}
                ></TitleTypography> */}
                <Box className="pujaCardBlock">
                  <Box className="pujaImgBlock">
                    <img src={pujaBgImage} alt=""></img>
                  </Box>
                  <Box className="pujaDescription">
                    <Box className="pujaNameInfo">
                      <Typography>{props.pujaDetails?.pujaName}</Typography>
                    </Box>
                    <Box className="nameLocPrice">
                      <List>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          className="twoItem"
                        >
                          {/* <ListItem>
                            <ListItemAvatar sx={{ minWidth: "50px" }}>
                              <Avatar
                                alt="Puja Icon"
                                className="squreShape pricetag"
                              >
                                <img src={pujaNameIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ pl: "6px" }} primary={props.pujaDetails?.pujaName} />
                          </ListItem> */}
                          <ListItem>
                            <ListItemAvatar sx={{ minWidth: "46px" }}>
                              <Avatar
                                alt="Temple Icon"
                                className="squreShape temple"
                              >
                                <img src={templeIcon}></img>
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${templeName}`} />
                          </ListItem>
                          <Box className="shareIcon">
                            <ShareLink urlToShare={urlToShare}></ShareLink>
                          </Box>
                        </Stack>

                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Location Icon"
                              className="squreShape location"
                            >
                              <img src={locationIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={`${templeAddress}`} />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar sx={{ minWidth: "46px" }}>
                            <Avatar
                              alt="Pricetag Icon"
                              className="squreShape pricetag"
                            >
                              <img src={pricetagIcon}></img>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            className="pujaPriceInfo"
                            sx={{ flex: "0 0 auto" }}
                            primary={`Price: ₹${actualPujaPriceAggregated}`}
                          />
                          {couponCodeText ? (
                            <ListItemText
                              sx={{ flex: "0 0 auto" }}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick()}
                            >
                              <Typography className="couponDiscountCode">
                                <PercentRoundedIcon />
                                {couponCodeText}
                              </Typography>
                            </ListItemText>
                          ) : null}
                        </ListItem>
                      </List>
                      <Button
                        type="button"
                        className="continueBtn desktopOnly"
                        onClick={() => {
                          if (props?.isSingleDatePuja) {
                            bookedSingleDatePuja();
                          } else {
                            props.setIsDateTimePicker(true);
                          }
                          //  props.setIsDateTimePicker(true);
                          // props.navigateToPujaDetailsAdditionScreen()
                        }}
                      >
                        Continue to Book
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="overViewText">
                <SubtitleTypographyPrimary title="Puja Overview"></SubtitleTypographyPrimary>
                <ParagraphTypography
                  title={`${props.pujaDetails?.description}`}
                ></ParagraphTypography>
              </Box>

              <Box className="templeDekhoVideo">
                <SubtitleTypographyPrimary title="About “Temple Dekho”"></SubtitleTypographyPrimary>
                <Typography>
                  Please watch our feature video to discover the capabilities of
                  the <span>Temple Dekho</span> app.
                </Typography>
                <Button
                  onClick={() => props.navigateToSeeVideoView()}
                  variant="contained"
                  className="seeVideoBtn"
                >
                  See Video
                </Button>
              </Box>

              {packageSelected ? (
                <Box className="SelectPackage">
                  <SubtitleTypographyPrimary title="Select Puja Package"></SubtitleTypographyPrimary>
                  <Box className="packageItem">
                    <Carousel
                      showArrows={false}
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop={false}
                      centerMode={true}
                      showIndicators={false}
                      centerSlidePercentage={33.33}
                    >
                      {pujaPackages.map((item: any, index: number) => {
                        let activeFlg = "inactive";
                        let pujaPrice = calculateActualPujaPrice(
                          Number(item.acctualPrice)
                        );
                        if (
                          packageSelected &&
                          packageSelected.acctualPrice === item.acctualPrice
                        ) {
                          activeFlg = "active";
                        }

                        return (
                          <PujaPackageCard
                            setPackageSelected={props.setPackageSelected}
                            getDevoteeImage={getDevoteeImage}
                            activeFlg={activeFlg}
                            packageDetails={item}
                            selectedTempleDetails={props.selectedTempleDetails}
                            pujaDetails={props.pujaDetails}
                          />
                        );
                      })}
                    </Carousel>
                  </Box>
                  <Box className="packagePujaInfo">
                    <Stack
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      className="packagePujaTitle"
                    >
                      <img
                        src={getDevoteeImage(packageSelected?.maxMemberAllowed)}
                        alt=""
                      />
                      <Box>
                        <Typography variant="h6">
                          {props.pujaDetails?.pujaName}
                        </Typography>
                        <Typography component="p">
                          Participants: Upto {packageSelected?.maxMemberAllowed}{" "}
                          Devotees
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              ) : null}

              <Box className="pujaBenefits">
                <SubtitleTypographyPrimary title="Benefits of Puja"></SubtitleTypographyPrimary>
                {props.pujaDetails &&
                  props.pujaDetails.benefitList &&
                  props.pujaDetails.benefitList.map((item: any, id: any) => {
                    let updatedBenefitsData = item;
                    // console.log("Benefir detais from map function", item);
                    const benefitDetais = getImageForBenefitsDataByBenefitId(
                      item.benefitId
                    );

                    if (benefitDetais) {
                      updatedBenefitsData = benefitDetais;
                    }
                    return (
                      <Box className="benefitsCard">
                        <SubtitleTypography
                          title={`${updatedBenefitsData.name}`}
                        ></SubtitleTypography>
                        <Box className="benefitCardImg">
                          <img src={`${updatedBenefitsData.url}`} alt=""></img>
                        </Box>
                        <ParagraphTypography
                          title={`${updatedBenefitsData.description}`}
                        ></ParagraphTypography>
                      </Box>
                    );
                  })}
              </Box>

              <Box className="pujaProccessSteps">
                <SubtitleTypographyPrimary title="Puja Process Steps"></SubtitleTypographyPrimary>
                <List sx={{ listStyle: "decimal", pl: "22px" }}>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Select and add puja" />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Select date" />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Enter Name & Gotra" />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Make payment" />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Skilled pandits will conduct the Puja, and notifications will be sent to your WhatsApp number with puja video or picture." />
                  </ListItem>
                  <ListItem disablePadding sx={{ display: "list-item" }}>
                    <ListItemText primary="Prasad will be delivered within 7-8 working days of the puja." />
                  </ListItem>
                </List>
              </Box>
              <Box
                onClick={() => props.navigateToPujaStepsView()}
                className="viewPujaSteps"
              >
                <img src={viewPujaSteps}></img>
              </Box>
              <Box className="blessContinue">
                <Box className="viewBless">
                  <img src={viewBless}></img>
                </Box>
                <Box className="continueCart">
                  <Box className="continueCenterBlock">
                    <Box className="continueLt">
                      ₹{`${actualPujaPriceAggregated}`}/-
                    </Box>
                    <Box className="continueRt">
                      <Button
                        type="button"
                        className="continueBtn"
                        onClick={() => {
                          if (props?.isSingleDatePuja) {
                            bookedSingleDatePuja();
                          } else {
                            props.setIsDateTimePicker(true);
                          }

                          // props.navigateToPujaDetailsAdditionScreen()
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={2} lg={3} xl={3} className="pagehdRightBlock">
            {/* <RightBlockCard /> */}
          </Grid>
        </Grid>
        <Dialog
          className="dateTimingSelection"
          onClose={() => props.setIsDateTimePicker(false)}
          open={props.isDateTimePicker}
        >
          <Box className="dateTimePopup">
            <TitleTypography title="Select Date">Select Date</TitleTypography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="flex-start"
              className="pujaReminderSec"
            >
              <ErrorOutlineOutlinedIcon />
              <Typography className="pujaReminderText">
                The Booking for the puja will be closed two days before the
                scheduled date.{" "}
              </Typography>
            </Stack>

            <Box className="calendarInfo">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  shouldDisableDate={props?.shouldDisableDate}
                  disablePast={true}
                  minDate={minDate}
                  onChange={(value: any) => {
                    props.setPujaDateAndTime({
                      ...props.pujaDateAndTime,
                      pujaDate: value,
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box className="btnContinue">
              <RoundedButton
                title="Continue"
                disabled={
                  props.pujaDateAndTime && props.pujaDateAndTime.pujaDate
                    ? false
                    : true
                }
                onClick={() => {
                  props.setIsDateTimePicker(false);
                  if (props.cartInfo && props.cartInfo.length > 0) {
                    props.setPopupPujaAlreadyAdded(true);
                  } else {
                    props.preprocessingAndProceedToAddmemberScreen();
                  }

                  //
                }}
              ></RoundedButton>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          className="dateTimingSelection"
          onClose={() => props.setPopupPujaAlreadyAdded(false)}
          open={props.popupPujaAlreadyAdded}
        >
          <Box style={{ padding: "1em" }}>
            <Typography>
              A puja has already been added to your cart. Would you like to
              replace the puja with selected puja ?
            </Typography>
            <Box sx={{ textAlign: "end" }}>
              <Button onClick={() => props.setPopupPujaAlreadyAdded(false)}>
                No
              </Button>
              <Button
                onClick={() => props.preprocessingAndProceedToAddmemberScreen()}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Dialog>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Text copied to clipboard"
      />
    </Box>
  );
}

export default PujaView;

import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import ParentView from "../../Views/PrarentView/ParentView";
import { Box, Button, Grid } from "@mui/material";
import AdminLayoutView from "../../Views/Admin/AdminLayoutView";
function AdminLayoutScreen() {
  const navigate = useNavigate();
  return (
    <ParentView>
      <AdminLayoutView />
    </ParentView>
  );
}

export default AdminLayoutScreen;

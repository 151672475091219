import { useEffect, useState } from "react";
import CartView from "../../../../Views/Checkout/Views/Cart/CartView";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { env } from "../../../../env";
import {
  uid,
  getDateToday,
  getFormattedDate,
  getDateNowInUtc,
  generateTransactionId,
  generateUniqueNumberString,
  createPaymentData,
  PRICES,
  calculateAdminCommission,
  calculatePlatformFee,
  calculateGSTPrice,
  calculatePujaPaymentPrice,
  calculateActualPujaPrice,
} from "../../../../utils/HelperFunctions";
import { OrderService, CommonService, CouponService } from "td_server";
import Razorpay from "razorpay";
import {
  setPujaMembers,
  setSelectedPuja,
  setSelectedTempleDetailsForPuja,
} from "../../../../store/slices/pujaSlice";
import CartCard from "../../../../components/card/Cart/CartCard";
import {
  emptyCartAction,
  getPriceOfPuja,
  updateCartAction,
} from "../../../../store/actions/cartAction";
import { setSelectedAddressInCart } from "../../../../store/slices/cartSlice";
import axios, { AxiosResponse } from "axios";
import { placeOrderAction } from "../../../../store/actions/orderAction";
import { Box, Button, Dialog, Typography } from "@mui/material";
import successIcon from "../../../../../src/assets/success.png";
import errorIcon from "../../../../../src/assets/error.png";
import "../../../../Views/Checkout/Views/Cart/CartView.scss";
import CartNoItem from "../../../../components/card/Cart/NoItemInCart";
import { updateCouponsUsageAction } from "../../../../store/actions/CouponCodeAction";
import templeLogo from "../../../../assets/temple-logo-icon.png";
import { addAnalytics } from "../../../../store/actions/AnalyticsAction";
function CheckoutScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [chooseAddressBox, setChooseAddressBox] = useState(false);
  const [orderCreatedScreen, setOrderCreatedScreen] = useState(false);
  const [orderResultDetails, setOrderResultDetails] = useState("");
  const [errorPopup, setErrorPopUp]: any = useState({
    isError: false,
    errorMessages: [],
  });
  const [bookingFailedPopup, setBookingFailedPopUp]: any = useState(false);
  const [couponPopup, setCouponPopUp]: any = useState(false);
  const [selectedCartItem, setSelectedCartItem]: any = useState(null);
  // const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [couponApplyResponse, setCouponApplyResponse] = useState({
    isValid: false,
    isApplied: false,
    finalPrice: 0,
    couponData: null,
    isError: false,
    errorDetails: "",
    actualPujaPrice: 0,
    discount: 0,
    gst: 0,
    priceBreakup: {},
  });
  const [couponCodeData, setCouponCodeData]: any = useState("");
  const [priceBreakupData, setPriceBreakupData]: any = useState({
    actualPujaPrice: 0,
    gst: 0,
    finalPrice: 0,
    priceBreakup: null,
  });
  const [priceInfoWithoutCoupon,setPriceInfoWithoutCoupon] = useState(null)
  const { selectedPuja, selectedTempleDetailsForPuja, pujaMembers } =
    useSelector((state: any) => state.puja);
  const { cartInfo, selectedAddressInCart } = useSelector(
    (state: any) => state.cart
  );
  var { userInfo } = useSelector((state: any) => state.user);

  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : {};
  const pujaDetails = selectedCartItem ? selectedCartItem.pujaDetails : {};
  const templeDetails = selectedCartItem ? selectedCartItem.templeDetails : {};
  const pujaPackage = selectedCartItem ? selectedCartItem.pujaPackage : {};
  const memberDetails = selectedCartItem ? selectedCartItem.members : [];
  const windowRefrence: any = window;
  useEffect(() => {
    //console.log(window);

    if (env === "prod") {
      windowRefrence?.fbq("track", "AddToCart");
    }

    setSelectedCartItem(cartInfo[0]);
    if (userPersonalInfo.address && userPersonalInfo.address.length > 0) {
      dispatch(setSelectedAddressInCart(userPersonalInfo.address[0]));
    }
  }, [cartInfo]);

  useEffect(() => {
    // console.log("Running usee setPrice again", couponApplyResponse);
    if (couponApplyResponse.isApplied) {
      console.log("Inside coupon is applied");
      setPriceQuoteForPujaWithCouponData(couponApplyResponse);
    } else {
      setPriceQuoteForPuja();
    }
  }, [couponApplyResponse, selectedCartItem]);

  const setPriceQuoteForPuja = async () => {
    console.log("Templ puja", templeDetails, pujaDetails, pujaPackage);
    let payload = {
      templeId: templeDetails?.id,
      pujaId: pujaDetails?.pujaId,
      packageId: pujaPackage?.packageId,
    };
    console.log("Payload", payload);
    const pujaPriceData = await OrderService.getPriceDetailsOfPuja(payload);

    console.log("Fetch function screen price res", pujaPriceData);

    setPriceBreakupData({
      ...pujaPriceData,
    });
    setPriceInfoWithoutCoupon(pujaPriceData)
  };

  const setPriceQuoteForPujaWithCouponData = (couponData: any) => {
    console.log("Coupon daata after apply click", couponData);
    setPriceBreakupData({
      ...priceBreakupData,
      discount: couponData.discount,
      gst: couponData.gst,
      finalPrice: couponData.finalPrice,
      actualGst:couponData.actualGst,
      actualPujaPrice:couponData.actualPujaPrice

    });
  };
  console.log("Price data", priceBreakupData);
  const changeDeliverytAddress = (address: any) => {
    dispatch(setSelectedAddressInCart(address));
  };

  const editPujaOptions = (selectedCartItem: any) => {
    console.log(`editPujaOptions`, selectedCartItem);
    dispatch(setSelectedTempleDetailsForPuja(selectedCartItem.templeDetails));
    dispatch(setSelectedPuja(selectedCartItem.pujaDetails));
    dispatch(setPujaMembers(selectedCartItem.members));
    navigate("/checkout/puja-details");
  };

  const clearCart = () => {
    dispatch(emptyCartAction());
    navigate("/temples");
  };

  const checkForAnyFormError = () => {
    let isErrorInForm = false;
    let errorMsg = [];
    if (!selectedAddressInCart) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "addressNotGiven",
        message: "Address not provided",
      });
    }
    if (!memberDetails || memberDetails.length === 0) {
      isErrorInForm = true;
      errorMsg.push({
        errType: "memberNotSelected",
        message: "No members added for puja please add atleast 1 member",
      });
    }

    //conditions completes
    if (isErrorInForm) {
      setErrorPopUp({ isError: true, errorMessages: errorMsg });
      return false;
    }
    return true;
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const placeOrder = async (e: any) => {
    e.preventDefault();
    const res = await loadRazorpayScript();

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    //check for error
    const isFormValid = checkForAnyFormError();
    //error checking complete

    if (isFormValid) {
      // let basePrice = priceDetails.basePrice;
      let orderValue = priceBreakupData?.finalPrice;
      const _basePrice = priceBreakupData?.priceBreakup?.basePrice;
      const priceBreakup = {
        basic: _basePrice,
        adminCommission: priceBreakupData?.priceBreakup?.adminCommission,
        delivery: priceBreakupData?.priceBreakup?.delivery,
        packaging: priceBreakupData?.priceBreakup?.packaging,
        goodies: priceBreakupData?.priceBreakup?.goodies,
        platformFee: priceBreakupData?.priceBreakup?.platformFee,
        gst: priceBreakupData.gst,
      };

      const transectionId = generateTransactionId();
      const orderId = `PO${generateUniqueNumberString()}`;
      const orderData = {
        amount: Number(Number(orderValue) * 100).toString(),
        currency: "INR",
        receipt: "receipt#1",
        notes: {
          key1: orderId,
          key2: "value2",
        },
      };

      try {
        const rpOrderIdResponse = await CommonService.generateRazorPayOrderId(
          orderData
        );

        var rpOrderId = rpOrderIdResponse?.id;

        const rpSecreats = await CommonService.getRazorPayCredentials();
        const rpCredentials = rpSecreats?.credentials;
        // console.log("Razor pay secreats",rpSecreats.credentials);
        if (rpOrderId && rpCredentials) {
          // const oderId = rpOrderId;
          const options = {
            key: rpCredentials.KEY,
            key_secreat: rpCredentials.SECRET,
            order_id: rpOrderId,
            amount: Number(Number(orderValue) * 100).toString(),
            name: "Temple Dekho",
            description: "Test Transaction",
            image: templeLogo,
            handler: function (response: any) {
              if (
                response.razorpay_payment_id &&
                response.razorpay_payment_id.length > 0
              ) {
                console.log("priceBreakup", priceBreakup);

                dispatch(
                  placeOrderAction(
                    cartInfo,
                    userInfo,
                    selectedAddressInCart,
                    orderValue,
                    transectionId,
                    orderId,
                    response.razorpay_payment_id,
                    priceBreakup
                  )
                );
                if (couponApplyResponse.isApplied) {
                  const couponData: any = couponApplyResponse.couponData;
                  const userPhNo = userInfo.phoneNumber;
                  dispatch(
                    updateCouponsUsageAction(couponData.couponCode, userPhNo)
                  );
                }

                // setOrderResultDetails(orderId);
                // setOrderCreatedScreen(true);
                navigate("/success", {
                  state: {
                    type: "payment",
                    title: "Payment Successful!",
                    message: `Thank you! Your payment of Rs.${orderValue}  has been received.`,
                    orderValue,
                    orderId,
                    transectionId,
                    priceBreakup,
                    razorpay_payment_id: response.razorpay_payment_id,
                  },
                });
              } else {
                //setBookingFailedPopUp(true);
                navigate("/error", {
                  state: {
                    message: `Sorry for inconvenience. Error happened while booking the Puja.Please try again after sometime`,
                  },
                });
              }
            },
            prefill: {
              name:
                userPersonalInfo.firstName + " " + userPersonalInfo.lastName,
              email: userPersonalInfo.email,
              contact: userInfo.phoneNumber,
            },
            notes: {
              address: "TempleDekho Office",
            },
            theme: {
              color: "#F37254",
            },
          };

          var pay = new (window as any).Razorpay(options);
          pay.open();
        }
      } catch (error) {
        console.log("Error:", error);
        setBookingFailedPopUp(true);
      }

      return true;
    }
  };

  const checkValidityOfcoupon = async () => {
    console.log("Check coupon func called");
    if (couponCodeData) {
      try {
        let payload = {
          couponCode: couponCodeData,
          userPhoneNo: userInfo?.phoneNumber,
          templeId: templeDetails?.id,
          pujaId: pujaDetails?.pujaId,
          packageId: pujaPackage?.packageId,
        };
        const couponValRes2 = await CouponService.verifyCouponAndGetPrice(
          payload
        );
        console.log("Coupon v2 response", couponValRes2);

        if (couponValRes2.isError === false && couponValRes2.finalPrice) {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: true,
            isError: false,
            actualPujaPrice: couponValRes2.actualPujaPrice,
            finalPrice: couponValRes2.finalPrice,
            couponData: couponValRes2.couponData,
            discount: couponValRes2.discount,
            gst: couponValRes2.gst,
            priceBreakup: couponValRes2.priceBreakup,
          });
        } else {
          setCouponApplyResponse({
            ...couponApplyResponse,
            isValid: false,
            isApplied: false,
            isError: true,
            errorDetails: "Invalid coupon applied",
          });
        }
      } catch (err: any) {
        setCouponApplyResponse({
          ...couponApplyResponse,
          isApplied: false,
          isError: true,
          errorDetails: "Invalid coupon applied",
        });
      }
    }
  };

  useEffect(() => {}, []);

  const navigateToAddressScreen = () => {
    navigate("/profile/address");
  };

  const navigateToAddMembersScreen = () => {
    navigate("/checkout/puja-details");
  };

  const navigateToLoginScreen = () => {
    // set the redirect uri
    localStorage.setItem("redirectUri", "/checkout/cart");
    navigate("/login");
  };

  console.log("Coupon code data", couponCodeData);

  useEffect(() => {
    if (cartInfo?.length > 0 && cartInfo[0]?.whatsAppNumber) {
      let analyticsData: any = {
        dateUTC: getDateNowInUtc(),
        key: "puja_cart_view_opened",
        eventData: {
          applicationType: "Web",
          userName: "",
        },
        count: 0,
      };

      analyticsData = { ...analyticsData, cartInfo: cartInfo };
      analyticsData["eventData"]["whatsAppNumber"] = cartInfo[0]?.whatsAppNumber;

      let user: any = localStorage.getItem("userInfo");
      let userData = JSON.parse(user);
      if (userData) {
        analyticsData.eventData.userName = userData?.personalInfo?.firstName;
        analyticsData["eventData"]["userId"] = userData?.id;
        analyticsData["eventData"]["phoneNumber"] = userData?.phoneNumber;
      } else {
        analyticsData.eventData.userName = "Guest User";
        analyticsData["eventData"]["phoneNumber"] =  cartInfo[0]?.whatsAppNumber;
      }
     
      dispatch(addAnalytics(analyticsData))
        .then((res: any) => {
          console.log("Result", res);
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
    }

    return () => {};
  }, [cartInfo]);

  useEffect(() => {
    if (selectedCartItem && selectedCartItem?.members.length === 0) {
      navigateToAddMembersScreen();
    }
  }, [selectedCartItem]);

  return (
    <div>
      {cartInfo && cartInfo.length > 0 ? (
        <CartView
          userDetails={userInfo}
          userPersonalInfo={userPersonalInfo}
          priceBreakup={priceBreakupData}
          pujaDetails={pujaDetails}
          templeDetails={templeDetails}
          memberDetails={memberDetails}
          selectedAddressDetails={selectedAddressInCart}
          chooseAddressBox={chooseAddressBox}
          setChooseAddressBox={setChooseAddressBox}
          changeDeliverytAddress={changeDeliverytAddress}
          editPujaOptions={() =>
            editPujaOptions(selectedCartItem ? selectedCartItem : cartInfo[0])
          }
          placeOrder={placeOrder}
          setOrderCreatedScreen={setOrderCreatedScreen}
          orderCreatedScreen={orderCreatedScreen}
          orderResultDetails={orderResultDetails}
          cartDetails={selectedCartItem ? selectedCartItem : cartInfo[0]}
          clearCart={clearCart}
          errorPopup={errorPopup}
          setErrorPopUp={setErrorPopUp}
          bookingFailedPopup={bookingFailedPopup}
          setBookingFailedPopUp={setBookingFailedPopUp}
          couponPopup={couponPopup}
          setCouponPopUp={setCouponPopUp}
          setCouponCodeData={setCouponCodeData}
          couponCodeData={couponCodeData}
          checkValidityOfcoupon={checkValidityOfcoupon}
          couponApplyResponse={couponApplyResponse}
          setCouponApplyResponse={setCouponApplyResponse}
          navigateToAddressScreen={navigateToAddressScreen}
          navigateToAddMembersScreen={navigateToAddMembersScreen}
          navigateToLoginScreen={navigateToLoginScreen}
          setPriceQuoteForPuja={setPriceQuoteForPuja}
          priceInfoWithoutCoupon={priceInfoWithoutCoupon}
        />
      ) : (
        <CartNoItem />
      )}
      <Dialog
        className="OrderResult"
        onClose={() => {
          setOrderCreatedScreen(false);
          navigate("/");
        }}
        open={orderCreatedScreen}
      >
        <Box className="successCard">
          <Box className="successIcon">
            <img src={successIcon} alt="" />
          </Box>
          <Typography mt="30px">Your order Created Successfully</Typography>
          <Typography>Order Id : {orderResultDetails}</Typography>
          <Button
            variant="outlined"
            onClick={() => {
              setOrderCreatedScreen(false);
              navigate("/");
            }}
          >
            Ok
          </Button>
        </Box>
      </Dialog>
      <Dialog
        className="errorMsg"
        onClose={() => setBookingFailedPopUp(false)}
        open={bookingFailedPopup}
      >
        <Box className="errorCard">
          <Box className="errorIcon">
            <img src={errorIcon} alt="" />
          </Box>
          <Typography>
            Sorry for inconvenience. Error happened while booking the Puja.
            Please try again after sometime !
          </Typography>
          <Button onClick={() => setBookingFailedPopUp(false)}>Ok</Button>
        </Box>
      </Dialog>
    </div>
  );
}

export default CheckoutScreen;

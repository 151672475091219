import PujaDetailsView from "../../../../Views/Checkout/Views/PujaDetails/PujaDetailsView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  uid,
  getDateToday,
  getFormattedDate,
  getDateNowInUtc,
} from "../../../../utils/HelperFunctions";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { setPujaMembers } from "../../../../store/slices/pujaSlice";
import { updateCartAction } from "../../../../store/actions/cartAction";
import CartNoItem from "../../../../components/card/Cart/NoItemInCart";
function PujaDetailsScreen() {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const { selectedPuja, selectedTempleDetailsForPuja, pujaMembers } =
    useSelector((state: any) => state.puja);

  const { cartInfo } = useSelector((state: any) => state.cart);
  var { userInfo } = useSelector((state: any) => state.user);
  const userPersonalInfo =
    userInfo && userInfo.personalInfo ? userInfo.personalInfo : null;
  const singleCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;

  // const templeAndPujaDetails = useSelector((state: any) => state.TempleSlice);
  useEffect(() => {
  setWhatsAppNumber(singleCartData?.whatsAppNumber)
  }, [singleCartData])
  const [isDateTimePicker, setIsDateTimePicker] = useState(false);
  const [pujaDateAndTime, setPujaDateAndTime] = useState({
    pujaDate: dayjs(new Date()),
    pujaTime: "10AM",
  });
  const [availableTimings, setAvailableTimings]: any = useState([
    { id: 1, time: "9:00AM", isSelected: true },
    { id: 2, time: "10:00AM", isSelected: false },
    { id: 3, time: "2:00PM", isSelected: false },
  ]);
  const currentPujaDetails = selectedPuja ? selectedPuja : {};
  const currentTempleDetails = selectedTempleDetailsForPuja
    ? selectedTempleDetailsForPuja
    : {};
  // var memberDetails: any = pujaMembers ? pujaMembers : [];
  const [isAddEditMemberScreen, setIsAddEditMemberScreen] = useState(true);
  const [
    memberDetailsGettingAddedOrEdited,
    setMemberDetailsGettingAddedOrEdited,
  ]: any = useState({ id: uid() });

  const [editFlag, setEditFlag] = useState(false);
 const [whatsAppNumber,setWhatsAppNumber] = useState('');
 const [cartAnalyticsData,setCartAnalyticsData] =  useState<any>({})
  // console.log("user details from puja book", userDetails);

  // const autoSetMembersForPujaIfNotChoosen = () => {
  //   if (userPersonalInfo) {
  //     var defaultUserDetails = {
  //       id: uid(),
  //       fullName: userPersonalInfo.firstName + " " + userPersonalInfo.lastName,
  //       dateOfBirth: userPersonalInfo.dateOfBirth,
  //       phoneNumber: "",
  //       gender: userPersonalInfo.gender,
  //       gotram: "",
  //     };
  //     // dispatch(setPujaMembers([defaultUserDetails]));
  //     let tempcartData = { ...singleCartData, members: [defaultUserDetails] };
  //     let tempCartArray = [tempcartData];
  //     dispatch(updateCartAction(tempCartArray));
  //   }
  // };

  useEffect(() => {
    let oneCartData = cartInfo && cartInfo.length > 0 ? cartInfo[0] : null;
    const maxMemberAllowed = Number(
      oneCartData?.pujaPackage?.maxMemberAllowed|| oneCartData?.pujaDetails?.maxMemberAllowed
    );
    const currentMembersLength =
      oneCartData && oneCartData.members && oneCartData.members.length
        ? oneCartData.members.length
        : 0;
    // console.log("Added members",currentMembersLength,"allowed members",maxMemberAllowed)
    if (currentMembersLength === maxMemberAllowed) {
      setTimeout(() => {
        setIsAddEditMemberScreen(false);
      }, 500);
      
    } else {
      setIsAddEditMemberScreen(true);
    }
  }, [cartInfo]);

  const addOrEditMemberSubmit = () => {
     
       
     let  newTempcartData = { ...singleCartData, whatsAppNumber };
     
     

    var memberDetails =
      cartInfo && cartInfo.length > 0 && cartInfo[0].members
        ? cartInfo[0].members
        : [];

        

    if (memberDetailsGettingAddedOrEdited.id && memberDetailsGettingAddedOrEdited.fullName) {
      //old member and getting edited
      if (editFlag) {
         //console.log('Hi Enter one')
        if (memberDetails.length === 1) {
          memberDetails = [memberDetailsGettingAddedOrEdited];
        } else {
          var withoutMember = memberDetails.filter((item: any) => {
            return item.id !== memberDetailsGettingAddedOrEdited.id;
          });
          memberDetails = withoutMember;
          memberDetails.push(memberDetailsGettingAddedOrEdited);
        }
        dispatch(setPujaMembers(memberDetails));
        let tempcartData = { ...newTempcartData, members: memberDetails };
        let tempCartArray = [tempcartData];
        dispatch(updateCartAction(tempCartArray));
      } else {
        //console.log('Hi Enter two')
        let addedMemberList = [
          ...memberDetails,
          memberDetailsGettingAddedOrEdited,
        ];
        // memberDetails.push(memberDetailsGettingAddedOrEdited);
        dispatch(setPujaMembers(addedMemberList));
        let tempcartData = { ...newTempcartData, members: addedMemberList };
        let tempCartArray = [tempcartData];
        dispatch(updateCartAction(tempCartArray));
      }

      // dispatch(setPujaMembers(memberDetails));
    
    
    }else{
      dispatch(updateCartAction([newTempcartData]));
    }
    // setIsAddEditMemberScreen(false);
    setMemberDetailsGettingAddedOrEdited({ id: uid() });
  };

  const deleteMemberFromPujaList = (idMember: any) => {
    var memberDetails =
      cartInfo && cartInfo.length > 0 && cartInfo[0].members
        ? cartInfo[0].members
        : [];
    var tempMemberDetails = [...memberDetails];
    for (let i = 0; i < tempMemberDetails.length; i++) {
      if (tempMemberDetails[i].id === idMember) {
        tempMemberDetails.splice(i, 1);
      }
    }
    const cartPayload = {
      ...singleCartData,
      members: tempMemberDetails,
    };
    dispatch(setPujaMembers(tempMemberDetails));
    let tempCartArray = [];
    tempCartArray.push(cartPayload);
    dispatch(updateCartAction(tempCartArray));
  };

  const proceedToCart = () => {
    let formattedDateTime = {
      date: getFormattedDate(new Date(pujaDateAndTime.pujaDate.toString())),
      time: pujaDateAndTime.pujaTime,
    };
    // dispatch(setPujaOrderDateTime(formattedDateTime));
   
    

    var memberDetails =
      cartInfo && cartInfo.length > 0 && cartInfo[0].members
        ? cartInfo[0].members
        : [];
    let cartPayload = { ...singleCartData, members: memberDetails };

    //let newCartData = [...cartInfo,cartDataPayload]
    let newCartData = [];
    newCartData.push(cartPayload);
    dispatch(updateCartAction(newCartData));
    navigate("/checkout/cart");
  };


  
  
  
 
  
  return (
    <div>
      {cartInfo && cartInfo.length > 0 ? (
        <PujaDetailsView
          cartInfo={cartInfo}
          isDateTimePicker={isDateTimePicker}
          setIsDateTimePicker={setIsDateTimePicker}
          pujaDateAndTime={pujaDateAndTime}
          setPujaDateAndTime={setPujaDateAndTime}
          setAvailableTimings={setAvailableTimings}
          availableTimings={availableTimings}
          pujaDetails={currentPujaDetails}
          templeDetails={currentTempleDetails}
          memberDetails={
            cartInfo && cartInfo.length > 0 && cartInfo[0].members
              ? cartInfo[0].members
              : []
          }
          memberDetailsGettingAddedOrEdited={memberDetailsGettingAddedOrEdited}
          setMemberDetailsGettingAddedOrEdited={
            setMemberDetailsGettingAddedOrEdited
          }
          editFlag={editFlag}
          setEditFlag={setEditFlag}
          addOrEditMemberSubmit={addOrEditMemberSubmit}
          deleteMemberFromPujaList={deleteMemberFromPujaList}
          isAddEditMemberScreen={isAddEditMemberScreen}
          setIsAddEditMemberScreen={setIsAddEditMemberScreen}
          proceedToCart={proceedToCart}
          whatsAppNumber={whatsAppNumber}
          setWhatsAppNumber={setWhatsAppNumber}
        />
      ) : (
        <CartNoItem />
      )}
    </div>
  );
}

export default PujaDetailsScreen;

const HindiTextConstants = {
    PUJA:"पूजा",
    PUJAS:"पूजा",
    TEMPLES:"मंदिरों",
    CURRENTADDRESS:"घर",
    EDITORADDADDRESS:"पता संपादित करें या जोड़ें",
    PLEASEENTERMOBILENO:"लॉगिन या साइनअप करने के लिए कृपया अपना मोबाइल नंबर दर्ज करें",
    UPCOMINGPUJAS:"आगामी पूजा",
    SPIRITUALINFORMATION:"आध्यात्मिक जानकारी",
    MOSTPOPULARTEMPLES:"लोकप्रिय मंदिर"
}
export default HindiTextConstants ;
import { useEffect, useState } from "react";
import PersonalInfoView from "../../../../Views/Profile/Views/PersonalInfo/PersonalInfoView";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../../../../store/actions/userAction";
function PersonalInfoScreen() {
  const { userInfo } = useSelector((state: any) => state.user);
  const userPersonalInfoFromState  = userInfo&&userInfo.personalInfo?userInfo.personalInfo:{}
  const dispatch: any = useDispatch();
  const [personalInfoEditMode, setPersonalInfoEditMode] = useState(false);
  const [userPersonalInfo, setUserPersonalInfo] :any = useState({});

  const phoneNumberNonEditable =userInfo?.phoneNumber;
  //const userId =userInfo?.id
        
  useEffect(() => {
    if (userPersonalInfoFromState) {
      setUserPersonalInfo(userPersonalInfoFromState);
    }
  }, []);

  const cancelUpdate = ()=>{
    if (userPersonalInfoFromState) {
      setUserPersonalInfo(userPersonalInfoFromState);
    }
    setPersonalInfoEditMode(false)
  }

  const submitUpdate = ()=>{
    const updatedUserDetailsPayload = {
      firstName:userPersonalInfo.firstName,
      lastName:userPersonalInfo.lastName,
      email:userPersonalInfo.email,
      dateOfBirth:userPersonalInfo.dateOfBirth,
    }
    console.log("profile update payload",updatedUserDetailsPayload)
    dispatch(updateProfileAction(updatedUserDetailsPayload))
    setPersonalInfoEditMode(false)
    // UserService.updateUserProfile(updatedUserDetailsPayload,userId)
    // .then((res)=>{
    //   console.log("Update profile info",res)
    //   dispatch(fetchUserDetails({ mobileNo: userMobileNumber }))
    //   setPersonalInfoEditMode(false)

    // })
    // .catch((err:any)=>{
    //   console.log("Error while updating profile",err)
    // })
  }
  
  return (
    <div>
      <PersonalInfoView
        userDetails={userInfo}
        userPersonalInfoFromState={userPersonalInfoFromState}
        userPersonalInfo={userPersonalInfo}
        setUserPersonalInfo={setUserPersonalInfo}
        setPersonalInfoEditMode={setPersonalInfoEditMode}
        personalInfoEditMode={personalInfoEditMode}
        phoneNumberNonEditable={phoneNumberNonEditable}
        cancelUpdate={cancelUpdate}
        submitUpdate={submitUpdate}
      />
    </div>
  );
}

export default PersonalInfoScreen;

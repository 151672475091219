import React from 'react';
import { Container, Typography, Link, Box, Grid } from '@mui/material';
import './PujaVideoView.scss';

const PujaVideoView = () => {
    return (
        // <Box className="pageContainer">
        //     Puja Video
        // </Box>

        <Box className="pageContainer pujaVideo">
            <Box className="templeBlock">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdLeftBlock"></Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                        <Box className="pujaVideoArea">
                            <Typography className="pujaVideoTitle">Product Video</Typography>
                            <Box className="liveDarshanView">
                                <iframe width="100%" height="400" src="https://www.youtube.com/embed/FJ-Yaz6_d68?si=xT31G4ypN0MPIxHU" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} xl={3} className="pagehdRightBlock"></Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PujaVideoView;

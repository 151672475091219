const EnglishTextConstants = {
    PUJA:"Puja",
    PUJAS:"Pujas",
    TEMPLES:"Temples",
    CURRENTADDRESS:"Home",
    EDITORADDADDRESS:"198/3, 3 Cross 80 Feet Road, Next..",
    PLEASEENTERMOBILENO:"Please enter your mobile number to login or signup",
    UPCOMINGPUJAS:"UPCOMING PUJAS",
    SPIRITUALINFORMATION:"SPIRITUAL INFORMATION",
    MOSTPOPULARTEMPLES:"MOST POPULAR TEMPLES",

}
export default EnglishTextConstants ;
import DonationView from "../../Views/Donation/DonationView";
import ParentView from "../../Views/PrarentView/ParentView";
function DonationScreen() {
  return (
    <ParentView>
      <DonationView />
    </ParentView>
  );
}

export default DonationScreen;

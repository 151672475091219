import { useState } from "react";
import footerLogo from "../../assets/footerlogo.png";
// import "./Footer.scss";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "300px",
};

function GoogleMapPage(props: any) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDAx2xJ9TEpGOR0MwOpNeZ3CpnLJPYAHyU",
  });

  const center =
    props.personalInfo &&
    props.personalInfo.address &&
    props.personalInfo.address.coordinates
      ? props.personalInfo.address.coordinates
      : {};

  console.log("center----", props);

  if (center.latitude && center.longitude && isLoaded) {
    let gpsdata = {
      lat: center.latitude,
      lng: center.longitude,
    };
    const mapUri =
      "http://maps.google.com/maps?q=loc:" +
      gpsdata.lat +
      "," +
      gpsdata.lng +
      " (" +
      props.personalInfo.name +
      ")";
    return (
      <GoogleMap
        onClick={() => {
          console.log("clicked on map");
          window.open(mapUri);
        }}
        mapContainerStyle={containerStyle}
        center={gpsdata}
        zoom={15}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <MarkerF position={gpsdata} />
      </GoogleMap>
    );
  } else {
    return <>Map not available</>;
  }
}

export default GoogleMapPage;

import * as React from "react";
import Button from "@mui/material/Button";

export default function RoundedButton(props: any) {

  const buttonDefaultCss = {
    padding:"10px 20px",
    backgroundColor:"#d56939",
    borderRadius:"30px",
    margin:"0px 5px",
  }

  return (
    <Button
      variant="contained"
      onClick={props.onClick}
      style={{
        padding: props.padding || buttonDefaultCss.padding,
        backgroundColor: props.backgroundColor || buttonDefaultCss.backgroundColor,
        borderRadius:props.borderRadius || buttonDefaultCss.borderRadius,
        margin: props.margin || buttonDefaultCss.margin,
        boxShadow:"none"
      }}
      disabled={props.disabled || false}
    >
      {props.title}
    </Button>
  );
}

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PageLinkScreen: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Parse the URL parameters
    const params = new URLSearchParams(location.search);
    const templeId = params.get('templeid');
    const singlePuja = params.get('singlepuja');

    const visheshpuja = params.get('visheshpuja');
    const pujaoftemple = params.get('pujaoftemple');

    const subcategory = params.get('subcategory');
    const horoscopedetails = params.get('horoscopedetails');

    let newUrl = "/apps";

    // Construct the new URL
    if (visheshpuja) {
      newUrl = `/promotionalPuja/${visheshpuja}`;
    }
    else if(pujaoftemple) {
      newUrl = `/temples/${pujaoftemple}`;
    }
    else if (templeId && singlePuja) {
      newUrl = `/temples/${templeId}/pujaInfo/${singlePuja}`;

    }
    else if (subcategory ) {
      newUrl = `/pujaSubCategory/${subcategory}`;

    }
    else if (horoscopedetails ) {
      newUrl = `/horoscopeDetails?zodiacName=${horoscopedetails}`;

    }
    navigate(newUrl);

  }, [navigate, location.search]);

  return <div>Redirecting...</div>;
};

export default PageLinkScreen;

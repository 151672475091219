import React from 'react';

import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import "./CommunityGuidelinesView.scss";


const CommunityGuidelinesView = () => {
    return (
        <Box className="pageContainer communityGuidelines">
            <Container className="container padding-0">
                <div className="sectionHead text-center style-4 mb-40">
                    <small className="title_small d-none"></small>
                    <Typography className="mainTitle mb-30">
                        Content & <span> Community</span> <span> Guidelines</span>
                    </Typography>
                </div>
                <div className="communityGuidelinesInfo mb-40">
                    <Typography paragraph>
                        At Temple Dekho, we are dedicated to creating a respectful, positive, and spiritually enriching environment for all our users. Please adhere to the following guidelines:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary="1. Respect and Courtesy: Treat all interactions with respect and courtesy. Discrimination, harassment, or abusive behavior of any kind will not be tolerated." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="2. Social Media Conduct: On our social media platforms, refrain from posting inappropriate, offensive, or unrelated material. Maintain respectful and relevant discussions." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="3. Privacy: Respect the privacy of others. Do not share personal information or images of others without their consent." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="4. Constructive Feedback: Engage in discussions with a positive attitude and provide constructive feedback. Avoid making derogatory or inflammatory remarks." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="5. Intellectual Property: Share only content that you own or have permission to use. Acknowledge and respect the intellectual property rights of others." />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="6. Compliance: Adhere to all applicable laws and regulations. Any illegal activities or discussions promoting such activities are strictly prohibited." />
                        </ListItem>
                    </List>
                    <Typography paragraph sx={{mt:"20px"}}>
                        Failure to comply with these guidelines may result in the removal of content or restrictions on your account. Thank you for helping us maintain a welcoming and respectful environment at Temple Dekho.
                    </Typography>
                </div>
            </Container>
        </Box>
    );
};

export default CommunityGuidelinesView;

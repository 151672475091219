import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Box,
  Stack,
  Modal,
  Input,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  ListItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  List,
  Card,
  CardContent,
  CardActions,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { mobileRoutes } from "../../../utils/Constants";
import {AuthService} from "td_server"
interface SendPushNotificationFormProps {
  onSubmit: ({}: any) => void;
  isLoading: boolean;
}

const SendPushNotificationForm: React.FC<SendPushNotificationFormProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [message, setmessage] = useState("");
  const [title, setTitle] = useState("");
  const [pushDetails, setPushDetails] = useState({
    screenName: "",
    id: "",
    subId: "",
  });
  const [searchMobNoInput,setSearchMobNoInput]:any = useState("")
  const [pushToUserList,setPushToUserList]:any = useState([])
  const [isSendToSpecificNumbers,setIsSendToSpecificNumbers]:any =useState(false)
  const [openAdvanceModal, setOpen] = React.useState(false);
  const handleAdvanceModalOpen = () => setOpen(true);
  const handleAdvanceModalClose = () => setOpen(false);
  const mobileRoutesMap: any = mobileRoutes;
  const handleNotificationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setmessage(event.target.value);
  };

  //Advance Modal Dropdown
  const [AdvanceModalSelect, setAdvanceModalSelect] = useState<string>("");
  const handleStatusChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setAdvanceModalSelect(selectedValue);
  };

  //Advance Modal Other Message Input area
  const [otherMessage, setOtherMessage] = useState<string>("");
  const handleOtherMessageChange = (event: any) => {
    const selectedValue = event.target.value as string;
    setOtherMessage(selectedValue);
  };

  //Advance Modal Upload Image area
  const [selectedFile, setSelectedFile]: any = useState(null);
  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit({ title, message, image: selectedFile, pushDetails,pushToUserList,isSendToSpecificNumbers});
    handleAdvanceModalClose();
    // setmessage('');
    // setTitle('')
  };

  const searchMobNo = async ()=>{
    if(searchMobNoInput&&searchMobNoInput.length===10){
      const checkUser = await AuthService.getUserData(
        `+91${searchMobNoInput}`
      );

      console.log("User Info = xx",checkUser.user)
      if(checkUser&&checkUser.user){
        setPushToUserList([...pushToUserList, checkUser.user])
        setSearchMobNoInput("")
      }

      //Add
    }
  }

  // console.log("Screen details",pushDetails)
  return (
    <Box>
      <form className="pushNotiForm">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className="pushNotiTitle" variant="h5" gutterBottom>
              Send Push Notification
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={title}
              onChange={handleTitleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              value={message}
              multiline
              rows={5}
              onChange={handleNotificationChange}
            />
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={2}>
              <Button
                className="pushNotiBtn"
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e:any)=>handleSubmit(e)}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Send Notification"
                )}
              </Button>
              <Button
                onClick={handleAdvanceModalOpen}
                className="advanceBtn"
                variant="contained"
                color="primary"
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Advance"
                )}
              </Button>
              <Modal
                open={openAdvanceModal}
                onClose={handleAdvanceModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box className="advanceModal">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ marginBottom: "15px" }}
                  >
                    <Typography
                      id="change-status-title"
                      variant="h6"
                      gutterBottom
                      className="advanceModalTitle"
                    >
                      Advance
                    </Typography>
                    <Button
                      onClick={handleAdvanceModalClose}
                      className="advanceModalCloseBtn"
                    >
                      <CloseIcon />
                    </Button>
                  </Stack>
                  <Box className="advanceModalInner">
                    <Grid container direction="column" spacing={2}>
                      {/* For Dropdown */}
                      <Grid item md={12}>
                        {/* Below commented items are future enhancements */}
                        {/* <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            value={AdvanceModalSelect}
                            displayEmpty
                            onChange={handleStatusChange}
                          >
                            <MenuItem value="">--- Puja Name ---</MenuItem>

                          </Select>
                        </FormControl> */}
                      </Grid>
                      <Grid item md={12}>
                        {/* Below commented items are future enhancements */}
                        {/* <TextField
                          label="Other Message"
                          multiline
                          fullWidth
                          value={otherMessage}
                          rows={5}
                          onChange={handleOtherMessageChange}
                        /> */}
                      </Grid>
                      <Grid item md={12}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography
                              component="p"
                              sx={{ mb: "15px" }}
                              gutterBottom
                            >
                              Upload Image
                            </Typography>
                            <Box>
                              <input
                                accept="image/*"
                                id="upload-image"
                                type="file"
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                              <label htmlFor="upload-image">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  className="uploadImgBtn"
                                  startIcon={<CloudUploadIcon />}
                                >
                                  Upload Image
                                </Button>
                              </label>
                            </Box>
                          </CardContent>
                          {selectedFile && (
                            <CardActions>
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                              >
                                <Typography variant="body1">
                                  Selected file: {selectedFile?.name}
                                </Typography>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setSelectedFile(null)}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </CardActions>
                          )}
                        </Card>
                        <br></br>
                        {/* <Typography>Naviagate to Page</Typography> */}
                        <Box mb={2}>
                          <InputLabel id="demo-simple-select-label">
                            Naviagate to Page
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="couponType"
                            value={pushDetails.screenName}
                            label="Coupon Type"
                            onChange={(e: any) => {
                              console.log("Event", e.target.value);
                              setPushDetails({...pushDetails,screenName:e.target.value})
                            }}
                            fullWidth
                            required
                            // disabled={!isEditMode}
                          >
                            {Object.keys(mobileRoutesMap).map(
                              (item: any, id: any) => {
                                return (
                                  <MenuItem value={mobileRoutesMap[item].navId}>
                                    {mobileRoutesMap[item].displayName}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                        </Box>
                        <TextField
                          label="Id/First level query"
                          variant="outlined"
                          fullWidth
                          value={pushDetails.id}
                          onChange={(e:any)=>setPushDetails({...pushDetails,id:e.target.value})}
                        />
                        <br></br>
                        <br></br>
                        <TextField
                          label="Sub Id/Second level query"
                          variant="outlined"
                          fullWidth
                          value={pushDetails.subId}
                          onChange={(e:any)=>setPushDetails({...pushDetails,subId:e.target.value})}
                        />
                      </Grid>
                      {/* Below commented items are future enhancements */}
                      <Grid item md={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={isSendToSpecificNumbers} onChange={()=>setIsSendToSpecificNumbers(!isSendToSpecificNumbers)}/>} label="Only send to specific numbers" />
                          </FormGroup>
                        </Box>
                      </Grid>
                      {/* Below commented items are future enhancements */}
                      <Grid item md={12}>
                        <TextField
                        onChange={(e:any)=>setSearchMobNoInput(e.target.value)}
                          style={{width:"50%"}}
                          variant="outlined"
                          value={searchMobNoInput}
                          placeholder="Search..."
                          InputProps={{
                            endAdornment: (
                              <SearchIcon sx={{ color: "#c4c4c4" }} />
                            ),
                          }}
                        />
                        <Button style={{marginLeft:"15px",marginTop:"10px"}} onClick={()=>searchMobNo()}>Search and Add</Button>
                        <br></br>
                      
                      </Grid>
                      {/* Below commented items are future enhancements */}
                      <Grid item md={12}>
                        <List>
                          {pushToUserList.map((item:any,id:any)=>{
                            return (
                              <ListItem sx={{ padding: "5px 0" }}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                className="advModalUserInfo"
                                spacing={1}
                              >
                                {/* <Box>
                                  <Checkbox defaultChecked />
                                </Box> */}
                                <Box>
                                  <Typography>Name: {item.personalInfo?.firstName} {item.personalInfo?.lastName}</Typography>
                                  <Typography>Phone No: {item.phoneNumber}</Typography>
                                </Box>
                              </Stack>
                            </ListItem>
                            )
                          })}
                      
                    
                        </List>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box className="advanceModalSubmitBtn">
                    <Button
                      sx={{ width: "100%" }}
                      variant="contained"
                      onClick={(e: any) => handleSubmit(e)}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SendPushNotificationForm;
